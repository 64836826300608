:root{
  --bs-blue:#5b73e8;
  --bs-indigo:#564ab1;
  --bs-purple:#6f42c1;
  --bs-pink:#e83e8c;
  --bs-red:#f46a6a;
  --bs-orange:#f1734f;
  --bs-yellow:#f1b44c;
  --bs-green:#34c38f;
  --bs-teal:#050505;
  --bs-cyan:#50a5f1;
  --bs-white:#fff;
  --bs-gray:#c3cbe4;
  --bs-gray-dark:#eff2f7;
  --bs-gray-100:#212529;
  --bs-gray-200:#2b2e3b;
  --bs-gray-300:#343747;
  --bs-gray-400:#9295a4;
  --bs-gray-500:#9699a8;
  --bs-gray-600:#c3cbe4;
  --bs-gray-700:#f6f6f6;
  --bs-gray-800:#eff2f7;
  --bs-gray-900:#f8f9fa;
  --bs-primary:#5b73e8;
  --bs-secondary:#c3cbe4;
  --bs-success:#34c38f;
  --bs-info:#50a5f1;
  --bs-warning:#f1b44c;
  --bs-danger:#f46a6a;
  --bs-pink:#e83e8c;
  --bs-light:#343747;
  --bs-dark:#eff2f7;
  --bs-purple:#6f42c1;
  --bs-primary-rgb:91,115,232;
  --bs-secondary-rgb:195,203,228;
  --bs-success-rgb:52,195,143;
  --bs-info-rgb:80,165,241;
  --bs-warning-rgb:241,180,76;
  --bs-danger-rgb:244,106,106;
  --bs-pink-rgb:232,62,140;
  --bs-light-rgb:52,55,71;
  --bs-dark-rgb:239,242,247;
  --bs-purple-rgb:111,66,193;
  --bs-white-rgb:255,255,255;
  --bs-black-rgb:0,0,0;
  --bs-body-color-rgb:146,149,164;
  --bs-body-bg-rgb:34,37,47;
  --bs-font-sans-serif:"IBM Plex Sans",sans-serif;
  --bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family:IBM Plex Sans,sans-serif;
  --bs-body-font-size:0.9rem;
  --bs-body-font-weight:400;
  --bs-body-line-height:1.5;
  --bs-body-color:#9295a4;
  --bs-body-bg:#22252f
}
*,::after,::before{
  -webkit-box-sizing:border-box;
  box-sizing:border-box
}
@media (prefers-reduced-motion:no-preference){
  :root{
      scroll-behavior:smooth
  }
}
body{
  margin:0;
  font-family:var(--bs-body-font-family);
  font-size:var(--bs-body-font-size);
  font-weight:var(--bs-body-font-weight);
  line-height:var(--bs-body-line-height);
  color:var(--bs-body-color);
  text-align:var(--bs-body-text-align);
  background-color:var(--bs-body-bg);
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:transparent
}
hr{
  margin:1rem 0;
  color:rgba(0,0,0,.1);
  background-color:currentColor;
  border:0;
  opacity:.25
}
hr:not([size]){
  height:1px
}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{
  margin-top:0;
  margin-bottom:.5rem;
  font-weight:500;
  line-height:1.2
}
.h1,h1{
  font-size:calc(1.35rem + 1.2vw)
}
@media (min-width:1200px){
  .h1,h1{
      font-size:2.25rem
  }
}
.h2,h2{
  font-size:calc(1.305rem + .66vw)
}
@media (min-width:1200px){
  .h2,h2{
      font-size:1.8rem
  }
}
.h3,h3{
  font-size:calc(1.2825rem + .39vw)
}
@media (min-width:1200px){
  .h3,h3{
      font-size:1.575rem
  }
}
.h4,h4{
  font-size:calc(1.26rem + .12vw)
}
@media (min-width:1200px){
  .h4,h4{
      font-size:1.35rem
  }
}
.h5,h5{
  font-size:1.125rem
}
.h6,h6{
  font-size:.9rem
}
p{
  margin-top:0;
  margin-bottom:1rem
}
abbr[data-bs-original-title],abbr[title]{
  -webkit-text-decoration:underline dotted;
  text-decoration:underline dotted;
  cursor:help;
  -webkit-text-decoration-skip-ink:none;
  text-decoration-skip-ink:none
}
address{
  margin-bottom:1rem;
  font-style:normal;
  line-height:inherit
}
ol,ul{
  padding-left:2rem
}
dl,ol,ul{
  margin-top:0;
  margin-bottom:1rem
}
ol ol,ol ul,ul ol,ul ul{
  margin-bottom:0
}
dt{
  font-weight:600
}
dd{
  margin-bottom:.5rem;
  margin-left:0
}
blockquote{
  margin:0 0 1rem
}
b,strong{
  font-weight:bolder
}
.small,small{
  font-size:87.5%
}
.mark,mark{
  padding:.2em;
  background-color:#fcf8e3
}
sub,sup{
  position:relative;
  font-size:.75em;
  line-height:0;
  vertical-align:baseline
}
sub{
  bottom:-.25em
}
sup{
  top:-.5em
}
a{
  color:#5b73e8;
  text-decoration:none
}
a:hover{
  color:#1e3ed8
}
a:not([href]):not([class]),a:not([href]):not([class]):hover{
  color:inherit;
  text-decoration:none
}
code,kbd,pre,samp{
  font-family:var(--bs-font-monospace);
  font-size:1em;
  direction:ltr;
  unicode-bidi:bidi-override
}
pre{
  display:block;
  margin-top:0;
  margin-bottom:1rem;
  overflow:auto;
  font-size:87.5%
}
pre code{
  font-size:inherit;
  color:inherit;
  word-break:normal
}
code{
  font-size:87.5%;
  color:#e83e8c;
  word-wrap:break-word
}
a>code{
  color:inherit
}
kbd{
  padding:.2rem .4rem;
  font-size:87.5%;
  color:#fff;
  background-color:#f8f9fa;
  border-radius:.2rem
}
kbd kbd{
  padding:0;
  font-size:1em;
  font-weight:600
}
figure{
  margin:0 0 1rem
}
img,svg{
  vertical-align:middle
}
table{
  caption-side:bottom;
  border-collapse:collapse
}
caption{
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#9699a8;
  text-align:left
}
th{
  text-align:inherit;
  text-align:-webkit-match-parent
}
tbody,td,tfoot,th,thead,tr{
  border-color:inherit;
  border-style:solid;
  border-width:0
}
label{
  display:inline-block
}
button{
  border-radius:0
}
button:focus:not(:focus-visible){
  outline:0
}
button,input,optgroup,select,textarea{
  margin:0;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit
}
button,select{
  text-transform:none
}
[role=button]{
  cursor:pointer
}
select{
  word-wrap:normal
}
select:disabled{
  opacity:1
}
[list]::-webkit-calendar-picker-indicator{
  display:none
}
[type=button],[type=reset],[type=submit],button{
  -webkit-appearance:button
}
[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled){
  cursor:pointer
}
::-moz-focus-inner{
  padding:0;
  border-style:none
}
textarea{
  resize:vertical
}
fieldset{
  min-width:0;
  padding:0;
  margin:0;
  border:0
}
legend{
  float:left;
  width:100%;
  padding:0;
  margin-bottom:.5rem;
  font-size:calc(1.275rem + .3vw);
  line-height:inherit
}
@media (min-width:1200px){
  legend{
      font-size:1.5rem
  }
}
legend+*{
  clear:left
}
::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-text,::-webkit-datetime-edit-year-field{
  padding:0
}
::-webkit-inner-spin-button{
  height:auto
}
[type=search]{
  outline-offset:-2px;
  -webkit-appearance:textfield
}
::-webkit-search-decoration{
  -webkit-appearance:none
}
::-webkit-color-swatch-wrapper{
  padding:0
}
::file-selector-button{
  font:inherit
}
::-webkit-file-upload-button{
  font:inherit;
  -webkit-appearance:button
}
output{
  display:inline-block
}
iframe{
  border:0
}
summary{
  display:list-item;
  cursor:pointer
}
progress{
  vertical-align:baseline
}
[hidden]{
  display:none!important
}
.lead{
  font-size:1.125rem;
  font-weight:300
}
.display-1{
  font-size:calc(1.625rem + 4.5vw);
  font-weight:300;
  line-height:1.2
}
@media (min-width:1200px){
  .display-1{
      font-size:5rem
  }
}
.display-2{
  font-size:calc(1.575rem + 3.9vw);
  font-weight:300;
  line-height:1.2
}
@media (min-width:1200px){
  .display-2{
      font-size:4.5rem
  }
}
.display-3{
  font-size:calc(1.525rem + 3.3vw);
  font-weight:300;
  line-height:1.2
}
@media (min-width:1200px){
  .display-3{
      font-size:4rem
  }
}
.display-4{
  font-size:calc(1.475rem + 2.7vw);
  font-weight:300;
  line-height:1.2
}
@media (min-width:1200px){
  .display-4{
      font-size:3.5rem
  }
}
.display-5{
  font-size:calc(1.425rem + 2.1vw);
  font-weight:300;
  line-height:1.2
}
@media (min-width:1200px){
  .display-5{
      font-size:3rem
  }
}
.display-6{
  font-size:calc(1.375rem + 1.5vw);
  font-weight:300;
  line-height:1.2
}
@media (min-width:1200px){
  .display-6{
      font-size:2.5rem
  }
}
.list-unstyled{
  padding-left:0;
  list-style:none
}
.list-inline{
  padding-left:0;
  list-style:none
}
.list-inline-item{
  display:inline-block
}
.list-inline-item:not(:last-child){
  margin-right:.5rem
}
.initialism{
  font-size:87.5%;
  text-transform:uppercase
}
.blockquote{
  margin-bottom:1rem;
  font-size:1.125rem
}
.blockquote>:last-child{
  margin-bottom:0
}
.blockquote-footer{
  margin-top:-1rem;
  margin-bottom:1rem;
  font-size:87.5%;
  color:#9699a8
}
.blockquote-footer::before{
  content:"\2014\00A0"
}
.img-fluid{
  max-width:100%;
  height:auto
}
.img-thumbnail{
  padding:.25rem;
  background-color:#22252f;
  border:1px solid #343747;
  border-radius:.25rem;
  max-width:100%;
  height:auto
}
.figure{
  display:inline-block
}
.figure-img{
  margin-bottom:.5rem;
  line-height:1
}
.figure-caption{
  font-size:87.5%;
  color:#c3cbe4
}
.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{
  width:100%;
  padding-right:var(--bs-gutter-x,.625rem);
  padding-left:var(--bs-gutter-x,.625rem);
  margin-right:auto;
  margin-left:auto
}
@media (min-width:576px){
  .container,.container-sm{
      max-width:540px
  }
}
@media (min-width:768px){
  .container,.container-md,.container-sm{
      max-width:720px
  }
}
@media (min-width:992px){
  .container,.container-lg,.container-md,.container-sm{
      max-width:960px
  }
}
@media (min-width:1200px){
  .container,.container-lg,.container-md,.container-sm,.container-xl{
      max-width:1140px
  }
}
@media (min-width:1400px){
  .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{
      max-width:1320px
  }
}
.row{
  --bs-gutter-x:1.25rem;
  --bs-gutter-y:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:calc(-1 * var(--bs-gutter-y));
  margin-right:calc(-.5 * var(--bs-gutter-x));
  margin-left:calc(-.5 * var(--bs-gutter-x))
}
.row>*{
  -ms-flex-negative:0;
  flex-shrink:0;
  width:100%;
  max-width:100%;
  padding-right:calc(var(--bs-gutter-x) * .5);
  padding-left:calc(var(--bs-gutter-x) * .5);
  margin-top:var(--bs-gutter-y)
}
.col{
  -webkit-box-flex:1;
  -ms-flex:1 0 0%;
  flex:1 0 0%
}
.row-cols-auto>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:auto
}
.row-cols-1>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:100%
}
.row-cols-2>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:50%
}
.row-cols-3>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:33.33333%
}
.row-cols-4>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:25%
}
.row-cols-5>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:20%
}
.row-cols-6>*{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:16.66667%
}
.col-auto{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:auto
}
.col-1{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:8.33333%
}
.col-2{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:16.66667%
}
.col-3{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:25%
}
.col-4{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:33.33333%
}
.col-5{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:41.66667%
}
.col-6{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:50%
}
.col-7{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:58.33333%
}
.col-8{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:66.66667%
}
.col-9{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:75%
}
.col-10{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:83.33333%
}
.col-11{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:91.66667%
}
.col-12{
  -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:100%
}
.offset-1{
  margin-left:8.33333%
}
.offset-2{
  margin-left:16.66667%
}
.offset-3{
  margin-left:25%
}
.offset-4{
  margin-left:33.33333%
}
.offset-5{
  margin-left:41.66667%
}
.offset-6{
  margin-left:50%
}
.offset-7{
  margin-left:58.33333%
}
.offset-8{
  margin-left:66.66667%
}
.offset-9{
  margin-left:75%
}
.offset-10{
  margin-left:83.33333%
}
.offset-11{
  margin-left:91.66667%
}
.g-0,.gx-0{
  --bs-gutter-x:0
}
.g-0,.gy-0{
  --bs-gutter-y:0
}
.g-1,.gx-1{
  --bs-gutter-x:0.25rem
}
.g-1,.gy-1{
  --bs-gutter-y:0.25rem
}
.g-2,.gx-2{
  --bs-gutter-x:0.5rem
}
.g-2,.gy-2{
  --bs-gutter-y:0.5rem
}
.g-3,.gx-3{
  --bs-gutter-x:1rem
}
.g-3,.gy-3{
  --bs-gutter-y:1rem
}
.g-4,.gx-4{
  --bs-gutter-x:1.5rem
}
.g-4,.gy-4{
  --bs-gutter-y:1.5rem
}
.g-5,.gx-5{
  --bs-gutter-x:3rem
}
.g-5,.gy-5{
  --bs-gutter-y:3rem
}
@media (min-width:576px){
  .col-sm{
      -webkit-box-flex:1;
      -ms-flex:1 0 0%;
      flex:1 0 0%
  }
  .row-cols-sm-auto>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .row-cols-sm-1>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .row-cols-sm-2>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .row-cols-sm-3>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .row-cols-sm-4>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .row-cols-sm-5>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:20%
  }
  .row-cols-sm-6>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-sm-auto{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .col-sm-1{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:8.33333%
  }
  .col-sm-2{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-sm-3{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .col-sm-4{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .col-sm-5{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:41.66667%
  }
  .col-sm-6{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .col-sm-7{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:58.33333%
  }
  .col-sm-8{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:66.66667%
  }
  .col-sm-9{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:75%
  }
  .col-sm-10{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:83.33333%
  }
  .col-sm-11{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:91.66667%
  }
  .col-sm-12{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .offset-sm-0{
      margin-left:0
  }
  .offset-sm-1{
      margin-left:8.33333%
  }
  .offset-sm-2{
      margin-left:16.66667%
  }
  .offset-sm-3{
      margin-left:25%
  }
  .offset-sm-4{
      margin-left:33.33333%
  }
  .offset-sm-5{
      margin-left:41.66667%
  }
  .offset-sm-6{
      margin-left:50%
  }
  .offset-sm-7{
      margin-left:58.33333%
  }
  .offset-sm-8{
      margin-left:66.66667%
  }
  .offset-sm-9{
      margin-left:75%
  }
  .offset-sm-10{
      margin-left:83.33333%
  }
  .offset-sm-11{
      margin-left:91.66667%
  }
  .g-sm-0,.gx-sm-0{
      --bs-gutter-x:0
  }
  .g-sm-0,.gy-sm-0{
      --bs-gutter-y:0
  }
  .g-sm-1,.gx-sm-1{
      --bs-gutter-x:0.25rem
  }
  .g-sm-1,.gy-sm-1{
      --bs-gutter-y:0.25rem
  }
  .g-sm-2,.gx-sm-2{
      --bs-gutter-x:0.5rem
  }
  .g-sm-2,.gy-sm-2{
      --bs-gutter-y:0.5rem
  }
  .g-sm-3,.gx-sm-3{
      --bs-gutter-x:1rem
  }
  .g-sm-3,.gy-sm-3{
      --bs-gutter-y:1rem
  }
  .g-sm-4,.gx-sm-4{
      --bs-gutter-x:1.5rem
  }
  .g-sm-4,.gy-sm-4{
      --bs-gutter-y:1.5rem
  }
  .g-sm-5,.gx-sm-5{
      --bs-gutter-x:3rem
  }
  .g-sm-5,.gy-sm-5{
      --bs-gutter-y:3rem
  }
}
@media (min-width:768px){
  .col-md{
      -webkit-box-flex:1;
      -ms-flex:1 0 0%;
      flex:1 0 0%
  }
  .row-cols-md-auto>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .row-cols-md-1>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .row-cols-md-2>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .row-cols-md-3>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .row-cols-md-4>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .row-cols-md-5>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:20%
  }
  .row-cols-md-6>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-md-auto{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .col-md-1{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:8.33333%
  }
  .col-md-2{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-md-3{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .col-md-4{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .col-md-5{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:41.66667%
  }
  .col-md-6{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .col-md-7{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:58.33333%
  }
  .col-md-8{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:66.66667%
  }
  .col-md-9{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:75%
  }
  .col-md-10{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:83.33333%
  }
  .col-md-11{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:91.66667%
  }
  .col-md-12{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .offset-md-0{
      margin-left:0
  }
  .offset-md-1{
      margin-left:8.33333%
  }
  .offset-md-2{
      margin-left:16.66667%
  }
  .offset-md-3{
      margin-left:25%
  }
  .offset-md-4{
      margin-left:33.33333%
  }
  .offset-md-5{
      margin-left:41.66667%
  }
  .offset-md-6{
      margin-left:50%
  }
  .offset-md-7{
      margin-left:58.33333%
  }
  .offset-md-8{
      margin-left:66.66667%
  }
  .offset-md-9{
      margin-left:75%
  }
  .offset-md-10{
      margin-left:83.33333%
  }
  .offset-md-11{
      margin-left:91.66667%
  }
  .g-md-0,.gx-md-0{
      --bs-gutter-x:0
  }
  .g-md-0,.gy-md-0{
      --bs-gutter-y:0
  }
  .g-md-1,.gx-md-1{
      --bs-gutter-x:0.25rem
  }
  .g-md-1,.gy-md-1{
      --bs-gutter-y:0.25rem
  }
  .g-md-2,.gx-md-2{
      --bs-gutter-x:0.5rem
  }
  .g-md-2,.gy-md-2{
      --bs-gutter-y:0.5rem
  }
  .g-md-3,.gx-md-3{
      --bs-gutter-x:1rem
  }
  .g-md-3,.gy-md-3{
      --bs-gutter-y:1rem
  }
  .g-md-4,.gx-md-4{
      --bs-gutter-x:1.5rem
  }
  .g-md-4,.gy-md-4{
      --bs-gutter-y:1.5rem
  }
  .g-md-5,.gx-md-5{
      --bs-gutter-x:3rem
  }
  .g-md-5,.gy-md-5{
      --bs-gutter-y:3rem
  }
}
@media (min-width:992px){
  .col-lg{
      -webkit-box-flex:1;
      -ms-flex:1 0 0%;
      flex:1 0 0%
  }
  .row-cols-lg-auto>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .row-cols-lg-1>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .row-cols-lg-2>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .row-cols-lg-3>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .row-cols-lg-4>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .row-cols-lg-5>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:20%
  }
  .row-cols-lg-6>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-lg-auto{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .col-lg-1{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:8.33333%
  }
  .col-lg-2{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-lg-3{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .col-lg-4{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .col-lg-5{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:41.66667%
  }
  .col-lg-6{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .col-lg-7{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:58.33333%
  }
  .col-lg-8{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:66.66667%
  }
  .col-lg-9{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:75%
  }
  .col-lg-10{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:83.33333%
  }
  .col-lg-11{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:91.66667%
  }
  .col-lg-12{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .offset-lg-0{
      margin-left:0
  }
  .offset-lg-1{
      margin-left:8.33333%
  }
  .offset-lg-2{
      margin-left:16.66667%
  }
  .offset-lg-3{
      margin-left:25%
  }
  .offset-lg-4{
      margin-left:33.33333%
  }
  .offset-lg-5{
      margin-left:41.66667%
  }
  .offset-lg-6{
      margin-left:50%
  }
  .offset-lg-7{
      margin-left:58.33333%
  }
  .offset-lg-8{
      margin-left:66.66667%
  }
  .offset-lg-9{
      margin-left:75%
  }
  .offset-lg-10{
      margin-left:83.33333%
  }
  .offset-lg-11{
      margin-left:91.66667%
  }
  .g-lg-0,.gx-lg-0{
      --bs-gutter-x:0
  }
  .g-lg-0,.gy-lg-0{
      --bs-gutter-y:0
  }
  .g-lg-1,.gx-lg-1{
      --bs-gutter-x:0.25rem
  }
  .g-lg-1,.gy-lg-1{
      --bs-gutter-y:0.25rem
  }
  .g-lg-2,.gx-lg-2{
      --bs-gutter-x:0.5rem
  }
  .g-lg-2,.gy-lg-2{
      --bs-gutter-y:0.5rem
  }
  .g-lg-3,.gx-lg-3{
      --bs-gutter-x:1rem
  }
  .g-lg-3,.gy-lg-3{
      --bs-gutter-y:1rem
  }
  .g-lg-4,.gx-lg-4{
      --bs-gutter-x:1.5rem
  }
  .g-lg-4,.gy-lg-4{
      --bs-gutter-y:1.5rem
  }
  .g-lg-5,.gx-lg-5{
      --bs-gutter-x:3rem
  }
  .g-lg-5,.gy-lg-5{
      --bs-gutter-y:3rem
  }
}
@media (min-width:1200px){
  .col-xl{
      -webkit-box-flex:1;
      -ms-flex:1 0 0%;
      flex:1 0 0%
  }
  .row-cols-xl-auto>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .row-cols-xl-1>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .row-cols-xl-2>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .row-cols-xl-3>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .row-cols-xl-4>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .row-cols-xl-5>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:20%
  }
  .row-cols-xl-6>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-xl-auto{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .col-xl-1{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:8.33333%
  }
  .col-xl-2{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-xl-3{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .col-xl-4{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .col-xl-5{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:41.66667%
  }
  .col-xl-6{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .col-xl-7{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:58.33333%
  }
  .col-xl-8{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:66.66667%
  }
  .col-xl-9{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:75%
  }
  .col-xl-10{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:83.33333%
  }
  .col-xl-11{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:91.66667%
  }
  .col-xl-12{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .offset-xl-0{
      margin-left:0
  }
  .offset-xl-1{
      margin-left:8.33333%
  }
  .offset-xl-2{
      margin-left:16.66667%
  }
  .offset-xl-3{
      margin-left:25%
  }
  .offset-xl-4{
      margin-left:33.33333%
  }
  .offset-xl-5{
      margin-left:41.66667%
  }
  .offset-xl-6{
      margin-left:50%
  }
  .offset-xl-7{
      margin-left:58.33333%
  }
  .offset-xl-8{
      margin-left:66.66667%
  }
  .offset-xl-9{
      margin-left:75%
  }
  .offset-xl-10{
      margin-left:83.33333%
  }
  .offset-xl-11{
      margin-left:91.66667%
  }
  .g-xl-0,.gx-xl-0{
      --bs-gutter-x:0
  }
  .g-xl-0,.gy-xl-0{
      --bs-gutter-y:0
  }
  .g-xl-1,.gx-xl-1{
      --bs-gutter-x:0.25rem
  }
  .g-xl-1,.gy-xl-1{
      --bs-gutter-y:0.25rem
  }
  .g-xl-2,.gx-xl-2{
      --bs-gutter-x:0.5rem
  }
  .g-xl-2,.gy-xl-2{
      --bs-gutter-y:0.5rem
  }
  .g-xl-3,.gx-xl-3{
      --bs-gutter-x:1rem
  }
  .g-xl-3,.gy-xl-3{
      --bs-gutter-y:1rem
  }
  .g-xl-4,.gx-xl-4{
      --bs-gutter-x:1.5rem
  }
  .g-xl-4,.gy-xl-4{
      --bs-gutter-y:1.5rem
  }
  .g-xl-5,.gx-xl-5{
      --bs-gutter-x:3rem
  }
  .g-xl-5,.gy-xl-5{
      --bs-gutter-y:3rem
  }
}
@media (min-width:1400px){
  .col-xxl{
      -webkit-box-flex:1;
      -ms-flex:1 0 0%;
      flex:1 0 0%
  }
  .row-cols-xxl-auto>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .row-cols-xxl-1>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .row-cols-xxl-2>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .row-cols-xxl-3>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .row-cols-xxl-4>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .row-cols-xxl-5>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:20%
  }
  .row-cols-xxl-6>*{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-xxl-auto{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:auto
  }
  .col-xxl-1{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:8.33333%
  }
  .col-xxl-2{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:16.66667%
  }
  .col-xxl-3{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:25%
  }
  .col-xxl-4{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:33.33333%
  }
  .col-xxl-5{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:41.66667%
  }
  .col-xxl-6{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:50%
  }
  .col-xxl-7{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:58.33333%
  }
  .col-xxl-8{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:66.66667%
  }
  .col-xxl-9{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:75%
  }
  .col-xxl-10{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:83.33333%
  }
  .col-xxl-11{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:91.66667%
  }
  .col-xxl-12{
      -webkit-box-flex:0;
      -ms-flex:0 0 auto;
      flex:0 0 auto;
      width:100%
  }
  .offset-xxl-0{
      margin-left:0
  }
  .offset-xxl-1{
      margin-left:8.33333%
  }
  .offset-xxl-2{
      margin-left:16.66667%
  }
  .offset-xxl-3{
      margin-left:25%
  }
  .offset-xxl-4{
      margin-left:33.33333%
  }
  .offset-xxl-5{
      margin-left:41.66667%
  }
  .offset-xxl-6{
      margin-left:50%
  }
  .offset-xxl-7{
      margin-left:58.33333%
  }
  .offset-xxl-8{
      margin-left:66.66667%
  }
  .offset-xxl-9{
      margin-left:75%
  }
  .offset-xxl-10{
      margin-left:83.33333%
  }
  .offset-xxl-11{
      margin-left:91.66667%
  }
  .g-xxl-0,.gx-xxl-0{
      --bs-gutter-x:0
  }
  .g-xxl-0,.gy-xxl-0{
      --bs-gutter-y:0
  }
  .g-xxl-1,.gx-xxl-1{
      --bs-gutter-x:0.25rem
  }
  .g-xxl-1,.gy-xxl-1{
      --bs-gutter-y:0.25rem
  }
  .g-xxl-2,.gx-xxl-2{
      --bs-gutter-x:0.5rem
  }
  .g-xxl-2,.gy-xxl-2{
      --bs-gutter-y:0.5rem
  }
  .g-xxl-3,.gx-xxl-3{
      --bs-gutter-x:1rem
  }
  .g-xxl-3,.gy-xxl-3{
      --bs-gutter-y:1rem
  }
  .g-xxl-4,.gx-xxl-4{
      --bs-gutter-x:1.5rem
  }
  .g-xxl-4,.gy-xxl-4{
      --bs-gutter-y:1.5rem
  }
  .g-xxl-5,.gx-xxl-5{
      --bs-gutter-x:3rem
  }
  .g-xxl-5,.gy-xxl-5{
      --bs-gutter-y:3rem
  }
}
.table{
  --bs-table-bg:transparent;
  --bs-table-accent-bg:transparent;
  --bs-table-striped-color:#9295a4;
  --bs-table-striped-bg:rgba(0, 0, 0, 0.05);
  --bs-table-active-color:#9295a4;
  --bs-table-active-bg:rgba(0, 0, 0, 0.1);
  --bs-table-hover-color:#9295a4;
  --bs-table-hover-bg:rgba(150, 153, 168, 0.05);
  width:100%;
  margin-bottom:1rem;
  color:#9295a4;
  vertical-align:top;
  border-color:#343747
}
.table>:not(caption)>*>*{
  padding:.75rem .75rem;
  background-color:var(--bs-table-bg);
  border-bottom-width:1px;
  -webkit-box-shadow:inset 0 0 0 9999px var(--bs-table-accent-bg);
  box-shadow:inset 0 0 0 9999px var(--bs-table-accent-bg)
}
.table>tbody{
  vertical-align:inherit
}
.table>thead{
  vertical-align:bottom
}
.table>:not(:first-child){
  border-top:2px solid #343747
}
.caption-top{
  caption-side:top
}
.table-sm>:not(caption)>*>*{
  padding:.25rem .25rem
}
.table-bordered>:not(caption)>*{
  border-width:1px 0
}
.table-bordered>:not(caption)>*>*{
  border-width:0 1px
}
.table-borderless>:not(caption)>*>*{
  border-bottom-width:0
}
.table-borderless>:not(:first-child){
  border-top-width:0
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
  --bs-table-accent-bg:var(--bs-table-striped-bg);
  color:var(--bs-table-striped-color)
}
.table-active{
  --bs-table-accent-bg:var(--bs-table-active-bg);
  color:var(--bs-table-active-color)
}
.table-hover>tbody>tr:hover>*{
  --bs-table-accent-bg:var(--bs-table-hover-bg);
  color:var(--bs-table-hover-color)
}
.table-primary{
  --bs-table-bg:#dee3fa;
  --bs-table-striped-bg:#d3d8ee;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#c8cce1;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#cdd2e7;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#c8cce1
}
.table-secondary{
  --bs-table-bg:#f3f5fa;
  --bs-table-striped-bg:#e7e9ee;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#dbdde1;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#e1e3e7;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#dbdde1
}
.table-success{
  --bs-table-bg:#d6f3e9;
  --bs-table-striped-bg:#cbe7dd;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#c1dbd2;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#c6e1d8;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#c1dbd2
}
.table-info{
  --bs-table-bg:#dcedfc;
  --bs-table-striped-bg:#d1e1ef;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#c6d5e3;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#ccdbe9;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#c6d5e3
}
.table-warning{
  --bs-table-bg:#fcf0db;
  --bs-table-striped-bg:#efe4d0;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#e3d8c5;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#e9decb;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#e3d8c5
}
.table-danger{
  --bs-table-bg:#fde1e1;
  --bs-table-striped-bg:#f0d6d6;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#e4cbcb;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#ead0d0;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#e4cbcb
}
.table-light{
  --bs-table-bg:#343747;
  --bs-table-striped-bg:#3e4150;
  --bs-table-striped-color:#fff;
  --bs-table-active-bg:#484b59;
  --bs-table-active-color:#fff;
  --bs-table-hover-bg:#434655;
  --bs-table-hover-color:#fff;
  color:#fff;
  border-color:#484b59
}
.table-dark{
  --bs-table-bg:#eff2f7;
  --bs-table-striped-bg:#e3e6eb;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#d7dade;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#dde0e4;
  --bs-table-hover-color:#000;
  color:#000;
  border-color:#d7dade
}
.table-responsive{
  overflow-x:auto;
  -webkit-overflow-scrolling:touch
}
@media (max-width:575.98px){
  .table-responsive-sm{
      overflow-x:auto;
      -webkit-overflow-scrolling:touch
  }
}
@media (max-width:767.98px){
  .table-responsive-md{
      overflow-x:auto;
      -webkit-overflow-scrolling:touch
  }
}
@media (max-width:991.98px){
  .table-responsive-lg{
      overflow-x:auto;
      -webkit-overflow-scrolling:touch
  }
}
@media (max-width:1199.98px){
  .table-responsive-xl{
      overflow-x:auto;
      -webkit-overflow-scrolling:touch
  }
}
@media (max-width:1399.98px){
  .table-responsive-xxl{
      overflow-x:auto;
      -webkit-overflow-scrolling:touch
  }
}
.form-label{
  margin-bottom:.5rem
}
.col-form-label{
  padding-top:calc(.47rem + 1px);
  padding-bottom:calc(.47rem + 1px);
  margin-bottom:0;
  font-size:inherit;
  line-height:1.5
}
.col-form-label-lg{
  padding-top:calc(.5rem + 1px);
  padding-bottom:calc(.5rem + 1px);
  font-size:1.125rem
}
.col-form-label-sm{
  padding-top:calc(.25rem + 1px);
  padding-bottom:calc(.25rem + 1px);
  font-size:.7875rem
}
.form-text{
  margin-top:.25rem;
  font-size:87.5%;
  color:#9699a8
}
.form-control{
  display:block;
  width:100%;
  padding:.47rem .75rem;
  font-size:.9rem;
  font-weight:400;
  line-height:1.5;
  color:#9295a4;
  background-color:#2f323f;
  background-clip:padding-box;
  border:1px solid #343747;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  border-radius:.25rem;
  -webkit-transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .form-control{
      -webkit-transition:none;
      transition:none
  }
}
.form-control[type=file]{
  overflow:hidden
}
.form-control[type=file]:not(:disabled):not([readonly]){
  cursor:pointer
}
.form-control:focus{
  color:#9295a4;
  background-color:#313441;
  border-color:#383b4b;
  outline:0;
  -webkit-box-shadow:none;
  box-shadow:none
}
.form-control::-webkit-date-and-time-value{
  height:1.5em
}
.form-control::-webkit-input-placeholder{
  color:#9295a4;
  opacity:1
}
.form-control::-moz-placeholder{
  color:#9295a4;
  opacity:1
}
.form-control:-ms-input-placeholder{
  color:#9295a4;
  opacity:1
}
.form-control::-ms-input-placeholder{
  color:#9295a4;
  opacity:1
}
.form-control::placeholder{
  color:#9295a4;
  opacity:1
}
.form-control:disabled,.form-control[readonly]{
  background-color:#2d303d;
  opacity:1
}
.form-control::file-selector-button{
  padding:.47rem .75rem;
  margin:-.47rem -.75rem;
  -webkit-margin-end:.75rem;
  margin-inline-end:.75rem;
  color:#9295a4;
  background-color:#343747;
  pointer-events:none;
  border-color:inherit;
  border-style:solid;
  border-width:0;
  border-inline-end-width:1px;
  border-radius:0;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .form-control::file-selector-button{
      -webkit-transition:none;
      transition:none
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button{
  background-color:#313443
}
.form-control::-webkit-file-upload-button{
  padding:.47rem .75rem;
  margin:-.47rem -.75rem;
  -webkit-margin-end:.75rem;
  margin-inline-end:.75rem;
  color:#9295a4;
  background-color:#343747;
  pointer-events:none;
  border-color:inherit;
  border-style:solid;
  border-width:0;
  border-inline-end-width:1px;
  border-radius:0;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .form-control::-webkit-file-upload-button{
      -webkit-transition:none;
      transition:none
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button{
  background-color:#313443
}
.form-control-plaintext{
  display:block;
  width:100%;
  padding:.47rem 0;
  margin-bottom:0;
  line-height:1.5;
  color:#9295a4;
  background-color:transparent;
  border:solid transparent;
  border-width:1px 0
}
.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm{
  padding-right:0;
  padding-left:0
}
.form-control-sm{
  min-height:calc(1.5em + .5rem + 2px);
  padding:.25rem .5rem;
  font-size:.7875rem;
  border-radius:.2rem
}
.form-control-sm::file-selector-button{
  padding:.25rem .5rem;
  margin:-.25rem -.5rem;
  -webkit-margin-end:.5rem;
  margin-inline-end:.5rem
}
.form-control-sm::-webkit-file-upload-button{
  padding:.25rem .5rem;
  margin:-.25rem -.5rem;
  -webkit-margin-end:.5rem;
  margin-inline-end:.5rem
}
.form-control-lg{
  min-height:calc(1.5em + 1rem + 2px);
  padding:.5rem 1rem;
  font-size:1.125rem;
  border-radius:.3rem
}
.form-control-lg::file-selector-button{
  padding:.5rem 1rem;
  margin:-.5rem -1rem;
  -webkit-margin-end:1rem;
  margin-inline-end:1rem
}
.form-control-lg::-webkit-file-upload-button{
  padding:.5rem 1rem;
  margin:-.5rem -1rem;
  -webkit-margin-end:1rem;
  margin-inline-end:1rem
}
textarea.form-control{
  min-height:calc(1.5em + .94rem + 2px)
}
textarea.form-control-sm{
  min-height:calc(1.5em + .5rem + 2px)
}
textarea.form-control-lg{
  min-height:calc(1.5em + 1rem + 2px)
}
.form-control-color{
  width:3rem;
  height:auto;
  padding:.47rem
}
.form-control-color:not(:disabled):not([readonly]){
  cursor:pointer
}
.form-control-color::-moz-color-swatch{
  height:1.5em;
  border-radius:.25rem
}
.form-control-color::-webkit-color-swatch{
  height:1.5em;
  border-radius:.25rem
}
.form-select{
  display:block;
  width:100%;
  padding:.47rem 1.75rem .47rem .75rem;
  -moz-padding-start:calc(.75rem - 3px);
  font-size:.9rem;
  font-weight:400;
  line-height:1.5;
  color:#9295a4;
  background-color:#2f323f;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23eff2f7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right .75rem center;
  background-size:16px 12px;
  border:1px solid #343747;
  border-radius:.25rem;
  -webkit-transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none
}
@media (prefers-reduced-motion:reduce){
  .form-select{
      -webkit-transition:none;
      transition:none
  }
}
.form-select:focus{
  border-color:#383b4b;
  outline:0;
  -webkit-box-shadow:0 0 0 .15rem rgba(91,115,232,.25);
  box-shadow:0 0 0 .15rem rgba(91,115,232,.25)
}
.form-select[multiple],.form-select[size]:not([size="1"]){
  padding-right:.75rem;
  background-image:none
}
.form-select:disabled{
  color:#c3cbe4;
  background-color:#2b2e3b
}
.form-select:-moz-focusring{
  color:transparent;
  text-shadow:0 0 0 #9295a4
}
.form-select-sm{
  padding-top:.25rem;
  padding-bottom:.25rem;
  padding-left:.5rem;
  font-size:.7875rem;
  border-radius:.2rem
}
.form-select-lg{
  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-left:1rem;
  font-size:1.125rem;
  border-radius:.3rem
}
.form-check{
  display:block;
  min-height:1.35rem;
  padding-left:1.5em;
  margin-bottom:.125rem
}
.form-check .form-check-input{
  float:left;
  margin-left:-1.5em
}
.form-check-input{
  width:1em;
  height:1em;
  margin-top:.25em;
  vertical-align:top;
  background-color:#393c49;
  background-repeat:no-repeat;
  background-position:center;
  background-size:contain;
  border:1px solid #4e515c;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  -webkit-print-color-adjust:exact;
  color-adjust:exact;
  -webkit-transition:background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .form-check-input{
      -webkit-transition:none;
      transition:none
  }
}
.form-check-input[type=checkbox]{
  border-radius:.25em
}
.form-check-input[type=radio]{
  border-radius:50%
}
.form-check-input:active{
  -webkit-filter:brightness(90%);
  filter:brightness(90%)
}
.form-check-input:focus{
  border-color:#383b4b;
  outline:0;
  -webkit-box-shadow:none;
  box-shadow:none
}
.form-check-input:checked{
  background-color:#5b73e8;
  border-color:#5b73e8
}
.form-check-input:checked[type=checkbox]{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}
.form-check-input:checked[type=radio]{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}
.form-check-input[type=checkbox]:indeterminate{
  background-color:#5b73e8;
  border-color:#5b73e8;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}
.form-check-input:disabled{
  pointer-events:none;
  -webkit-filter:none;
  filter:none;
  opacity:.5
}
.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label{
  opacity:.5
}
.form-switch{
  padding-left:2.5em
}
.form-switch .form-check-input{
  width:2em;
  margin-left:-2.5em;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
  background-position:left center;
  border-radius:2em;
  -webkit-transition:background-position .15s ease-in-out;
  transition:background-position .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .form-switch .form-check-input{
      -webkit-transition:none;
      transition:none
  }
}
.form-switch .form-check-input:focus{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23383b4b'/%3e%3c/svg%3e")
}
.form-switch .form-check-input:checked{
  background-position:right center;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}
.form-check-inline{
  display:inline-block;
  margin-right:1rem
}
.btn-check{
  position:absolute;
  clip:rect(0,0,0,0);
  pointer-events:none
}
.btn-check:disabled+.btn,.btn-check[disabled]+.btn{
  pointer-events:none;
  -webkit-filter:none;
  filter:none;
  opacity:.65
}
.form-range{
  width:100%;
  height:1.3rem;
  padding:0;
  background-color:transparent;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none
}
.form-range:focus{
  outline:0
}
.form-range:focus::-webkit-slider-thumb{
  -webkit-box-shadow:0 0 0 1px #22252f,none;
  box-shadow:0 0 0 1px #22252f,none
}
.form-range:focus::-moz-range-thumb{
  box-shadow:0 0 0 1px #22252f,none
}
.form-range::-moz-focus-outer{
  border:0
}
.form-range::-webkit-slider-thumb{
  width:1rem;
  height:1rem;
  margin-top:-.25rem;
  background-color:#5b73e8;
  border:0;
  border-radius:1rem;
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  -webkit-appearance:none;
  appearance:none
}
@media (prefers-reduced-motion:reduce){
  .form-range::-webkit-slider-thumb{
      -webkit-transition:none;
      transition:none
  }
}
.form-range::-webkit-slider-thumb:active{
  background-color:#ced5f8
}
.form-range::-webkit-slider-runnable-track{
  width:100%;
  height:.5rem;
  color:transparent;
  cursor:pointer;
  background-color:#343747;
  border-color:transparent;
  border-radius:1rem
}
.form-range::-moz-range-thumb{
  width:1rem;
  height:1rem;
  background-color:#5b73e8;
  border:0;
  border-radius:1rem;
  -moz-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-appearance:none;
  appearance:none
}
@media (prefers-reduced-motion:reduce){
  .form-range::-moz-range-thumb{
      -moz-transition:none;
      transition:none
  }
}
.form-range::-moz-range-thumb:active{
  background-color:#ced5f8
}
.form-range::-moz-range-track{
  width:100%;
  height:.5rem;
  color:transparent;
  cursor:pointer;
  background-color:#343747;
  border-color:transparent;
  border-radius:1rem
}
.form-range:disabled{
  pointer-events:none
}
.form-range:disabled::-webkit-slider-thumb{
  background-color:#9699a8
}
.form-range:disabled::-moz-range-thumb{
  background-color:#9699a8
}
.form-floating{
  position:relative
}
.form-floating>.form-control,.form-floating>.form-select{
  height:calc(3.5rem + 2px);
  line-height:1.25
}
.form-floating>label{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  padding:1rem .75rem;
  pointer-events:none;
  border:1px solid transparent;
  -webkit-transform-origin:0 0;
  transform-origin:0 0;
  -webkit-transition:opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
  transition:opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
  transition:opacity .1s ease-in-out,transform .1s ease-in-out;
  transition:opacity .1s ease-in-out,transform .1s ease-in-out,-webkit-transform .1s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .form-floating>label{
      -webkit-transition:none;
      transition:none
  }
}
.form-floating>.form-control{
  padding:1rem .75rem
}
.form-floating>.form-control::-webkit-input-placeholder{
  color:transparent
}
.form-floating>.form-control::-moz-placeholder{
  color:transparent
}
.form-floating>.form-control:-ms-input-placeholder{
  color:transparent
}
.form-floating>.form-control::-ms-input-placeholder{
  color:transparent
}
.form-floating>.form-control::placeholder{
  color:transparent
}
.form-floating>.form-control:not(:-moz-placeholder-shown){
  padding-top:1.625rem;
  padding-bottom:.625rem
}
.form-floating>.form-control:not(:-ms-input-placeholder){
  padding-top:1.625rem;
  padding-bottom:.625rem
}
.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown){
  padding-top:1.625rem;
  padding-bottom:.625rem
}
.form-floating>.form-control:-webkit-autofill{
  padding-top:1.625rem;
  padding-bottom:.625rem
}
.form-floating>.form-select{
  padding-top:1.625rem;
  padding-bottom:.625rem
}
.form-floating>.form-control:not(:-moz-placeholder-shown)~label{
  opacity:.65;
  transform:scale(.85) translateY(-.5rem) translateX(.15rem)
}
.form-floating>.form-control:not(:-ms-input-placeholder)~label{
  opacity:.65;
  transform:scale(.85) translateY(-.5rem) translateX(.15rem)
}
.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label{
  opacity:.65;
  -webkit-transform:scale(.85) translateY(-.5rem) translateX(.15rem);
  transform:scale(.85) translateY(-.5rem) translateX(.15rem)
}
.form-floating>.form-control:-webkit-autofill~label{
  opacity:.65;
  -webkit-transform:scale(.85) translateY(-.5rem) translateX(.15rem);
  transform:scale(.85) translateY(-.5rem) translateX(.15rem)
}
.input-group{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-align:stretch;
  -ms-flex-align:stretch;
  align-items:stretch;
  width:100%
}
.input-group>.form-control,.input-group>.form-select{
  position:relative;
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto;
  width:1%;
  min-width:0
}
.input-group>.form-control:focus,.input-group>.form-select:focus{
  z-index:3
}
.input-group .btn{
  position:relative;
  z-index:2
}
.input-group .btn:focus{
  z-index:3
}
.input-group-text{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  padding:.47rem .75rem;
  font-size:.9rem;
  font-weight:400;
  line-height:1.5;
  color:#9295a4;
  text-align:center;
  white-space:nowrap;
  background-color:#343747;
  border:1px solid #343747;
  border-radius:.25rem
}
.input-group-lg>.btn,.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text{
  padding:.5rem 1rem;
  font-size:1.125rem;
  border-radius:.3rem
}
.input-group-sm>.btn,.input-group-sm>.form-control,.input-group-sm>.form-select,.input-group-sm>.input-group-text{
  padding:.25rem .5rem;
  font-size:.7875rem;
  border-radius:.2rem
}
.input-group-lg>.form-select,.input-group-sm>.form-select{
  padding-right:2.5rem
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  margin-left:-1px;
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.valid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:87.5%;
  color:#34c38f
}
.valid-tooltip{
  position:absolute;
  top:100%;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.4rem .7rem;
  margin-top:.1rem;
  font-size:.7875rem;
  color:#fff;
  background-color:rgba(52,195,143,.9);
  border-radius:.25rem
}
.is-valid~.valid-feedback,.is-valid~.valid-tooltip,.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip{
  display:block
}
.form-control.is-valid,.was-validated .form-control:valid{
  border-color:#34c38f;
  padding-right:calc(1.5em + .94rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334c38f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right calc(.375em + .235rem) center;
  background-size:calc(.75em + .47rem) calc(.75em + .47rem)
}
.form-control.is-valid:focus,.was-validated .form-control:valid:focus{
  border-color:#34c38f;
  -webkit-box-shadow:0 0 0 .15rem rgba(52,195,143,.25);
  box-shadow:0 0 0 .15rem rgba(52,195,143,.25)
}
.was-validated textarea.form-control:valid,textarea.form-control.is-valid{
  padding-right:calc(1.5em + .94rem);
  background-position:top calc(.375em + .235rem) right calc(.375em + .235rem)
}
.form-select.is-valid,.was-validated .form-select:valid{
  border-color:#34c38f
}
.form-select.is-valid:not([multiple]):not([size]),.form-select.is-valid:not([multiple])[size="1"],.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:valid:not([multiple])[size="1"]{
  padding-right:calc(.75em + 3.205rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23eff2f7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334c38f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position:right .75rem center,center right 2.5rem;
  background-size:16px 12px,calc(.75em + .47rem) calc(.75em + .47rem)
}
.form-select.is-valid:focus,.was-validated .form-select:valid:focus{
  border-color:#34c38f;
  -webkit-box-shadow:0 0 0 .15rem rgba(52,195,143,.25);
  box-shadow:0 0 0 .15rem rgba(52,195,143,.25)
}
.form-check-input.is-valid,.was-validated .form-check-input:valid{
  border-color:#34c38f
}
.form-check-input.is-valid:checked,.was-validated .form-check-input:valid:checked{
  background-color:#34c38f
}
.form-check-input.is-valid:focus,.was-validated .form-check-input:valid:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(52,195,143,.25);
  box-shadow:0 0 0 .15rem rgba(52,195,143,.25)
}
.form-check-input.is-valid~.form-check-label,.was-validated .form-check-input:valid~.form-check-label{
  color:#34c38f
}
.form-check-inline .form-check-input~.valid-feedback{
  margin-left:.5em
}
.input-group .form-control.is-valid,.input-group .form-select.is-valid,.was-validated .input-group .form-control:valid,.was-validated .input-group .form-select:valid{
  z-index:1
}
.input-group .form-control.is-valid:focus,.input-group .form-select.is-valid:focus,.was-validated .input-group .form-control:valid:focus,.was-validated .input-group .form-select:valid:focus{
  z-index:3
}
.invalid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:87.5%;
  color:#f46a6a
}
.invalid-tooltip{
  position:absolute;
  top:100%;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.4rem .7rem;
  margin-top:.1rem;
  font-size:.7875rem;
  color:#fff;
  background-color:rgba(244,106,106,.9);
  border-radius:.25rem
}
.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip,.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip{
  display:block
}
.form-control.is-invalid,.was-validated .form-control:invalid{
  border-color:#f46a6a;
  padding-right:calc(1.5em + .94rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right calc(.375em + .235rem) center;
  background-size:calc(.75em + .47rem) calc(.75em + .47rem)
}
.form-control.is-invalid:focus,.was-validated .form-control:invalid:focus{
  border-color:#f46a6a;
  -webkit-box-shadow:0 0 0 .15rem rgba(244,106,106,.25);
  box-shadow:0 0 0 .15rem rgba(244,106,106,.25)
}
.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid{
  padding-right:calc(1.5em + .94rem);
  background-position:top calc(.375em + .235rem) right calc(.375em + .235rem)
}
.form-select.is-invalid,.was-validated .form-select:invalid{
  border-color:#f46a6a
}
.form-select.is-invalid:not([multiple]):not([size]),.form-select.is-invalid:not([multiple])[size="1"],.was-validated .form-select:invalid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple])[size="1"]{
  padding-right:calc(.75em + 3.205rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23eff2f7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-position:right .75rem center,center right 2.5rem;
  background-size:16px 12px,calc(.75em + .47rem) calc(.75em + .47rem)
}
.form-select.is-invalid:focus,.was-validated .form-select:invalid:focus{
  border-color:#f46a6a;
  -webkit-box-shadow:0 0 0 .15rem rgba(244,106,106,.25);
  box-shadow:0 0 0 .15rem rgba(244,106,106,.25)
}
.form-check-input.is-invalid,.was-validated .form-check-input:invalid{
  border-color:#f46a6a
}
.form-check-input.is-invalid:checked,.was-validated .form-check-input:invalid:checked{
  background-color:#f46a6a
}
.form-check-input.is-invalid:focus,.was-validated .form-check-input:invalid:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(244,106,106,.25);
  box-shadow:0 0 0 .15rem rgba(244,106,106,.25)
}
.form-check-input.is-invalid~.form-check-label,.was-validated .form-check-input:invalid~.form-check-label{
  color:#f46a6a
}
.form-check-inline .form-check-input~.invalid-feedback{
  margin-left:.5em
}
.input-group .form-control.is-invalid,.input-group .form-select.is-invalid,.was-validated .input-group .form-control:invalid,.was-validated .input-group .form-select:invalid{
  z-index:2
}
.input-group .form-control.is-invalid:focus,.input-group .form-select.is-invalid:focus,.was-validated .input-group .form-control:invalid:focus,.was-validated .input-group .form-select:invalid:focus{
  z-index:3
}
.btn{
  display:inline-block;
  font-weight:400;
  line-height:1.5;
  color:#9295a4;
  text-align:center;
  vertical-align:middle;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  background-color:transparent;
  border:1px solid transparent;
  padding:.47rem .75rem;
  font-size:.9rem;
  border-radius:.25rem;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .btn{
      -webkit-transition:none;
      transition:none
  }
}
.btn:hover{
  color:#9295a4
}
.btn-check:focus+.btn,.btn:focus{
  outline:0;
  -webkit-box-shadow:0 0 0 .15rem rgba(91,115,232,.25);
  box-shadow:0 0 0 .15rem rgba(91,115,232,.25)
}
.btn.disabled,.btn:disabled,fieldset:disabled .btn{
  pointer-events:none;
  opacity:.65
}
.btn-primary{
  color:#fff;
  background-color:#5b73e8;
  border-color:#5b73e8
}
.btn-primary:hover{
  color:#fff;
  background-color:#4d62c5;
  border-color:#495cba
}
.btn-check:focus+.btn-primary,.btn-primary:focus{
  color:#fff;
  background-color:#4d62c5;
  border-color:#495cba;
  -webkit-box-shadow:0 0 0 .15rem rgba(116,136,235,.5);
  box-shadow:0 0 0 .15rem rgba(116,136,235,.5)
}
.btn-check:active+.btn-primary,.btn-check:checked+.btn-primary,.btn-primary.active,.btn-primary:active,.show>.btn-primary.dropdown-toggle{
  color:#fff;
  background-color:#495cba;
  border-color:#4456ae
}
.btn-check:active+.btn-primary:focus,.btn-check:checked+.btn-primary:focus,.btn-primary.active:focus,.btn-primary:active:focus,.show>.btn-primary.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(116,136,235,.5);
  box-shadow:0 0 0 .15rem rgba(116,136,235,.5)
}
.btn-primary.disabled,.btn-primary:disabled{
  color:#fff;
  background-color:#5b73e8;
  border-color:#5b73e8
}
.btn-secondary{
  color:#000;
  background-color:#c3cbe4;
  border-color:#c3cbe4
}
.btn-secondary:hover{
  color:#000;
  background-color:#ccd3e8;
  border-color:#c9d0e7
}
.btn-check:focus+.btn-secondary,.btn-secondary:focus{
  color:#000;
  background-color:#ccd3e8;
  border-color:#c9d0e7;
  -webkit-box-shadow:0 0 0 .15rem rgba(166,173,194,.5);
  box-shadow:0 0 0 .15rem rgba(166,173,194,.5)
}
.btn-check:active+.btn-secondary,.btn-check:checked+.btn-secondary,.btn-secondary.active,.btn-secondary:active,.show>.btn-secondary.dropdown-toggle{
  color:#000;
  background-color:#cfd5e9;
  border-color:#c9d0e7
}
.btn-check:active+.btn-secondary:focus,.btn-check:checked+.btn-secondary:focus,.btn-secondary.active:focus,.btn-secondary:active:focus,.show>.btn-secondary.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(166,173,194,.5);
  box-shadow:0 0 0 .15rem rgba(166,173,194,.5)
}
.btn-secondary.disabled,.btn-secondary:disabled{
  color:#000;
  background-color:#c3cbe4;
  border-color:#c3cbe4
}
.btn-success{
  color:#fff;
  background-color:#34c38f;
  border-color:#34c38f
}
.btn-success:hover{
  color:#fff;
  background-color:#2ca67a;
  border-color:#2a9c72
}
.btn-check:focus+.btn-success,.btn-success:focus{
  color:#fff;
  background-color:#2ca67a;
  border-color:#2a9c72;
  -webkit-box-shadow:0 0 0 .15rem rgba(82,204,160,.5);
  box-shadow:0 0 0 .15rem rgba(82,204,160,.5)
}
.btn-check:active+.btn-success,.btn-check:checked+.btn-success,.btn-success.active,.btn-success:active,.show>.btn-success.dropdown-toggle{
  color:#fff;
  background-color:#2a9c72;
  border-color:#27926b
}
.btn-check:active+.btn-success:focus,.btn-check:checked+.btn-success:focus,.btn-success.active:focus,.btn-success:active:focus,.show>.btn-success.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(82,204,160,.5);
  box-shadow:0 0 0 .15rem rgba(82,204,160,.5)
}
.btn-success.disabled,.btn-success:disabled{
  color:#fff;
  background-color:#34c38f;
  border-color:#34c38f
}
.btn-info{
  color:#fff;
  background-color:#50a5f1;
  border-color:#50a5f1
}
.btn-info:hover{
  color:#fff;
  background-color:#448ccd;
  border-color:#4084c1
}
.btn-check:focus+.btn-info,.btn-info:focus{
  color:#fff;
  background-color:#448ccd;
  border-color:#4084c1;
  -webkit-box-shadow:0 0 0 .15rem rgba(106,179,243,.5);
  box-shadow:0 0 0 .15rem rgba(106,179,243,.5)
}
.btn-check:active+.btn-info,.btn-check:checked+.btn-info,.btn-info.active,.btn-info:active,.show>.btn-info.dropdown-toggle{
  color:#fff;
  background-color:#4084c1;
  border-color:#3c7cb5
}
.btn-check:active+.btn-info:focus,.btn-check:checked+.btn-info:focus,.btn-info.active:focus,.btn-info:active:focus,.show>.btn-info.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(106,179,243,.5);
  box-shadow:0 0 0 .15rem rgba(106,179,243,.5)
}
.btn-info.disabled,.btn-info:disabled{
  color:#fff;
  background-color:#50a5f1;
  border-color:#50a5f1
}
.btn-warning{
  color:#fff;
  background-color:#f1b44c;
  border-color:#f1b44c
}
.btn-warning:hover{
  color:#fff;
  background-color:#cd9941;
  border-color:#c1903d
}
.btn-check:focus+.btn-warning,.btn-warning:focus{
  color:#fff;
  background-color:#cd9941;
  border-color:#c1903d;
  -webkit-box-shadow:0 0 0 .15rem rgba(243,191,103,.5);
  box-shadow:0 0 0 .15rem rgba(243,191,103,.5)
}
.btn-check:active+.btn-warning,.btn-check:checked+.btn-warning,.btn-warning.active,.btn-warning:active,.show>.btn-warning.dropdown-toggle{
  color:#fff;
  background-color:#c1903d;
  border-color:#b58739
}
.btn-check:active+.btn-warning:focus,.btn-check:checked+.btn-warning:focus,.btn-warning.active:focus,.btn-warning:active:focus,.show>.btn-warning.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(243,191,103,.5);
  box-shadow:0 0 0 .15rem rgba(243,191,103,.5)
}
.btn-warning.disabled,.btn-warning:disabled{
  color:#fff;
  background-color:#f1b44c;
  border-color:#f1b44c
}
.btn-danger{
  color:#fff;
  background-color:#f46a6a;
  border-color:#f46a6a
}
.btn-danger:hover{
  color:#fff;
  background-color:#cf5a5a;
  border-color:#c35555
}
.btn-check:focus+.btn-danger,.btn-danger:focus{
  color:#fff;
  background-color:#cf5a5a;
  border-color:#c35555;
  -webkit-box-shadow:0 0 0 .15rem rgba(246,128,128,.5);
  box-shadow:0 0 0 .15rem rgba(246,128,128,.5)
}
.btn-check:active+.btn-danger,.btn-check:checked+.btn-danger,.btn-danger.active,.btn-danger:active,.show>.btn-danger.dropdown-toggle{
  color:#fff;
  background-color:#c35555;
  border-color:#b75050
}
.btn-check:active+.btn-danger:focus,.btn-check:checked+.btn-danger:focus,.btn-danger.active:focus,.btn-danger:active:focus,.show>.btn-danger.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(246,128,128,.5);
  box-shadow:0 0 0 .15rem rgba(246,128,128,.5)
}
.btn-danger.disabled,.btn-danger:disabled{
  color:#fff;
  background-color:#f46a6a;
  border-color:#f46a6a
}
.btn-pink{
  color:#fff;
  background-color:#e83e8c;
  border-color:#e83e8c
}
.btn-pink:hover{
  color:#fff;
  background-color:#c53577;
  border-color:#ba3270
}
.btn-check:focus+.btn-pink,.btn-pink:focus{
  color:#fff;
  background-color:#c53577;
  border-color:#ba3270;
  -webkit-box-shadow:0 0 0 .15rem rgba(235,91,157,.5);
  box-shadow:0 0 0 .15rem rgba(235,91,157,.5)
}
.btn-check:active+.btn-pink,.btn-check:checked+.btn-pink,.btn-pink.active,.btn-pink:active,.show>.btn-pink.dropdown-toggle{
  color:#fff;
  background-color:#ba3270;
  border-color:#ae2f69
}
.btn-check:active+.btn-pink:focus,.btn-check:checked+.btn-pink:focus,.btn-pink.active:focus,.btn-pink:active:focus,.show>.btn-pink.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(235,91,157,.5);
  box-shadow:0 0 0 .15rem rgba(235,91,157,.5)
}
.btn-pink.disabled,.btn-pink:disabled{
  color:#fff;
  background-color:#e83e8c;
  border-color:#e83e8c
}
.btn-light{
  color:#fff;
  background-color:#343747;
  border-color:#343747
}
.btn-light:hover{
  color:#fff;
  background-color:#2c2f3c;
  border-color:#2a2c39
}
.btn-check:focus+.btn-light,.btn-light:focus{
  color:#fff;
  background-color:#2c2f3c;
  border-color:#2a2c39;
  -webkit-box-shadow:0 0 0 .15rem rgba(82,85,99,.5);
  box-shadow:0 0 0 .15rem rgba(82,85,99,.5)
}
.btn-check:active+.btn-light,.btn-check:checked+.btn-light,.btn-light.active,.btn-light:active,.show>.btn-light.dropdown-toggle{
  color:#fff;
  background-color:#2a2c39;
  border-color:#272935
}
.btn-check:active+.btn-light:focus,.btn-check:checked+.btn-light:focus,.btn-light.active:focus,.btn-light:active:focus,.show>.btn-light.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(82,85,99,.5);
  box-shadow:0 0 0 .15rem rgba(82,85,99,.5)
}
.btn-light.disabled,.btn-light:disabled{
  color:#fff;
  background-color:#343747;
  border-color:#343747
}
.btn-dark{
  color:#000;
  background-color:#eff2f7;
  border-color:#eff2f7
}
.btn-dark:hover{
  color:#000;
  background-color:#f1f4f8;
  border-color:#f1f3f8
}
.btn-check:focus+.btn-dark,.btn-dark:focus{
  color:#000;
  background-color:#f1f4f8;
  border-color:#f1f3f8;
  -webkit-box-shadow:0 0 0 .15rem rgba(203,206,210,.5);
  box-shadow:0 0 0 .15rem rgba(203,206,210,.5)
}
.btn-check:active+.btn-dark,.btn-check:checked+.btn-dark,.btn-dark.active,.btn-dark:active,.show>.btn-dark.dropdown-toggle{
  color:#000;
  background-color:#f2f5f9;
  border-color:#f1f3f8
}
.btn-check:active+.btn-dark:focus,.btn-check:checked+.btn-dark:focus,.btn-dark.active:focus,.btn-dark:active:focus,.show>.btn-dark.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(203,206,210,.5);
  box-shadow:0 0 0 .15rem rgba(203,206,210,.5)
}
.btn-dark.disabled,.btn-dark:disabled{
  color:#000;
  background-color:#eff2f7;
  border-color:#eff2f7
}
.btn-purple{
  color:#fff;
  background-color:#6f42c1;
  border-color:#6f42c1
}
.btn-purple:hover{
  color:#fff;
  background-color:#5e38a4;
  border-color:#59359a
}
.btn-check:focus+.btn-purple,.btn-purple:focus{
  color:#fff;
  background-color:#5e38a4;
  border-color:#59359a;
  -webkit-box-shadow:0 0 0 .15rem rgba(133,94,202,.5);
  box-shadow:0 0 0 .15rem rgba(133,94,202,.5)
}
.btn-check:active+.btn-purple,.btn-check:checked+.btn-purple,.btn-purple.active,.btn-purple:active,.show>.btn-purple.dropdown-toggle{
  color:#fff;
  background-color:#59359a;
  border-color:#533291
}
.btn-check:active+.btn-purple:focus,.btn-check:checked+.btn-purple:focus,.btn-purple.active:focus,.btn-purple:active:focus,.show>.btn-purple.dropdown-toggle:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(133,94,202,.5);
  box-shadow:0 0 0 .15rem rgba(133,94,202,.5)
}
.btn-purple.disabled,.btn-purple:disabled{
  color:#fff;
  background-color:#6f42c1;
  border-color:#6f42c1
}
.btn-outline-primary{
  color:#5b73e8;
  border-color:#5b73e8
}
.btn-outline-primary:hover{
  color:#fff;
  background-color:#5b73e8;
  border-color:#5b73e8
}
.btn-check:focus+.btn-outline-primary,.btn-outline-primary:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(91,115,232,.5);
  box-shadow:0 0 0 .15rem rgba(91,115,232,.5)
}
.btn-check:active+.btn-outline-primary,.btn-check:checked+.btn-outline-primary,.btn-outline-primary.active,.btn-outline-primary.dropdown-toggle.show,.btn-outline-primary:active{
  color:#fff;
  background-color:#5b73e8;
  border-color:#5b73e8
}
.btn-check:active+.btn-outline-primary:focus,.btn-check:checked+.btn-outline-primary:focus,.btn-outline-primary.active:focus,.btn-outline-primary.dropdown-toggle.show:focus,.btn-outline-primary:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(91,115,232,.5);
  box-shadow:0 0 0 .15rem rgba(91,115,232,.5)
}
.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color:#5b73e8;
  background-color:transparent
}
.btn-outline-secondary{
  color:#c3cbe4;
  border-color:#c3cbe4
}
.btn-outline-secondary:hover{
  color:#000;
  background-color:#c3cbe4;
  border-color:#c3cbe4
}
.btn-check:focus+.btn-outline-secondary,.btn-outline-secondary:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(195,203,228,.5);
  box-shadow:0 0 0 .15rem rgba(195,203,228,.5)
}
.btn-check:active+.btn-outline-secondary,.btn-check:checked+.btn-outline-secondary,.btn-outline-secondary.active,.btn-outline-secondary.dropdown-toggle.show,.btn-outline-secondary:active{
  color:#000;
  background-color:#c3cbe4;
  border-color:#c3cbe4
}
.btn-check:active+.btn-outline-secondary:focus,.btn-check:checked+.btn-outline-secondary:focus,.btn-outline-secondary.active:focus,.btn-outline-secondary.dropdown-toggle.show:focus,.btn-outline-secondary:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(195,203,228,.5);
  box-shadow:0 0 0 .15rem rgba(195,203,228,.5)
}
.btn-outline-secondary.disabled,.btn-outline-secondary:disabled{
  color:#c3cbe4;
  background-color:transparent
}
.btn-outline-success{
  color:#34c38f;
  border-color:#34c38f
}
.btn-outline-success:hover{
  color:#fff;
  background-color:#34c38f;
  border-color:#34c38f
}
.btn-check:focus+.btn-outline-success,.btn-outline-success:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(52,195,143,.5);
  box-shadow:0 0 0 .15rem rgba(52,195,143,.5)
}
.btn-check:active+.btn-outline-success,.btn-check:checked+.btn-outline-success,.btn-outline-success.active,.btn-outline-success.dropdown-toggle.show,.btn-outline-success:active{
  color:#fff;
  background-color:#34c38f;
  border-color:#34c38f
}
.btn-check:active+.btn-outline-success:focus,.btn-check:checked+.btn-outline-success:focus,.btn-outline-success.active:focus,.btn-outline-success.dropdown-toggle.show:focus,.btn-outline-success:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(52,195,143,.5);
  box-shadow:0 0 0 .15rem rgba(52,195,143,.5)
}
.btn-outline-success.disabled,.btn-outline-success:disabled{
  color:#34c38f;
  background-color:transparent
}
.btn-outline-info{
  color:#50a5f1;
  border-color:#50a5f1
}
.btn-outline-info:hover{
  color:#fff;
  background-color:#50a5f1;
  border-color:#50a5f1
}
.btn-check:focus+.btn-outline-info,.btn-outline-info:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(80,165,241,.5);
  box-shadow:0 0 0 .15rem rgba(80,165,241,.5)
}
.btn-check:active+.btn-outline-info,.btn-check:checked+.btn-outline-info,.btn-outline-info.active,.btn-outline-info.dropdown-toggle.show,.btn-outline-info:active{
  color:#fff;
  background-color:#50a5f1;
  border-color:#50a5f1
}
.btn-check:active+.btn-outline-info:focus,.btn-check:checked+.btn-outline-info:focus,.btn-outline-info.active:focus,.btn-outline-info.dropdown-toggle.show:focus,.btn-outline-info:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(80,165,241,.5);
  box-shadow:0 0 0 .15rem rgba(80,165,241,.5)
}
.btn-outline-info.disabled,.btn-outline-info:disabled{
  color:#50a5f1;
  background-color:transparent
}
.btn-outline-warning{
  color:#f1b44c;
  border-color:#f1b44c
}
.btn-outline-warning:hover{
  color:#fff;
  background-color:#f1b44c;
  border-color:#f1b44c
}
.btn-check:focus+.btn-outline-warning,.btn-outline-warning:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(241,180,76,.5);
  box-shadow:0 0 0 .15rem rgba(241,180,76,.5)
}
.btn-check:active+.btn-outline-warning,.btn-check:checked+.btn-outline-warning,.btn-outline-warning.active,.btn-outline-warning.dropdown-toggle.show,.btn-outline-warning:active{
  color:#fff;
  background-color:#f1b44c;
  border-color:#f1b44c
}
.btn-check:active+.btn-outline-warning:focus,.btn-check:checked+.btn-outline-warning:focus,.btn-outline-warning.active:focus,.btn-outline-warning.dropdown-toggle.show:focus,.btn-outline-warning:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(241,180,76,.5);
  box-shadow:0 0 0 .15rem rgba(241,180,76,.5)
}
.btn-outline-warning.disabled,.btn-outline-warning:disabled{
  color:#f1b44c;
  background-color:transparent
}
.btn-outline-danger{
  color:#f46a6a;
  border-color:#f46a6a
}
.btn-outline-danger:hover{
  color:#fff;
  background-color:#f46a6a;
  border-color:#f46a6a
}
.btn-check:focus+.btn-outline-danger,.btn-outline-danger:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(244,106,106,.5);
  box-shadow:0 0 0 .15rem rgba(244,106,106,.5)
}
.btn-check:active+.btn-outline-danger,.btn-check:checked+.btn-outline-danger,.btn-outline-danger.active,.btn-outline-danger.dropdown-toggle.show,.btn-outline-danger:active{
  color:#fff;
  background-color:#f46a6a;
  border-color:#f46a6a
}
.btn-check:active+.btn-outline-danger:focus,.btn-check:checked+.btn-outline-danger:focus,.btn-outline-danger.active:focus,.btn-outline-danger.dropdown-toggle.show:focus,.btn-outline-danger:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(244,106,106,.5);
  box-shadow:0 0 0 .15rem rgba(244,106,106,.5)
}
.btn-outline-danger.disabled,.btn-outline-danger:disabled{
  color:#f46a6a;
  background-color:transparent
}
.btn-outline-pink{
  color:#e83e8c;
  border-color:#e83e8c
}
.btn-outline-pink:hover{
  color:#fff;
  background-color:#e83e8c;
  border-color:#e83e8c
}
.btn-check:focus+.btn-outline-pink,.btn-outline-pink:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(232,62,140,.5);
  box-shadow:0 0 0 .15rem rgba(232,62,140,.5)
}
.btn-check:active+.btn-outline-pink,.btn-check:checked+.btn-outline-pink,.btn-outline-pink.active,.btn-outline-pink.dropdown-toggle.show,.btn-outline-pink:active{
  color:#fff;
  background-color:#e83e8c;
  border-color:#e83e8c
}
.btn-check:active+.btn-outline-pink:focus,.btn-check:checked+.btn-outline-pink:focus,.btn-outline-pink.active:focus,.btn-outline-pink.dropdown-toggle.show:focus,.btn-outline-pink:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(232,62,140,.5);
  box-shadow:0 0 0 .15rem rgba(232,62,140,.5)
}
.btn-outline-pink.disabled,.btn-outline-pink:disabled{
  color:#e83e8c;
  background-color:transparent
}
.btn-outline-light{
  color:#343747;
  border-color:#343747
}
.btn-outline-light:hover{
  color:#fff;
  background-color:#343747;
  border-color:#343747
}
.btn-check:focus+.btn-outline-light,.btn-outline-light:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(52,55,71,.5);
  box-shadow:0 0 0 .15rem rgba(52,55,71,.5)
}
.btn-check:active+.btn-outline-light,.btn-check:checked+.btn-outline-light,.btn-outline-light.active,.btn-outline-light.dropdown-toggle.show,.btn-outline-light:active{
  color:#fff;
  background-color:#343747;
  border-color:#343747
}
.btn-check:active+.btn-outline-light:focus,.btn-check:checked+.btn-outline-light:focus,.btn-outline-light.active:focus,.btn-outline-light.dropdown-toggle.show:focus,.btn-outline-light:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(52,55,71,.5);
  box-shadow:0 0 0 .15rem rgba(52,55,71,.5)
}
.btn-outline-light.disabled,.btn-outline-light:disabled{
  color:#343747;
  background-color:transparent
}
.btn-outline-dark{
  color:#eff2f7;
  border-color:#eff2f7
}
.btn-outline-dark:hover{
  color:#000;
  background-color:#eff2f7;
  border-color:#eff2f7
}
.btn-check:focus+.btn-outline-dark,.btn-outline-dark:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(239,242,247,.5);
  box-shadow:0 0 0 .15rem rgba(239,242,247,.5)
}
.btn-check:active+.btn-outline-dark,.btn-check:checked+.btn-outline-dark,.btn-outline-dark.active,.btn-outline-dark.dropdown-toggle.show,.btn-outline-dark:active{
  color:#000;
  background-color:#eff2f7;
  border-color:#eff2f7
}
.btn-check:active+.btn-outline-dark:focus,.btn-check:checked+.btn-outline-dark:focus,.btn-outline-dark.active:focus,.btn-outline-dark.dropdown-toggle.show:focus,.btn-outline-dark:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(239,242,247,.5);
  box-shadow:0 0 0 .15rem rgba(239,242,247,.5)
}
.btn-outline-dark.disabled,.btn-outline-dark:disabled{
  color:#eff2f7;
  background-color:transparent
}
.btn-outline-purple{
  color:#6f42c1;
  border-color:#6f42c1
}
.btn-outline-purple:hover{
  color:#fff;
  background-color:#6f42c1;
  border-color:#6f42c1
}
.btn-check:focus+.btn-outline-purple,.btn-outline-purple:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(111,66,193,.5);
  box-shadow:0 0 0 .15rem rgba(111,66,193,.5)
}
.btn-check:active+.btn-outline-purple,.btn-check:checked+.btn-outline-purple,.btn-outline-purple.active,.btn-outline-purple.dropdown-toggle.show,.btn-outline-purple:active{
  color:#fff;
  background-color:#6f42c1;
  border-color:#6f42c1
}
.btn-check:active+.btn-outline-purple:focus,.btn-check:checked+.btn-outline-purple:focus,.btn-outline-purple.active:focus,.btn-outline-purple.dropdown-toggle.show:focus,.btn-outline-purple:active:focus{
  -webkit-box-shadow:0 0 0 .15rem rgba(111,66,193,.5);
  box-shadow:0 0 0 .15rem rgba(111,66,193,.5)
}
.btn-outline-purple.disabled,.btn-outline-purple:disabled{
  color:#6f42c1;
  background-color:transparent
}
.btn-link{
  font-weight:400;
  color:#5b73e8;
  text-decoration:none
}
.btn-link:hover{
  color:#1e3ed8
}
.btn-link.disabled,.btn-link:disabled{
  color:#c3cbe4
}
.btn-group-lg>.btn,.btn-lg{
  padding:.5rem 1rem;
  font-size:1.125rem;
  border-radius:.3rem
}
.btn-group-sm>.btn,.btn-sm{
  padding:.25rem .5rem;
  font-size:.7875rem;
  border-radius:.2rem
}
.fade{
  -webkit-transition:opacity .15s linear;
  transition:opacity .15s linear
}
@media (prefers-reduced-motion:reduce){
  .fade{
      -webkit-transition:none;
      transition:none
  }
}
.fade:not(.show){
  opacity:0
}
.collapse:not(.show){
  display:none
}
.collapsing{
  height:0;
  overflow:hidden;
  -webkit-transition:height .35s ease;
  transition:height .35s ease
}
@media (prefers-reduced-motion:reduce){
  .collapsing{
      -webkit-transition:none;
      transition:none
  }
}
.collapsing.collapse-horizontal{
  width:0;
  height:auto;
  -webkit-transition:width .35s ease;
  transition:width .35s ease
}
@media (prefers-reduced-motion:reduce){
  .collapsing.collapse-horizontal{
      -webkit-transition:none;
      transition:none
  }
}
.dropdown,.dropend,.dropstart,.dropup{
  position:relative
}
.dropdown-toggle{
  white-space:nowrap
}
.dropdown-menu{
  position:absolute;
  z-index:1000;
  display:none;
  min-width:10rem;
  padding:.5rem 0;
  margin:0;
  font-size:.9rem;
  color:#9295a4;
  text-align:left;
  list-style:none;
  background-color:#2b2e3b;
  background-clip:padding-box;
  border:1px solid #333643;
  border-radius:.25rem
}
.dropdown-menu[data-bs-popper]{
  top:100%;
  left:0;
  margin-top:.125rem
}
.dropdown-menu-start{
  --bs-position:start
}
.dropdown-menu-start[data-bs-popper]{
  right:auto;
  left:0
}
.dropdown-menu-end{
  --bs-position:end
}
.dropdown-menu-end[data-bs-popper]{
  right:0;
  left:auto
}
@media (min-width:576px){
  .dropdown-menu-sm-start{
      --bs-position:start
  }
  .dropdown-menu-sm-start[data-bs-popper]{
      right:auto;
      left:0
  }
  .dropdown-menu-sm-end{
      --bs-position:end
  }
  .dropdown-menu-sm-end[data-bs-popper]{
      right:0;
      left:auto
  }
}
@media (min-width:768px){
  .dropdown-menu-md-start{
      --bs-position:start
  }
  .dropdown-menu-md-start[data-bs-popper]{
      right:auto;
      left:0
  }
  .dropdown-menu-md-end{
      --bs-position:end
  }
  .dropdown-menu-md-end[data-bs-popper]{
      right:0;
      left:auto
  }
}
@media (min-width:992px){
  .dropdown-menu-lg-start{
      --bs-position:start
  }
  .dropdown-menu-lg-start[data-bs-popper]{
      right:auto;
      left:0
  }
  .dropdown-menu-lg-end{
      --bs-position:end
  }
  .dropdown-menu-lg-end[data-bs-popper]{
      right:0;
      left:auto
  }
}
@media (min-width:1200px){
  .dropdown-menu-xl-start{
      --bs-position:start
  }
  .dropdown-menu-xl-start[data-bs-popper]{
      right:auto;
      left:0
  }
  .dropdown-menu-xl-end{
      --bs-position:end
  }
  .dropdown-menu-xl-end[data-bs-popper]{
      right:0;
      left:auto
  }
}
@media (min-width:1400px){
  .dropdown-menu-xxl-start{
      --bs-position:start
  }
  .dropdown-menu-xxl-start[data-bs-popper]{
      right:auto;
      left:0
  }
  .dropdown-menu-xxl-end{
      --bs-position:end
  }
  .dropdown-menu-xxl-end[data-bs-popper]{
      right:0;
      left:auto
  }
}
.dropup .dropdown-menu[data-bs-popper]{
  top:auto;
  bottom:100%;
  margin-top:0;
  margin-bottom:.125rem
}
.dropend .dropdown-menu[data-bs-popper]{
  top:0;
  right:auto;
  left:100%;
  margin-top:0;
  margin-left:.125rem
}
.dropend .dropdown-toggle::after{
  vertical-align:0
}
.dropstart .dropdown-menu[data-bs-popper]{
  top:0;
  right:100%;
  left:auto;
  margin-top:0;
  margin-right:.125rem
}
.dropstart .dropdown-toggle::before{
  vertical-align:0
}
.dropdown-divider{
  height:0;
  margin:.5rem 0;
  overflow:hidden;
  border-top:1px solid #333643
}
.dropdown-item{
  display:block;
  width:100%;
  padding:.35rem 1.5rem;
  clear:both;
  font-weight:400;
  color:#9699a8;
  text-align:inherit;
  white-space:nowrap;
  background-color:transparent;
  border:0
}
.dropdown-item:focus,.dropdown-item:hover{
  color:#f8f9fa;
  background-color:#343747
}
.dropdown-item.active,.dropdown-item:active{
  color:#f8f9fa;
  text-decoration:none;
  background-color:#343747
}
.dropdown-item.disabled,.dropdown-item:disabled{
  color:#c3cbe4;
  pointer-events:none;
  background-color:transparent
}
.dropdown-menu.show{
  display:block
}
.dropdown-header{
  display:block;
  padding:.5rem 1.5rem;
  margin-bottom:0;
  font-size:.7875rem;
  color:#c3cbe4;
  white-space:nowrap
}
.dropdown-item-text{
  display:block;
  padding:.35rem 1.5rem;
  color:#9699a8
}
.dropdown-menu-dark{
  color:#343747;
  background-color:#eff2f7;
  border-color:#333643
}
.dropdown-menu-dark .dropdown-item{
  color:#343747
}
.dropdown-menu-dark .dropdown-item:focus,.dropdown-menu-dark .dropdown-item:hover{
  color:#fff;
  background-color:rgba(255,255,255,.15)
}
.dropdown-menu-dark .dropdown-item.active,.dropdown-menu-dark .dropdown-item:active{
  color:#f8f9fa;
  background-color:#343747
}
.dropdown-menu-dark .dropdown-item.disabled,.dropdown-menu-dark .dropdown-item:disabled{
  color:#9699a8
}
.dropdown-menu-dark .dropdown-divider{
  border-color:#333643
}
.dropdown-menu-dark .dropdown-item-text{
  color:#343747
}
.dropdown-menu-dark .dropdown-header{
  color:#9699a8
}
.btn-group,.btn-group-vertical{
  position:relative;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  vertical-align:middle
}
.btn-group-vertical>.btn,.btn-group>.btn{
  position:relative;
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto
}
.btn-group-vertical>.btn-check:checked+.btn,.btn-group-vertical>.btn-check:focus+.btn,.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn-check:checked+.btn,.btn-group>.btn-check:focus+.btn,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover{
  z-index:1
}
.btn-toolbar{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start
}
.btn-toolbar .input-group{
  width:auto
}
.btn-group>.btn-group:not(:first-child),.btn-group>.btn:not(:first-child){
  margin-left:-1px
}
.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn:not(:last-child):not(.dropdown-toggle){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:nth-child(n+3),.btn-group>:not(.btn-check)+.btn{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.dropdown-toggle-split{
  padding-right:.5625rem;
  padding-left:.5625rem
}
.dropdown-toggle-split::after,.dropend .dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after{
  margin-left:0
}
.dropstart .dropdown-toggle-split::before{
  margin-right:0
}
.btn-group-sm>.btn+.dropdown-toggle-split,.btn-sm+.dropdown-toggle-split{
  padding-right:.375rem;
  padding-left:.375rem
}
.btn-group-lg>.btn+.dropdown-toggle-split,.btn-lg+.dropdown-toggle-split{
  padding-right:.75rem;
  padding-left:.75rem
}
.btn-group-vertical{
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-align:start;
  -ms-flex-align:start;
  align-items:flex-start;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.btn-group-vertical>.btn,.btn-group-vertical>.btn-group{
  width:100%
}
.btn-group-vertical>.btn-group:not(:first-child),.btn-group-vertical>.btn:not(:first-child){
  margin-top:-1px
}
.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle){
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}
.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn~.btn{
  border-top-left-radius:0;
  border-top-right-radius:0
}
.nav{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.nav-link{
  display:block;
  padding:.5rem 1rem;
  color:#5b73e8;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .nav-link{
      -webkit-transition:none;
      transition:none
  }
}
.nav-link:focus,.nav-link:hover{
  color:#1e3ed8
}
.nav-link.disabled{
  color:#c3cbe4;
  pointer-events:none;
  cursor:default
}
.nav-tabs{
  border-bottom:1px solid #343747
}
.nav-tabs .nav-link{
  margin-bottom:-1px;
  background:0 0;
  border:1px solid transparent;
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}
.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover{
  border-color:#2b2e3b #2b2e3b #343747;
  isolation:isolate
}
.nav-tabs .nav-link.disabled{
  color:#c3cbe4;
  background-color:transparent;
  border-color:transparent
}
.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active{
  color:#f6f6f6;
  background-color:#2b2e3b;
  border-color:#343747 #343747 #2b2e3b
}
.nav-tabs .dropdown-menu{
  margin-top:-1px;
  border-top-left-radius:0;
  border-top-right-radius:0
}
.nav-pills .nav-link{
  background:0 0;
  border:0;
  border-radius:.25rem
}
.nav-pills .nav-link.active,.nav-pills .show>.nav-link{
  color:#fff;
  background-color:#5b73e8
}
.nav-fill .nav-item,.nav-fill>.nav-link{
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto;
  text-align:center
}
.nav-justified .nav-item,.nav-justified>.nav-link{
  -ms-flex-preferred-size:0;
  flex-basis:0;
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  text-align:center
}
.nav-fill .nav-item .nav-link,.nav-justified .nav-item .nav-link{
  width:100%
}
.tab-content>.tab-pane{
  display:none
}
.tab-content>.active{
  display:block
}
.navbar{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  padding-top:.5rem;
  padding-bottom:.5rem
}
.navbar>.container,.navbar>.container-fluid,.navbar>.container-lg,.navbar>.container-md,.navbar>.container-sm,.navbar>.container-xl,.navbar>.container-xxl{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:inherit;
  flex-wrap:inherit;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between
}
.navbar-brand{
  padding-top:.33125rem;
  padding-bottom:.33125rem;
  margin-right:1rem;
  font-size:1.125rem;
  white-space:nowrap
}
.navbar-nav{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.navbar-nav .nav-link{
  padding-right:0;
  padding-left:0
}
.navbar-nav .dropdown-menu{
  position:static
}
.navbar-text{
  padding-top:.5rem;
  padding-bottom:.5rem
}
.navbar-collapse{
  -ms-flex-preferred-size:100%;
  flex-basis:100%;
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.navbar-toggler{
  padding:.25rem .75rem;
  font-size:1.125rem;
  line-height:1;
  background-color:transparent;
  border:1px solid transparent;
  border-radius:.25rem;
  -webkit-transition:-webkit-box-shadow .15s ease-in-out;
  transition:-webkit-box-shadow .15s ease-in-out;
  transition:box-shadow .15s ease-in-out;
  transition:box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .navbar-toggler{
      -webkit-transition:none;
      transition:none
  }
}
.navbar-toggler:hover{
  text-decoration:none
}
.navbar-toggler:focus{
  text-decoration:none;
  outline:0;
  -webkit-box-shadow:0 0 0 .15rem;
  box-shadow:0 0 0 .15rem
}
.navbar-toggler-icon{
  display:inline-block;
  width:1.5em;
  height:1.5em;
  vertical-align:middle;
  background-repeat:no-repeat;
  background-position:center;
  background-size:100%
}
.navbar-nav-scroll{
  max-height:var(--bs-scroll-height,75vh);
  overflow-y:auto
}
@media (min-width:576px){
  .navbar-expand-sm{
      -ms-flex-wrap:nowrap;
      flex-wrap:nowrap;
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
  .navbar-expand-sm .navbar-nav{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu{
      position:absolute
  }
  .navbar-expand-sm .navbar-nav .nav-link{
      padding-right:.5rem;
      padding-left:.5rem
  }
  .navbar-expand-sm .navbar-nav-scroll{
      overflow:visible
  }
  .navbar-expand-sm .navbar-collapse{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important;
      -ms-flex-preferred-size:auto;
      flex-basis:auto
  }
  .navbar-expand-sm .navbar-toggler{
      display:none
  }
  .navbar-expand-sm .offcanvas-header{
      display:none
  }
  .navbar-expand-sm .offcanvas{
      position:inherit;
      bottom:0;
      z-index:1000;
      -webkit-box-flex:1;
      -ms-flex-positive:1;
      flex-grow:1;
      visibility:visible!important;
      background-color:transparent;
      border-right:0;
      border-left:0;
      -webkit-transition:none;
      transition:none;
      -webkit-transform:none;
      transform:none
  }
  .navbar-expand-sm .offcanvas-bottom,.navbar-expand-sm .offcanvas-top{
      height:auto;
      border-top:0;
      border-bottom:0
  }
  .navbar-expand-sm .offcanvas-body{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-flex:0;
      -ms-flex-positive:0;
      flex-grow:0;
      padding:0;
      overflow-y:visible
  }
}
@media (min-width:768px){
  .navbar-expand-md{
      -ms-flex-wrap:nowrap;
      flex-wrap:nowrap;
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
  .navbar-expand-md .navbar-nav{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .navbar-expand-md .navbar-nav .dropdown-menu{
      position:absolute
  }
  .navbar-expand-md .navbar-nav .nav-link{
      padding-right:.5rem;
      padding-left:.5rem
  }
  .navbar-expand-md .navbar-nav-scroll{
      overflow:visible
  }
  .navbar-expand-md .navbar-collapse{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important;
      -ms-flex-preferred-size:auto;
      flex-basis:auto
  }
  .navbar-expand-md .navbar-toggler{
      display:none
  }
  .navbar-expand-md .offcanvas-header{
      display:none
  }
  .navbar-expand-md .offcanvas{
      position:inherit;
      bottom:0;
      z-index:1000;
      -webkit-box-flex:1;
      -ms-flex-positive:1;
      flex-grow:1;
      visibility:visible!important;
      background-color:transparent;
      border-right:0;
      border-left:0;
      -webkit-transition:none;
      transition:none;
      -webkit-transform:none;
      transform:none
  }
  .navbar-expand-md .offcanvas-bottom,.navbar-expand-md .offcanvas-top{
      height:auto;
      border-top:0;
      border-bottom:0
  }
  .navbar-expand-md .offcanvas-body{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-flex:0;
      -ms-flex-positive:0;
      flex-grow:0;
      padding:0;
      overflow-y:visible
  }
}
@media (min-width:992px){
  .navbar-expand-lg{
      -ms-flex-wrap:nowrap;
      flex-wrap:nowrap;
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
  .navbar-expand-lg .navbar-nav{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu{
      position:absolute
  }
  .navbar-expand-lg .navbar-nav .nav-link{
      padding-right:.5rem;
      padding-left:.5rem
  }
  .navbar-expand-lg .navbar-nav-scroll{
      overflow:visible
  }
  .navbar-expand-lg .navbar-collapse{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important;
      -ms-flex-preferred-size:auto;
      flex-basis:auto
  }
  .navbar-expand-lg .navbar-toggler{
      display:none
  }
  .navbar-expand-lg .offcanvas-header{
      display:none
  }
  .navbar-expand-lg .offcanvas{
      position:inherit;
      bottom:0;
      z-index:1000;
      -webkit-box-flex:1;
      -ms-flex-positive:1;
      flex-grow:1;
      visibility:visible!important;
      background-color:transparent;
      border-right:0;
      border-left:0;
      -webkit-transition:none;
      transition:none;
      -webkit-transform:none;
      transform:none
  }
  .navbar-expand-lg .offcanvas-bottom,.navbar-expand-lg .offcanvas-top{
      height:auto;
      border-top:0;
      border-bottom:0
  }
  .navbar-expand-lg .offcanvas-body{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-flex:0;
      -ms-flex-positive:0;
      flex-grow:0;
      padding:0;
      overflow-y:visible
  }
}
@media (min-width:1200px){
  .navbar-expand-xl{
      -ms-flex-wrap:nowrap;
      flex-wrap:nowrap;
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
  .navbar-expand-xl .navbar-nav{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu{
      position:absolute
  }
  .navbar-expand-xl .navbar-nav .nav-link{
      padding-right:.5rem;
      padding-left:.5rem
  }
  .navbar-expand-xl .navbar-nav-scroll{
      overflow:visible
  }
  .navbar-expand-xl .navbar-collapse{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important;
      -ms-flex-preferred-size:auto;
      flex-basis:auto
  }
  .navbar-expand-xl .navbar-toggler{
      display:none
  }
  .navbar-expand-xl .offcanvas-header{
      display:none
  }
  .navbar-expand-xl .offcanvas{
      position:inherit;
      bottom:0;
      z-index:1000;
      -webkit-box-flex:1;
      -ms-flex-positive:1;
      flex-grow:1;
      visibility:visible!important;
      background-color:transparent;
      border-right:0;
      border-left:0;
      -webkit-transition:none;
      transition:none;
      -webkit-transform:none;
      transform:none
  }
  .navbar-expand-xl .offcanvas-bottom,.navbar-expand-xl .offcanvas-top{
      height:auto;
      border-top:0;
      border-bottom:0
  }
  .navbar-expand-xl .offcanvas-body{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-flex:0;
      -ms-flex-positive:0;
      flex-grow:0;
      padding:0;
      overflow-y:visible
  }
}
@media (min-width:1400px){
  .navbar-expand-xxl{
      -ms-flex-wrap:nowrap;
      flex-wrap:nowrap;
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
  .navbar-expand-xxl .navbar-nav{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu{
      position:absolute
  }
  .navbar-expand-xxl .navbar-nav .nav-link{
      padding-right:.5rem;
      padding-left:.5rem
  }
  .navbar-expand-xxl .navbar-nav-scroll{
      overflow:visible
  }
  .navbar-expand-xxl .navbar-collapse{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important;
      -ms-flex-preferred-size:auto;
      flex-basis:auto
  }
  .navbar-expand-xxl .navbar-toggler{
      display:none
  }
  .navbar-expand-xxl .offcanvas-header{
      display:none
  }
  .navbar-expand-xxl .offcanvas{
      position:inherit;
      bottom:0;
      z-index:1000;
      -webkit-box-flex:1;
      -ms-flex-positive:1;
      flex-grow:1;
      visibility:visible!important;
      background-color:transparent;
      border-right:0;
      border-left:0;
      -webkit-transition:none;
      transition:none;
      -webkit-transform:none;
      transform:none
  }
  .navbar-expand-xxl .offcanvas-bottom,.navbar-expand-xxl .offcanvas-top{
      height:auto;
      border-top:0;
      border-bottom:0
  }
  .navbar-expand-xxl .offcanvas-body{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-flex:0;
      -ms-flex-positive:0;
      flex-grow:0;
      padding:0;
      overflow-y:visible
  }
}
.navbar-expand{
  -ms-flex-wrap:nowrap;
  flex-wrap:nowrap;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start
}
.navbar-expand .navbar-nav{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-direction:row;
  flex-direction:row
}
.navbar-expand .navbar-nav .dropdown-menu{
  position:absolute
}
.navbar-expand .navbar-nav .nav-link{
  padding-right:.5rem;
  padding-left:.5rem
}
.navbar-expand .navbar-nav-scroll{
  overflow:visible
}
.navbar-expand .navbar-collapse{
  display:-webkit-box!important;
  display:-ms-flexbox!important;
  display:flex!important;
  -ms-flex-preferred-size:auto;
  flex-basis:auto
}
.navbar-expand .navbar-toggler{
  display:none
}
.navbar-expand .offcanvas-header{
  display:none
}
.navbar-expand .offcanvas{
  position:inherit;
  bottom:0;
  z-index:1000;
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  visibility:visible!important;
  background-color:transparent;
  border-right:0;
  border-left:0;
  -webkit-transition:none;
  transition:none;
  -webkit-transform:none;
  transform:none
}
.navbar-expand .offcanvas-bottom,.navbar-expand .offcanvas-top{
  height:auto;
  border-top:0;
  border-bottom:0
}
.navbar-expand .offcanvas-body{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:0;
  -ms-flex-positive:0;
  flex-grow:0;
  padding:0;
  overflow-y:visible
}
.navbar-light .navbar-brand{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-brand:focus,.navbar-light .navbar-brand:hover{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-nav .nav-link{
  color:rgba(0,0,0,.55)
}
.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover{
  color:rgba(0,0,0,.7)
}
.navbar-light .navbar-nav .nav-link.disabled{
  color:rgba(0,0,0,.3)
}
.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .show>.nav-link{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-toggler{
  color:rgba(0,0,0,.55);
  border-color:rgba(0,0,0,.1)
}
.navbar-light .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
.navbar-light .navbar-text{
  color:rgba(0,0,0,.55)
}
.navbar-light .navbar-text a,.navbar-light .navbar-text a:focus,.navbar-light .navbar-text a:hover{
  color:rgba(0,0,0,.9)
}
.navbar-dark .navbar-brand{
  color:#fff
}
.navbar-dark .navbar-brand:focus,.navbar-dark .navbar-brand:hover{
  color:#fff
}
.navbar-dark .navbar-nav .nav-link{
  color:rgba(255,255,255,.55)
}
.navbar-dark .navbar-nav .nav-link:focus,.navbar-dark .navbar-nav .nav-link:hover{
  color:rgba(255,255,255,.75)
}
.navbar-dark .navbar-nav .nav-link.disabled{
  color:rgba(255,255,255,.25)
}
.navbar-dark .navbar-nav .nav-link.active,.navbar-dark .navbar-nav .show>.nav-link{
  color:#fff
}
.navbar-dark .navbar-toggler{
  color:rgba(255,255,255,.55);
  border-color:rgba(255,255,255,.1)
}
.navbar-dark .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
.navbar-dark .navbar-text{
  color:rgba(255,255,255,.55)
}
.navbar-dark .navbar-text a,.navbar-dark .navbar-text a:focus,.navbar-dark .navbar-text a:hover{
  color:#fff
}
.card{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  min-width:0;
  word-wrap:break-word;
  background-color:#2b2e3b;
  background-clip:border-box;
  border:0 solid #343747;
  border-radius:.25rem
}
.card>hr{
  margin-right:0;
  margin-left:0
}
.card>.list-group{
  border-top:inherit;
  border-bottom:inherit
}
.card>.list-group:first-child{
  border-top-width:0;
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}
.card>.list-group:last-child{
  border-bottom-width:0;
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:.25rem
}
.card>.card-header+.list-group,.card>.list-group+.card-footer{
  border-top:0
}
.card-body{
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto;
  padding:1.25rem 1.25rem
}
.card-title{
  margin-bottom:.5rem
}
.card-subtitle{
  margin-top:-.25rem;
  margin-bottom:0
}
.card-text:last-child{
  margin-bottom:0
}
.card-link+.card-link{
  margin-left:1.25rem
}
.card-header{
  padding:.75rem 1.25rem;
  margin-bottom:0;
  background-color:rgba(0,0,0,.03);
  border-bottom:0 solid #343747
}
.card-header:first-child{
  border-radius:.25rem .25rem 0 0
}
.card-footer{
  padding:.75rem 1.25rem;
  background-color:rgba(0,0,0,.03);
  border-top:0 solid #343747
}
.card-footer:last-child{
  border-radius:0 0 .25rem .25rem
}
.card-header-tabs{
  margin-right:-.625rem;
  margin-bottom:-.75rem;
  margin-left:-.625rem;
  border-bottom:0
}
.card-header-pills{
  margin-right:-.625rem;
  margin-left:-.625rem
}
.card-img-overlay{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  padding:1rem;
  border-radius:.25rem
}
.card-img,.card-img-bottom,.card-img-top{
  width:100%
}
.card-img,.card-img-top{
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}
.card-img,.card-img-bottom{
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:.25rem
}
.card-group>.card{
  margin-bottom:.625rem
}
@media (min-width:576px){
  .card-group{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-flow:row wrap;
      flex-flow:row wrap
  }
  .card-group>.card{
      -webkit-box-flex:1;
      -ms-flex:1 0 0%;
      flex:1 0 0%;
      margin-bottom:0
  }
  .card-group>.card+.card{
      margin-left:0;
      border-left:0
  }
  .card-group>.card:not(:last-child){
      border-top-right-radius:0;
      border-bottom-right-radius:0
  }
  .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top{
      border-top-right-radius:0
  }
  .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom{
      border-bottom-right-radius:0
  }
  .card-group>.card:not(:first-child){
      border-top-left-radius:0;
      border-bottom-left-radius:0
  }
  .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top{
      border-top-left-radius:0
  }
  .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom{
      border-bottom-left-radius:0
  }
}
.accordion-button{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  width:100%;
  padding:1rem 1.25rem;
  font-size:.9rem;
  color:#9295a4;
  text-align:left;
  background-color:transparent;
  border:0;
  border-radius:0;
  overflow-anchor:none;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .accordion-button{
      -webkit-transition:none;
      transition:none
  }
}
.accordion-button:not(.collapsed){
  color:#5268d1;
  background-color:#eff1fd;
  -webkit-box-shadow:inset 0 -1px 0 rgba(0,0,0,.125);
  box-shadow:inset 0 -1px 0 rgba(0,0,0,.125)
}
.accordion-button:not(.collapsed)::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235268d1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg)
}
.accordion-button::after{
  -ms-flex-negative:0;
  flex-shrink:0;
  width:16px;
  height:16px;
  margin-left:auto;
  content:"";
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239295a4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-size:16px;
  -webkit-transition:-webkit-transform .2s ease-in-out;
  transition:-webkit-transform .2s ease-in-out;
  transition:transform .2s ease-in-out;
  transition:transform .2s ease-in-out,-webkit-transform .2s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .accordion-button::after{
      -webkit-transition:none;
      transition:none
  }
}
.accordion-button:hover{
  z-index:2
}
.accordion-button:focus{
  z-index:3;
  border-color:#383b4b;
  outline:0;
  -webkit-box-shadow:none;
  box-shadow:none
}
.accordion-header{
  margin-bottom:0
}
.accordion-item{
  background-color:transparent;
  border:1px solid rgba(0,0,0,.125)
}
.accordion-item:first-of-type{
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}
.accordion-item:first-of-type .accordion-button{
  border-top-left-radius:calc(.25rem - 1px);
  border-top-right-radius:calc(.25rem - 1px)
}
.accordion-item:not(:first-of-type){
  border-top:0
}
.accordion-item:last-of-type{
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:.25rem
}
.accordion-item:last-of-type .accordion-button.collapsed{
  border-bottom-right-radius:calc(.25rem - 1px);
  border-bottom-left-radius:calc(.25rem - 1px)
}
.accordion-item:last-of-type .accordion-collapse{
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:.25rem
}
.accordion-body{
  padding:1rem 1.25rem
}
.accordion-flush .accordion-collapse{
  border-width:0
}
.accordion-flush .accordion-item{
  border-right:0;
  border-left:0;
  border-radius:0
}
.accordion-flush .accordion-item:first-child{
  border-top:0
}
.accordion-flush .accordion-item:last-child{
  border-bottom:0
}
.accordion-flush .accordion-item .accordion-button{
  border-radius:0
}
.breadcrumb{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  padding:0 0;
  margin-bottom:1rem;
  list-style:none
}
.breadcrumb-item+.breadcrumb-item{
  padding-left:.5rem
}
.breadcrumb-item+.breadcrumb-item::before{
  float:left;
  padding-right:.5rem;
  color:#9295a4;
  content:var(--bs-breadcrumb-divider, "/")
}
.breadcrumb-item.active{
  color:#9295a4
}
.pagination{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  padding-left:0;
  list-style:none
}
.page-link{
  position:relative;
  display:block;
  color:#9699a8;
  background-color:#2b2e3b;
  border:1px solid #343747;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .page-link{
      -webkit-transition:none;
      transition:none
  }
}
.page-link:hover{
  z-index:2;
  color:#1e3ed8;
  background-color:#2b2e3b;
  border-color:#343747
}
.page-link:focus{
  z-index:3;
  color:#1e3ed8;
  background-color:#2b2e3b;
  outline:0;
  -webkit-box-shadow:0 0 0 .15rem rgba(91,115,232,.25);
  box-shadow:0 0 0 .15rem rgba(91,115,232,.25)
}
.page-item:not(:first-child) .page-link{
  margin-left:-1px
}
.page-item.active .page-link{
  z-index:3;
  color:#fff;
  background-color:#5b73e8;
  border-color:#5b73e8
}
.page-item.disabled .page-link{
  color:#c3cbe4;
  pointer-events:none;
  background-color:#343747;
  border-color:#343747
}
.page-link{
  padding:.375rem .75rem
}
.page-item:first-child .page-link{
  border-top-left-radius:.25rem;
  border-bottom-left-radius:.25rem
}
.page-item:last-child .page-link{
  border-top-right-radius:.25rem;
  border-bottom-right-radius:.25rem
}
.pagination-lg .page-link{
  padding:.75rem 1.5rem;
  font-size:1.125rem
}
.pagination-lg .page-item:first-child .page-link{
  border-top-left-radius:.3rem;
  border-bottom-left-radius:.3rem
}
.pagination-lg .page-item:last-child .page-link{
  border-top-right-radius:.3rem;
  border-bottom-right-radius:.3rem
}
.pagination-sm .page-link{
  padding:.25rem .5rem;
  font-size:.7875rem
}
.pagination-sm .page-item:first-child .page-link{
  border-top-left-radius:.2rem;
  border-bottom-left-radius:.2rem
}
.pagination-sm .page-item:last-child .page-link{
  border-top-right-radius:.2rem;
  border-bottom-right-radius:.2rem
}
.badge{
  display:inline-block;
  padding:.25em .4em;
  font-size:75%;
  font-weight:500;
  line-height:1;
  color:#fff;
  text-align:center;
  white-space:nowrap;
  vertical-align:baseline;
  border-radius:.25rem
}
.badge:empty{
  display:none
}
.btn .badge{
  position:relative;
  top:-1px
}
.alert{
  position:relative;
  padding:.75rem 1.25rem;
  margin-bottom:1rem;
  border:1px solid transparent;
  border-radius:.25rem
}
.alert-heading{
  color:inherit
}
.alert-link{
  font-weight:600
}
.alert-dismissible{
  padding-right:3.75rem
}
.alert-dismissible .btn-close{
  position:absolute;
  top:0;
  right:0;
  z-index:2;
  padding:.9375rem 1.25rem
}
.alert-primary{
  color:#37458b;
  background-color:#dee3fa;
  border-color:#ced5f8
}
.alert-primary .alert-link{
  color:#2c376f
}
.alert-secondary{
  color:#757a89;
  background-color:#f3f5fa;
  border-color:#edeff7
}
.alert-secondary .alert-link{
  color:#5e626e
}
.alert-success{
  color:#1f7556;
  background-color:#d6f3e9;
  border-color:#c2eddd
}
.alert-success .alert-link{
  color:#195e45
}
.alert-info{
  color:#306391;
  background-color:#dcedfc;
  border-color:#cbe4fb
}
.alert-info .alert-link{
  color:#264f74
}
.alert-warning{
  color:#916c2e;
  background-color:#fcf0db;
  border-color:#fbe9c9
}
.alert-warning .alert-link{
  color:#745625
}
.alert-danger{
  color:#924040;
  background-color:#fde1e1;
  border-color:#fcd2d2
}
.alert-danger .alert-link{
  color:#753333
}
.alert-pink{
  color:#8b2554;
  background-color:#fad8e8;
  border-color:#f8c5dd
}
.alert-pink .alert-link{
  color:#6f1e43
}
.alert-light{
  color:#1f212b;
  background-color:#d6d7da;
  border-color:#c2c3c8
}
.alert-light .alert-link{
  color:#191a22
}
.alert-dark{
  color:#8f9194;
  background-color:#fcfcfd;
  border-color:#fafbfd
}
.alert-dark .alert-link{
  color:#727476
}
.alert-purple{
  color:#432874;
  background-color:#e2d9f3;
  border-color:#d4c6ec
}
.alert-purple .alert-link{
  color:#36205d
}
@-webkit-keyframes progress-bar-stripes{
  0%{
      background-position-x:.625rem
  }
}
@keyframes progress-bar-stripes{
  0%{
      background-position-x:.625rem
  }
}
.progress{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:.625rem;
  overflow:hidden;
  font-size:.675rem;
  background-color:#343747;
  border-radius:.25rem
}
.progress-bar{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  overflow:hidden;
  color:#fff;
  text-align:center;
  white-space:nowrap;
  background-color:#5b73e8;
  -webkit-transition:width .6s ease;
  transition:width .6s ease
}
@media (prefers-reduced-motion:reduce){
  .progress-bar{
      -webkit-transition:none;
      transition:none
  }
}
.progress-bar-striped{
  background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size:.625rem .625rem
}
.progress-bar-animated{
  -webkit-animation:1s linear infinite progress-bar-stripes;
  animation:1s linear infinite progress-bar-stripes
}
@media (prefers-reduced-motion:reduce){
  .progress-bar-animated{
      -webkit-animation:none;
      animation:none
  }
}
.list-group{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  padding-left:0;
  margin-bottom:0;
  border-radius:.25rem
}
.list-group-numbered{
  list-style-type:none;
  counter-reset:section
}
.list-group-numbered>li::before{
  content:counters(section, ".") ". ";
  counter-increment:section
}
.list-group-item-action{
  width:100%;
  color:#f6f6f6;
  text-align:inherit
}
.list-group-item-action:focus,.list-group-item-action:hover{
  z-index:1;
  color:#f6f6f6;
  text-decoration:none;
  background-color:#212529
}
.list-group-item-action:active{
  color:#9295a4;
  background-color:#2b2e3b
}
.list-group-item{
  position:relative;
  display:block;
  padding:.75rem 1.25rem;
  color:#f8f9fa;
  background-color:#2b2e3b;
  border:1px solid #343747
}
.list-group-item:first-child{
  border-top-left-radius:inherit;
  border-top-right-radius:inherit
}
.list-group-item:last-child{
  border-bottom-right-radius:inherit;
  border-bottom-left-radius:inherit
}
.list-group-item.disabled,.list-group-item:disabled{
  color:#c3cbe4;
  pointer-events:none;
  background-color:#2b2e3b
}
.list-group-item.active{
  z-index:2;
  color:#fff;
  background-color:#5b73e8;
  border-color:#5b73e8
}
.list-group-item+.list-group-item{
  border-top-width:0
}
.list-group-item+.list-group-item.active{
  margin-top:-1px;
  border-top-width:1px
}
.list-group-horizontal{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-direction:row;
  flex-direction:row
}
.list-group-horizontal>.list-group-item:first-child{
  border-bottom-left-radius:.25rem;
  border-top-right-radius:0
}
.list-group-horizontal>.list-group-item:last-child{
  border-top-right-radius:.25rem;
  border-bottom-left-radius:0
}
.list-group-horizontal>.list-group-item.active{
  margin-top:0
}
.list-group-horizontal>.list-group-item+.list-group-item{
  border-top-width:1px;
  border-left-width:0
}
.list-group-horizontal>.list-group-item+.list-group-item.active{
  margin-left:-1px;
  border-left-width:1px
}
@media (min-width:576px){
  .list-group-horizontal-sm{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .list-group-horizontal-sm>.list-group-item:first-child{
      border-bottom-left-radius:.25rem;
      border-top-right-radius:0
  }
  .list-group-horizontal-sm>.list-group-item:last-child{
      border-top-right-radius:.25rem;
      border-bottom-left-radius:0
  }
  .list-group-horizontal-sm>.list-group-item.active{
      margin-top:0
  }
  .list-group-horizontal-sm>.list-group-item+.list-group-item{
      border-top-width:1px;
      border-left-width:0
  }
  .list-group-horizontal-sm>.list-group-item+.list-group-item.active{
      margin-left:-1px;
      border-left-width:1px
  }
}
@media (min-width:768px){
  .list-group-horizontal-md{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .list-group-horizontal-md>.list-group-item:first-child{
      border-bottom-left-radius:.25rem;
      border-top-right-radius:0
  }
  .list-group-horizontal-md>.list-group-item:last-child{
      border-top-right-radius:.25rem;
      border-bottom-left-radius:0
  }
  .list-group-horizontal-md>.list-group-item.active{
      margin-top:0
  }
  .list-group-horizontal-md>.list-group-item+.list-group-item{
      border-top-width:1px;
      border-left-width:0
  }
  .list-group-horizontal-md>.list-group-item+.list-group-item.active{
      margin-left:-1px;
      border-left-width:1px
  }
}
@media (min-width:992px){
  .list-group-horizontal-lg{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .list-group-horizontal-lg>.list-group-item:first-child{
      border-bottom-left-radius:.25rem;
      border-top-right-radius:0
  }
  .list-group-horizontal-lg>.list-group-item:last-child{
      border-top-right-radius:.25rem;
      border-bottom-left-radius:0
  }
  .list-group-horizontal-lg>.list-group-item.active{
      margin-top:0
  }
  .list-group-horizontal-lg>.list-group-item+.list-group-item{
      border-top-width:1px;
      border-left-width:0
  }
  .list-group-horizontal-lg>.list-group-item+.list-group-item.active{
      margin-left:-1px;
      border-left-width:1px
  }
}
@media (min-width:1200px){
  .list-group-horizontal-xl{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .list-group-horizontal-xl>.list-group-item:first-child{
      border-bottom-left-radius:.25rem;
      border-top-right-radius:0
  }
  .list-group-horizontal-xl>.list-group-item:last-child{
      border-top-right-radius:.25rem;
      border-bottom-left-radius:0
  }
  .list-group-horizontal-xl>.list-group-item.active{
      margin-top:0
  }
  .list-group-horizontal-xl>.list-group-item+.list-group-item{
      border-top-width:1px;
      border-left-width:0
  }
  .list-group-horizontal-xl>.list-group-item+.list-group-item.active{
      margin-left:-1px;
      border-left-width:1px
  }
}
@media (min-width:1400px){
  .list-group-horizontal-xxl{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
  .list-group-horizontal-xxl>.list-group-item:first-child{
      border-bottom-left-radius:.25rem;
      border-top-right-radius:0
  }
  .list-group-horizontal-xxl>.list-group-item:last-child{
      border-top-right-radius:.25rem;
      border-bottom-left-radius:0
  }
  .list-group-horizontal-xxl>.list-group-item.active{
      margin-top:0
  }
  .list-group-horizontal-xxl>.list-group-item+.list-group-item{
      border-top-width:1px;
      border-left-width:0
  }
  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active{
      margin-left:-1px;
      border-left-width:1px
  }
}
.list-group-flush{
  border-radius:0
}
.list-group-flush>.list-group-item{
  border-width:0 0 1px
}
.list-group-flush>.list-group-item:last-child{
  border-bottom-width:0
}
.list-group-item-primary{
  color:#37458b;
  background-color:#dee3fa
}
.list-group-item-primary.list-group-item-action:focus,.list-group-item-primary.list-group-item-action:hover{
  color:#37458b;
  background-color:#c8cce1
}
.list-group-item-primary.list-group-item-action.active{
  color:#fff;
  background-color:#37458b;
  border-color:#37458b
}
.list-group-item-secondary{
  color:#757a89;
  background-color:#f3f5fa
}
.list-group-item-secondary.list-group-item-action:focus,.list-group-item-secondary.list-group-item-action:hover{
  color:#757a89;
  background-color:#dbdde1
}
.list-group-item-secondary.list-group-item-action.active{
  color:#fff;
  background-color:#757a89;
  border-color:#757a89
}
.list-group-item-success{
  color:#1f7556;
  background-color:#d6f3e9
}
.list-group-item-success.list-group-item-action:focus,.list-group-item-success.list-group-item-action:hover{
  color:#1f7556;
  background-color:#c1dbd2
}
.list-group-item-success.list-group-item-action.active{
  color:#fff;
  background-color:#1f7556;
  border-color:#1f7556
}
.list-group-item-info{
  color:#306391;
  background-color:#dcedfc
}
.list-group-item-info.list-group-item-action:focus,.list-group-item-info.list-group-item-action:hover{
  color:#306391;
  background-color:#c6d5e3
}
.list-group-item-info.list-group-item-action.active{
  color:#fff;
  background-color:#306391;
  border-color:#306391
}
.list-group-item-warning{
  color:#916c2e;
  background-color:#fcf0db
}
.list-group-item-warning.list-group-item-action:focus,.list-group-item-warning.list-group-item-action:hover{
  color:#916c2e;
  background-color:#e3d8c5
}
.list-group-item-warning.list-group-item-action.active{
  color:#fff;
  background-color:#916c2e;
  border-color:#916c2e
}
.list-group-item-danger{
  color:#924040;
  background-color:#fde1e1
}
.list-group-item-danger.list-group-item-action:focus,.list-group-item-danger.list-group-item-action:hover{
  color:#924040;
  background-color:#e4cbcb
}
.list-group-item-danger.list-group-item-action.active{
  color:#fff;
  background-color:#924040;
  border-color:#924040
}
.list-group-item-pink{
  color:#8b2554;
  background-color:#fad8e8
}
.list-group-item-pink.list-group-item-action:focus,.list-group-item-pink.list-group-item-action:hover{
  color:#8b2554;
  background-color:#e1c2d1
}
.list-group-item-pink.list-group-item-action.active{
  color:#fff;
  background-color:#8b2554;
  border-color:#8b2554
}
.list-group-item-light{
  color:#1f212b;
  background-color:#d6d7da
}
.list-group-item-light.list-group-item-action:focus,.list-group-item-light.list-group-item-action:hover{
  color:#1f212b;
  background-color:#c1c2c4
}
.list-group-item-light.list-group-item-action.active{
  color:#fff;
  background-color:#1f212b;
  border-color:#1f212b
}
.list-group-item-dark{
  color:#8f9194;
  background-color:#fcfcfd
}
.list-group-item-dark.list-group-item-action:focus,.list-group-item-dark.list-group-item-action:hover{
  color:#8f9194;
  background-color:#e3e3e4
}
.list-group-item-dark.list-group-item-action.active{
  color:#fff;
  background-color:#8f9194;
  border-color:#8f9194
}
.list-group-item-purple{
  color:#432874;
  background-color:#e2d9f3
}
.list-group-item-purple.list-group-item-action:focus,.list-group-item-purple.list-group-item-action:hover{
  color:#432874;
  background-color:#cbc3db
}
.list-group-item-purple.list-group-item-action.active{
  color:#fff;
  background-color:#432874;
  border-color:#432874
}
.btn-close{
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
  width:1em;
  height:1em;
  padding:.25em .25em;
  color:#fff;
  background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border:0;
  border-radius:.25rem;
  opacity:.5
}
.btn-close:hover{
  color:#fff;
  text-decoration:none;
  opacity:.75
}
.btn-close:focus{
  outline:0;
  -webkit-box-shadow:none;
  box-shadow:none;
  opacity:1
}
.btn-close.disabled,.btn-close:disabled{
  pointer-events:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  opacity:.25
}
.btn-close-white{
  -webkit-filter:invert(1) grayscale(100%) brightness(200%);
  filter:invert(1) grayscale(100%) brightness(200%)
}
.toast{
  width:350px;
  max-width:100%;
  font-size:.875rem;
  pointer-events:auto;
  background-color:#343747;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.1);
  -webkit-box-shadow:0 2px 4px rgba(15,34,58,.12);
  box-shadow:0 2px 4px rgba(15,34,58,.12);
  border-radius:.25rem
}
.toast.showing{
  opacity:0
}
.toast:not(.show){
  display:none
}
.toast-container{
  width:-webkit-max-content;
  width:-moz-max-content;
  width:max-content;
  max-width:100%;
  pointer-events:none
}
.toast-container>:not(:last-child){
  margin-bottom:.625rem
}
.toast-header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  padding:.5rem .75rem;
  color:#c3cbe4;
  background-color:rgba(52,55,71,.85);
  background-clip:padding-box;
  border-bottom:1px solid rgba(0,0,0,.05);
  border-top-left-radius:calc(.25rem - 1px);
  border-top-right-radius:calc(.25rem - 1px)
}
.toast-header .btn-close{
  margin-right:-.375rem;
  margin-left:.75rem
}
.toast-body{
  padding:.75rem;
  word-wrap:break-word
}
.modal{
  position:fixed;
  top:0;
  left:0;
  z-index:1055;
  display:none;
  width:100%;
  height:100%;
  overflow-x:hidden;
  overflow-y:auto;
  outline:0
}
.modal-dialog{
  position:relative;
  width:auto;
  margin:.5rem;
  pointer-events:none
}
.modal.fade .modal-dialog{
  -webkit-transition:-webkit-transform .3s ease-out;
  transition:-webkit-transform .3s ease-out;
  transition:transform .3s ease-out;
  transition:transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform:translate(0,-50px);
  transform:translate(0,-50px)
}
@media (prefers-reduced-motion:reduce){
  .modal.fade .modal-dialog{
      -webkit-transition:none;
      transition:none
  }
}
.modal.show .modal-dialog{
  -webkit-transform:none;
  transform:none
}
.modal.modal-static .modal-dialog{
  -webkit-transform:scale(1.02);
  transform:scale(1.02)
}
.modal-dialog-scrollable{
  height:calc(100% - 1rem)
}
.modal-dialog-scrollable .modal-content{
  max-height:100%;
  overflow:hidden
}
.modal-dialog-scrollable .modal-body{
  overflow-y:auto
}
.modal-dialog-centered{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  min-height:calc(100% - 1rem)
}
.modal-content{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  width:100%;
  pointer-events:auto;
  background-color:#2b2e3b;
  background-clip:padding-box;
  border:1px solid #343747;
  border-radius:.3rem;
  outline:0
}
.modal-backdrop{
  position:fixed;
  top:0;
  left:0;
  z-index:1050;
  width:100vw;
  height:100vh;
  background-color:#000
}
.modal-backdrop.fade{
  opacity:0
}
.modal-backdrop.show{
  opacity:.5
}
.modal-header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-negative:0;
  flex-shrink:0;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  padding:1rem 1rem;
  border-bottom:1px solid #343747;
  border-top-left-radius:calc(.3rem - 1px);
  border-top-right-radius:calc(.3rem - 1px)
}
.modal-header .btn-close{
  padding:.5rem .5rem;
  margin:-.5rem -.5rem -.5rem auto
}
.modal-title{
  margin-bottom:0;
  line-height:1.5
}
.modal-body{
  position:relative;
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto;
  padding:1rem
}
.modal-footer{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -ms-flex-negative:0;
  flex-shrink:0;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
  padding:.75rem;
  border-top:1px solid #343747;
  border-bottom-right-radius:calc(.3rem - 1px);
  border-bottom-left-radius:calc(.3rem - 1px)
}
.modal-footer>*{
  margin:.25rem
}
@media (min-width:576px){
  .modal-dialog{
      max-width:500px;
      margin:1.75rem auto
  }
  .modal-dialog-scrollable{
      height:calc(100% - 3.5rem)
  }
  .modal-dialog-centered{
      min-height:calc(100% - 3.5rem)
  }
  .modal-sm{
      max-width:300px
  }
}
@media (min-width:992px){
  .modal-lg,.modal-xl{
      max-width:800px
  }
}
@media (min-width:1200px){
  .modal-xl{
      max-width:1140px
  }
}
.modal-fullscreen{
  width:100vw;
  max-width:none;
  height:100%;
  margin:0
}
.modal-fullscreen .modal-content{
  height:100%;
  border:0;
  border-radius:0
}
.modal-fullscreen .modal-header{
  border-radius:0
}
.modal-fullscreen .modal-body{
  overflow-y:auto
}
.modal-fullscreen .modal-footer{
  border-radius:0
}
@media (max-width:575.98px){
  .modal-fullscreen-sm-down{
      width:100vw;
      max-width:none;
      height:100%;
      margin:0
  }
  .modal-fullscreen-sm-down .modal-content{
      height:100%;
      border:0;
      border-radius:0
  }
  .modal-fullscreen-sm-down .modal-header{
      border-radius:0
  }
  .modal-fullscreen-sm-down .modal-body{
      overflow-y:auto
  }
  .modal-fullscreen-sm-down .modal-footer{
      border-radius:0
  }
}
@media (max-width:767.98px){
  .modal-fullscreen-md-down{
      width:100vw;
      max-width:none;
      height:100%;
      margin:0
  }
  .modal-fullscreen-md-down .modal-content{
      height:100%;
      border:0;
      border-radius:0
  }
  .modal-fullscreen-md-down .modal-header{
      border-radius:0
  }
  .modal-fullscreen-md-down .modal-body{
      overflow-y:auto
  }
  .modal-fullscreen-md-down .modal-footer{
      border-radius:0
  }
}
@media (max-width:991.98px){
  .modal-fullscreen-lg-down{
      width:100vw;
      max-width:none;
      height:100%;
      margin:0
  }
  .modal-fullscreen-lg-down .modal-content{
      height:100%;
      border:0;
      border-radius:0
  }
  .modal-fullscreen-lg-down .modal-header{
      border-radius:0
  }
  .modal-fullscreen-lg-down .modal-body{
      overflow-y:auto
  }
  .modal-fullscreen-lg-down .modal-footer{
      border-radius:0
  }
}
@media (max-width:1199.98px){
  .modal-fullscreen-xl-down{
      width:100vw;
      max-width:none;
      height:100%;
      margin:0
  }
  .modal-fullscreen-xl-down .modal-content{
      height:100%;
      border:0;
      border-radius:0
  }
  .modal-fullscreen-xl-down .modal-header{
      border-radius:0
  }
  .modal-fullscreen-xl-down .modal-body{
      overflow-y:auto
  }
  .modal-fullscreen-xl-down .modal-footer{
      border-radius:0
  }
}
@media (max-width:1399.98px){
  .modal-fullscreen-xxl-down{
      width:100vw;
      max-width:none;
      height:100%;
      margin:0
  }
  .modal-fullscreen-xxl-down .modal-content{
      height:100%;
      border:0;
      border-radius:0
  }
  .modal-fullscreen-xxl-down .modal-header{
      border-radius:0
  }
  .modal-fullscreen-xxl-down .modal-body{
      overflow-y:auto
  }
  .modal-fullscreen-xxl-down .modal-footer{
      border-radius:0
  }
}
.tooltip{
  position:absolute;
  z-index:1080;
  display:block;
  margin:0;
  font-family:"IBM Plex Sans",sans-serif;
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:.7875rem;
  word-wrap:break-word;
  opacity:0
}
.tooltip.show{
  opacity:.9
}
.tooltip .tooltip-arrow{
  position:absolute;
  display:block;
  width:.8rem;
  height:.4rem
}
.tooltip .tooltip-arrow::before{
  position:absolute;
  content:"";
  border-color:transparent;
  border-style:solid
}
.bs-tooltip-auto[data-popper-placement^=top],.bs-tooltip-top{
  padding:.4rem 0
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,.bs-tooltip-top .tooltip-arrow{
  bottom:0
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,.bs-tooltip-top .tooltip-arrow::before{
  top:-1px;
  border-width:.4rem .4rem 0;
  border-top-color:#000
}
.bs-tooltip-auto[data-popper-placement^=right],.bs-tooltip-end{
  padding:0 .4rem
}
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,.bs-tooltip-end .tooltip-arrow{
  left:0;
  width:.4rem;
  height:.8rem
}
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,.bs-tooltip-end .tooltip-arrow::before{
  right:-1px;
  border-width:.4rem .4rem .4rem 0;
  border-right-color:#000
}
.bs-tooltip-auto[data-popper-placement^=bottom],.bs-tooltip-bottom{
  padding:.4rem 0
}
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,.bs-tooltip-bottom .tooltip-arrow{
  top:0
}
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,.bs-tooltip-bottom .tooltip-arrow::before{
  bottom:-1px;
  border-width:0 .4rem .4rem;
  border-bottom-color:#000
}
.bs-tooltip-auto[data-popper-placement^=left],.bs-tooltip-start{
  padding:0 .4rem
}
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,.bs-tooltip-start .tooltip-arrow{
  right:0;
  width:.4rem;
  height:.8rem
}
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,.bs-tooltip-start .tooltip-arrow::before{
  left:-1px;
  border-width:.4rem 0 .4rem .4rem;
  border-left-color:#000
}
.tooltip-inner{
  max-width:200px;
  padding:.4rem .7rem;
  color:#fff;
  text-align:center;
  background-color:#000;
  border-radius:.25rem
}
.popover{
  position:absolute;
  top:0;
  left:0;
  z-index:1070;
  display:block;
  max-width:276px;
  font-family:"IBM Plex Sans",sans-serif;
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:.7875rem;
  word-wrap:break-word;
  background-color:#343747;
  background-clip:padding-box;
  border:1px solid rgba(255,255,255,.05);
  border-radius:.3rem
}
.popover .popover-arrow{
  position:absolute;
  display:block;
  width:1rem;
  height:.5rem
}
.popover .popover-arrow::after,.popover .popover-arrow::before{
  position:absolute;
  display:block;
  content:"";
  border-color:transparent;
  border-style:solid
}
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,.bs-popover-top>.popover-arrow{
  bottom:calc(-.5rem - 1px)
}
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,.bs-popover-top>.popover-arrow::before{
  bottom:0;
  border-width:.5rem .5rem 0;
  border-top-color:rgba(255,255,255,.1)
}
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,.bs-popover-top>.popover-arrow::after{
  bottom:1px;
  border-width:.5rem .5rem 0;
  border-top-color:#343747
}
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,.bs-popover-end>.popover-arrow{
  left:calc(-.5rem - 1px);
  width:.5rem;
  height:1rem
}
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,.bs-popover-end>.popover-arrow::before{
  left:0;
  border-width:.5rem .5rem .5rem 0;
  border-right-color:rgba(255,255,255,.1)
}
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,.bs-popover-end>.popover-arrow::after{
  left:1px;
  border-width:.5rem .5rem .5rem 0;
  border-right-color:#343747
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,.bs-popover-bottom>.popover-arrow{
  top:calc(-.5rem - 1px)
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,.bs-popover-bottom>.popover-arrow::before{
  top:0;
  border-width:0 .5rem .5rem .5rem;
  border-bottom-color:rgba(255,255,255,.1)
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,.bs-popover-bottom>.popover-arrow::after{
  top:1px;
  border-width:0 .5rem .5rem .5rem;
  border-bottom-color:#343747
}
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,.bs-popover-bottom .popover-header::before{
  position:absolute;
  top:0;
  left:50%;
  display:block;
  width:1rem;
  margin-left:-.5rem;
  content:"";
  border-bottom:1px solid #313443
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,.bs-popover-start>.popover-arrow{
  right:calc(-.5rem - 1px);
  width:.5rem;
  height:1rem
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,.bs-popover-start>.popover-arrow::before{
  right:0;
  border-width:.5rem 0 .5rem .5rem;
  border-left-color:rgba(255,255,255,.1)
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,.bs-popover-start>.popover-arrow::after{
  right:1px;
  border-width:.5rem 0 .5rem .5rem;
  border-left-color:#343747
}
.popover-header{
  padding:.5rem .75rem;
  margin-bottom:0;
  font-size:.9rem;
  color:#9295a4;
  background-color:#313443;
  border-bottom:1px solid rgba(255,255,255,.05);
  border-top-left-radius:calc(.3rem - 1px);
  border-top-right-radius:calc(.3rem - 1px)
}
.popover-header:empty{
  display:none
}
.popover-body{
  padding:.5rem .75rem;
  color:#9295a4
}
.carousel{
  position:relative
}
.carousel.pointer-event{
  -ms-touch-action:pan-y;
  touch-action:pan-y
}
.carousel-inner{
  position:relative;
  width:100%;
  overflow:hidden
}
.carousel-inner::after{
  display:block;
  clear:both;
  content:""
}
.carousel-item{
  position:relative;
  display:none;
  float:left;
  width:100%;
  margin-right:-100%;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  -webkit-transition:-webkit-transform .6s ease-in-out;
  transition:-webkit-transform .6s ease-in-out;
  transition:transform .6s ease-in-out;
  transition:transform .6s ease-in-out,-webkit-transform .6s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .carousel-item{
      -webkit-transition:none;
      transition:none
  }
}
.carousel-item-next,.carousel-item-prev,.carousel-item.active{
  display:block
}
.active.carousel-item-end,.carousel-item-next:not(.carousel-item-start){
  -webkit-transform:translateX(100%);
  transform:translateX(100%)
}
.active.carousel-item-start,.carousel-item-prev:not(.carousel-item-end){
  -webkit-transform:translateX(-100%);
  transform:translateX(-100%)
}
.carousel-fade .carousel-item{
  opacity:0;
  -webkit-transition-property:opacity;
  transition-property:opacity;
  -webkit-transform:none;
  transform:none
}
.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end,.carousel-fade .carousel-item.active{
  z-index:1;
  opacity:1
}
.carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start{
  z-index:0;
  opacity:0;
  -webkit-transition:opacity 0s .6s;
  transition:opacity 0s .6s
}
@media (prefers-reduced-motion:reduce){
  .carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start{
      -webkit-transition:none;
      transition:none
  }
}
.carousel-control-next,.carousel-control-prev{
  position:absolute;
  top:0;
  bottom:0;
  z-index:1;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  width:15%;
  padding:0;
  color:#fff;
  text-align:center;
  background:0 0;
  border:0;
  opacity:.5;
  -webkit-transition:opacity .15s ease;
  transition:opacity .15s ease
}
@media (prefers-reduced-motion:reduce){
  .carousel-control-next,.carousel-control-prev{
      -webkit-transition:none;
      transition:none
  }
}
.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover{
  color:#fff;
  text-decoration:none;
  outline:0;
  opacity:.9
}
.carousel-control-prev{
  left:0
}
.carousel-control-next{
  right:0
}
.carousel-control-next-icon,.carousel-control-prev-icon{
  display:inline-block;
  width:2rem;
  height:2rem;
  background-repeat:no-repeat;
  background-position:50%;
  background-size:100% 100%
}
.carousel-control-prev-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}
.carousel-control-next-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
.carousel-indicators{
  position:absolute;
  right:0;
  bottom:0;
  left:0;
  z-index:2;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  padding:0;
  margin-right:15%;
  margin-bottom:1rem;
  margin-left:15%;
  list-style:none
}
.carousel-indicators [data-bs-target]{
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-flex:0;
  -ms-flex:0 1 auto;
  flex:0 1 auto;
  width:30px;
  height:3px;
  padding:0;
  margin-right:3px;
  margin-left:3px;
  text-indent:-999px;
  cursor:pointer;
  background-color:#fff;
  background-clip:padding-box;
  border:0;
  border-top:10px solid transparent;
  border-bottom:10px solid transparent;
  opacity:.5;
  -webkit-transition:opacity .6s ease;
  transition:opacity .6s ease
}
@media (prefers-reduced-motion:reduce){
  .carousel-indicators [data-bs-target]{
      -webkit-transition:none;
      transition:none
  }
}
.carousel-indicators .active{
  opacity:1
}
.carousel-caption{
  position:absolute;
  right:15%;
  bottom:1.25rem;
  left:15%;
  padding-top:1.25rem;
  padding-bottom:1.25rem;
  color:#fff;
  text-align:center
}
.carousel-dark .carousel-control-next-icon,.carousel-dark .carousel-control-prev-icon{
  -webkit-filter:invert(1) grayscale(100);
  filter:invert(1) grayscale(100)
}
.carousel-dark .carousel-indicators [data-bs-target]{
  background-color:#000
}
.carousel-dark .carousel-caption{
  color:#fff
}
@-webkit-keyframes spinner-border{
  to{
      -webkit-transform:rotate(360deg);
      transform:rotate(360deg)
  }
}
@keyframes spinner-border{
  to{
      -webkit-transform:rotate(360deg);
      transform:rotate(360deg)
  }
}
.spinner-border{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:-.125em;
  border:.25em solid currentColor;
  border-right-color:transparent;
  border-radius:50%;
  -webkit-animation:.75s linear infinite spinner-border;
  animation:.75s linear infinite spinner-border
}
.spinner-border-sm{
  width:1rem;
  height:1rem;
  border-width:.2em
}
@-webkit-keyframes spinner-grow{
  0%{
      -webkit-transform:scale(0);
      transform:scale(0)
  }
  50%{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes spinner-grow{
  0%{
      -webkit-transform:scale(0);
      transform:scale(0)
  }
  50%{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.spinner-grow{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:-.125em;
  background-color:currentColor;
  border-radius:50%;
  opacity:0;
  -webkit-animation:.75s linear infinite spinner-grow;
  animation:.75s linear infinite spinner-grow
}
.spinner-grow-sm{
  width:1rem;
  height:1rem
}
@media (prefers-reduced-motion:reduce){
  .spinner-border,.spinner-grow{
      -webkit-animation-duration:1.5s;
      animation-duration:1.5s
  }
}
.offcanvas{
  position:fixed;
  bottom:0;
  z-index:1045;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  max-width:100%;
  visibility:hidden;
  background-color:#2b2e3b;
  background-clip:padding-box;
  outline:0;
  -webkit-transition:-webkit-transform .3s ease-in-out;
  transition:-webkit-transform .3s ease-in-out;
  transition:transform .3s ease-in-out;
  transition:transform .3s ease-in-out,-webkit-transform .3s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .offcanvas{
      -webkit-transition:none;
      transition:none
  }
}
.offcanvas-backdrop{
  position:fixed;
  top:0;
  left:0;
  z-index:1040;
  width:100vw;
  height:100vh;
  background-color:#000
}
.offcanvas-backdrop.fade{
  opacity:0
}
.offcanvas-backdrop.show{
  opacity:.5
}
.offcanvas-header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  padding:1rem 1rem
}
.offcanvas-header .btn-close{
  padding:.5rem .5rem;
  margin-top:-.5rem;
  margin-right:-.5rem;
  margin-bottom:-.5rem
}
.offcanvas-title{
  margin-bottom:0;
  line-height:1.5
}
.offcanvas-body{
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  padding:1rem 1rem;
  overflow-y:auto
}
.offcanvas-start{
  top:0;
  left:0;
  width:400px;
  border-right:1px solid #343747;
  -webkit-transform:translateX(-100%);
  transform:translateX(-100%)
}
.offcanvas-end{
  top:0;
  right:0;
  width:400px;
  border-left:1px solid #343747;
  -webkit-transform:translateX(100%);
  transform:translateX(100%)
}
.offcanvas-top{
  top:0;
  right:0;
  left:0;
  height:30vh;
  max-height:100%;
  border-bottom:1px solid #343747;
  -webkit-transform:translateY(-100%);
  transform:translateY(-100%)
}
.offcanvas-bottom{
  right:0;
  left:0;
  height:30vh;
  max-height:100%;
  border-top:1px solid #343747;
  -webkit-transform:translateY(100%);
  transform:translateY(100%)
}
.offcanvas.show{
  -webkit-transform:none;
  transform:none
}
.placeholder{
  display:inline-block;
  min-height:1em;
  vertical-align:middle;
  cursor:wait;
  background-color:currentColor;
  opacity:.5
}
.placeholder.btn::before{
  display:inline-block;
  content:""
}
.placeholder-xs{
  min-height:.6em
}
.placeholder-sm{
  min-height:.8em
}
.placeholder-lg{
  min-height:1.2em
}
.placeholder-glow .placeholder{
  -webkit-animation:placeholder-glow 2s ease-in-out infinite;
  animation:placeholder-glow 2s ease-in-out infinite
}
@-webkit-keyframes placeholder-glow{
  50%{
      opacity:.2
  }
}
@keyframes placeholder-glow{
  50%{
      opacity:.2
  }
}
.placeholder-wave{
  -webkit-mask-image:linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
  mask-image:linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
  -webkit-mask-size:200% 100%;
  mask-size:200% 100%;
  -webkit-animation:placeholder-wave 2s linear infinite;
  animation:placeholder-wave 2s linear infinite
}
@-webkit-keyframes placeholder-wave{
  100%{
      -webkit-mask-position:-200% 0;
      mask-position:-200% 0
  }
}
@keyframes placeholder-wave{
  100%{
      -webkit-mask-position:-200% 0;
      mask-position:-200% 0
  }
}
.clearfix::after{
  display:block;
  clear:both;
  content:""
}
.link-primary{
  color:#5b73e8
}
.link-primary:focus,.link-primary:hover{
  color:#495cba
}
.link-secondary{
  color:#c3cbe4
}
.link-secondary:focus,.link-secondary:hover{
  color:#cfd5e9
}
.link-success{
  color:#34c38f
}
.link-success:focus,.link-success:hover{
  color:#2a9c72
}
.link-info{
  color:#50a5f1
}
.link-info:focus,.link-info:hover{
  color:#4084c1
}
.link-warning{
  color:#f1b44c
}
.link-warning:focus,.link-warning:hover{
  color:#c1903d
}
.link-danger{
  color:#f46a6a
}
.link-danger:focus,.link-danger:hover{
  color:#c35555
}
.link-pink{
  color:#e83e8c
}
.link-pink:focus,.link-pink:hover{
  color:#ba3270
}
.link-light{
  color:#343747
}
.link-light:focus,.link-light:hover{
  color:#2a2c39
}
.link-dark{
  color:#eff2f7
}
.link-dark:focus,.link-dark:hover{
  color:#f2f5f9
}
.link-purple{
  color:#6f42c1
}
.link-purple:focus,.link-purple:hover{
  color:#59359a
}
.ratio{
  position:relative;
  width:100%
}
.ratio::before{
  display:block;
  padding-top:var(--bs-aspect-ratio);
  content:""
}
.ratio>*{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.ratio-1x1{
  --bs-aspect-ratio:100%
}
.ratio-4x3{
  --bs-aspect-ratio:calc(3 / 4 * 100%)
}
.ratio-16x9{
  --bs-aspect-ratio:calc(9 / 16 * 100%)
}
.ratio-21x9{
  --bs-aspect-ratio:calc(9 / 21 * 100%)
}
.fixed-top{
  position:fixed;
  top:0;
  right:0;
  left:0;
  z-index:1030
}
.fixed-bottom{
  position:fixed;
  right:0;
  bottom:0;
  left:0;
  z-index:1030
}
.sticky-top{
  position:sticky;
  top:0;
  z-index:1020
}
@media (min-width:576px){
  .sticky-sm-top{
      position:sticky;
      top:0;
      z-index:1020
  }
}
@media (min-width:768px){
  .sticky-md-top{
      position:sticky;
      top:0;
      z-index:1020
  }
}
@media (min-width:992px){
  .sticky-lg-top{
      position:sticky;
      top:0;
      z-index:1020
  }
}
@media (min-width:1200px){
  .sticky-xl-top{
      position:sticky;
      top:0;
      z-index:1020
  }
}
@media (min-width:1400px){
  .sticky-xxl-top{
      position:sticky;
      top:0;
      z-index:1020
  }
}
.hstack{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-direction:row;
  flex-direction:row;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -ms-flex-item-align:stretch;
  align-self:stretch
}
.vstack{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -ms-flex-item-align:stretch;
  align-self:stretch
}
.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within){
  position:absolute!important;
  width:1px!important;
  height:1px!important;
  padding:0!important;
  margin:-1px!important;
  overflow:hidden!important;
  clip:rect(0,0,0,0)!important;
  white-space:nowrap!important;
  border:0!important
}
.stretched-link::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:1;
  content:""
}
.text-truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.vr{
  display:inline-block;
  -ms-flex-item-align:stretch;
  align-self:stretch;
  width:1px;
  min-height:1em;
  background-color:currentColor;
  opacity:.25
}
.align-baseline{
  vertical-align:baseline!important
}
.align-top{
  vertical-align:top!important
}
.align-middle{
  vertical-align:middle!important
}
.align-bottom{
  vertical-align:bottom!important
}
.align-text-bottom{
  vertical-align:text-bottom!important
}
.align-text-top{
  vertical-align:text-top!important
}
.float-start{
  float:left!important
}
.float-end{
  float:right!important
}
.float-none{
  float:none!important
}
.opacity-0{
  opacity:0!important
}
.opacity-25{
  opacity:.25!important
}
.opacity-50{
  opacity:.5!important
}
.opacity-75{
  opacity:.75!important
}
.opacity-100{
  opacity:1!important
}
.overflow-auto{
  overflow:auto!important
}
.overflow-hidden{
  overflow:hidden!important
}
.overflow-visible{
  overflow:visible!important
}
.overflow-scroll{
  overflow:scroll!important
}
.d-inline{
  display:inline!important
}
.d-inline-block{
  display:inline-block!important
}
.d-block{
  display:block!important
}
.d-grid{
  display:grid!important
}
.d-table{
  display:table!important
}
.d-table-row{
  display:table-row!important
}
.d-table-cell{
  display:table-cell!important
}
.d-flex{
  display:-webkit-box!important;
  display:-ms-flexbox!important;
  display:flex!important
}
.d-inline-flex{
  display:-webkit-inline-box!important;
  display:-ms-inline-flexbox!important;
  display:inline-flex!important
}
.d-none{
  display:none!important
}
.shadow{
  -webkit-box-shadow:0 2px 4px rgba(15,34,58,.12)!important;
  box-shadow:0 2px 4px rgba(15,34,58,.12)!important
}
.shadow-sm{
  -webkit-box-shadow:0 .125rem .25rem rgba(0,0,0,.075)!important;
  box-shadow:0 .125rem .25rem rgba(0,0,0,.075)!important
}
.shadow-lg{
  -webkit-box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important;
  box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important
}
.shadow-none{
  -webkit-box-shadow:none!important;
  box-shadow:none!important
}
.position-static{
  position:static!important
}
.position-relative{
  position:relative!important
}
.position-absolute{
  position:absolute!important
}
.position-fixed{
  position:fixed!important
}
.position-sticky{
  position:sticky!important
}
.top-0{
  top:0!important
}
.top-50{
  top:50%!important
}
.top-100{
  top:100%!important
}
.bottom-0{
  bottom:0!important
}
.bottom-50{
  bottom:50%!important
}
.bottom-100{
  bottom:100%!important
}
.start-0{
  left:0!important
}
.start-50{
  left:50%!important
}
.start-100{
  left:100%!important
}
.end-0{
  right:0!important
}
.end-50{
  right:50%!important
}
.end-100{
  right:100%!important
}
.translate-middle{
  -webkit-transform:translate(-50%,-50%)!important;
  transform:translate(-50%,-50%)!important
}
.translate-middle-x{
  -webkit-transform:translateX(-50%)!important;
  transform:translateX(-50%)!important
}
.translate-middle-y{
  -webkit-transform:translateY(-50%)!important;
  transform:translateY(-50%)!important
}
.border{
  border:1px solid #343747!important
}
.border-0{
  border:0!important
}
.border-top{
  border-top:1px solid #343747!important
}
.border-top-0{
  border-top:0!important
}
.border-end{
  border-right:1px solid #343747!important
}
.border-end-0{
  border-right:0!important
}
.border-bottom{
  border-bottom:1px solid #343747!important
}
.border-bottom-0{
  border-bottom:0!important
}
.border-start{
  border-left:1px solid #343747!important
}
.border-start-0{
  border-left:0!important
}
.border-primary{
  border-color:#5b73e8!important
}
.border-secondary{
  border-color:#c3cbe4!important
}
.border-success{
  border-color:#34c38f!important
}
.border-info{
  border-color:#50a5f1!important
}
.border-warning{
  border-color:#f1b44c!important
}
.border-danger{
  border-color:#f46a6a!important
}
.border-pink{
  border-color:#e83e8c!important
}
.border-light{
  border-color:#343747!important
}
.border-dark{
  border-color:#eff2f7!important
}
.border-purple{
  border-color:#6f42c1!important
}
.border-white{
  border-color:#fff!important
}
.border-0{
  border-width:0!important
}
.border-1{
  border-width:1px!important
}
.border-2{
  border-width:2px!important
}
.border-3{
  border-width:3px!important
}
.border-4{
  border-width:4px!important
}
.border-5{
  border-width:5px!important
}
.w-25{
  width:25%!important
}
.w-50{
  width:50%!important
}
.w-75{
  width:75%!important
}
.w-100{
  width:100%!important
}
.w-auto{
  width:auto!important
}
.mw-100{
  max-width:100%!important
}
.vw-100{
  width:100vw!important
}
.min-vw-100{
  min-width:100vw!important
}
.h-25{
  height:25%!important
}
.h-50{
  height:50%!important
}
.h-75{
  height:75%!important
}
.h-100{
  height:100%!important
}
.h-auto{
  height:auto!important
}
.mh-100{
  max-height:100%!important
}
.vh-100{
  height:100vh!important
}
.min-vh-100{
  min-height:100vh!important
}
.flex-fill{
  -webkit-box-flex:1!important;
  -ms-flex:1 1 auto!important;
  flex:1 1 auto!important
}
.flex-row{
  -webkit-box-orient:horizontal!important;
  -webkit-box-direction:normal!important;
  -ms-flex-direction:row!important;
  flex-direction:row!important
}
.flex-column{
  -webkit-box-orient:vertical!important;
  -webkit-box-direction:normal!important;
  -ms-flex-direction:column!important;
  flex-direction:column!important
}
.flex-row-reverse{
  -webkit-box-orient:horizontal!important;
  -webkit-box-direction:reverse!important;
  -ms-flex-direction:row-reverse!important;
  flex-direction:row-reverse!important
}
.flex-column-reverse{
  -webkit-box-orient:vertical!important;
  -webkit-box-direction:reverse!important;
  -ms-flex-direction:column-reverse!important;
  flex-direction:column-reverse!important
}
.flex-grow-0{
  -webkit-box-flex:0!important;
  -ms-flex-positive:0!important;
  flex-grow:0!important
}
.flex-grow-1{
  -webkit-box-flex:1!important;
  -ms-flex-positive:1!important;
  flex-grow:1!important
}
.flex-shrink-0{
  -ms-flex-negative:0!important;
  flex-shrink:0!important
}
.flex-shrink-1{
  -ms-flex-negative:1!important;
  flex-shrink:1!important
}
.flex-wrap{
  -ms-flex-wrap:wrap!important;
  flex-wrap:wrap!important
}
.flex-nowrap{
  -ms-flex-wrap:nowrap!important;
  flex-wrap:nowrap!important
}
.flex-wrap-reverse{
  -ms-flex-wrap:wrap-reverse!important;
  flex-wrap:wrap-reverse!important
}
.gap-0{
  gap:0!important
}
.gap-1{
  gap:.25rem!important
}
.gap-2{
  gap:.5rem!important
}
.gap-3{
  gap:1rem!important
}
.gap-4{
  gap:1.5rem!important
}
.gap-5{
  gap:3rem!important
}
.justify-content-start{
  -webkit-box-pack:start!important;
  -ms-flex-pack:start!important;
  justify-content:flex-start!important
}
.justify-content-end{
  -webkit-box-pack:end!important;
  -ms-flex-pack:end!important;
  justify-content:flex-end!important
}
.justify-content-center{
  -webkit-box-pack:center!important;
  -ms-flex-pack:center!important;
  justify-content:center!important
}
.justify-content-between{
  -webkit-box-pack:justify!important;
  -ms-flex-pack:justify!important;
  justify-content:space-between!important
}
.justify-content-around{
  -ms-flex-pack:distribute!important;
  justify-content:space-around!important
}
.justify-content-evenly{
  -webkit-box-pack:space-evenly!important;
  -ms-flex-pack:space-evenly!important;
  justify-content:space-evenly!important
}
.align-items-start{
  -webkit-box-align:start!important;
  -ms-flex-align:start!important;
  align-items:flex-start!important
}
.align-items-end{
  -webkit-box-align:end!important;
  -ms-flex-align:end!important;
  align-items:flex-end!important
}
.align-items-center{
  -webkit-box-align:center!important;
  -ms-flex-align:center!important;
  align-items:center!important
}
.align-items-baseline{
  -webkit-box-align:baseline!important;
  -ms-flex-align:baseline!important;
  align-items:baseline!important
}
.align-items-stretch{
  -webkit-box-align:stretch!important;
  -ms-flex-align:stretch!important;
  align-items:stretch!important
}
.align-content-start{
  -ms-flex-line-pack:start!important;
  align-content:flex-start!important
}
.align-content-end{
  -ms-flex-line-pack:end!important;
  align-content:flex-end!important
}
.align-content-center{
  -ms-flex-line-pack:center!important;
  align-content:center!important
}
.align-content-between{
  -ms-flex-line-pack:justify!important;
  align-content:space-between!important
}
.align-content-around{
  -ms-flex-line-pack:distribute!important;
  align-content:space-around!important
}
.align-content-stretch{
  -ms-flex-line-pack:stretch!important;
  align-content:stretch!important
}
.align-self-auto{
  -ms-flex-item-align:auto!important;
  align-self:auto!important
}
.align-self-start{
  -ms-flex-item-align:start!important;
  align-self:flex-start!important
}
.align-self-end{
  -ms-flex-item-align:end!important;
  align-self:flex-end!important
}
.align-self-center{
  -ms-flex-item-align:center!important;
  align-self:center!important
}
.align-self-baseline{
  -ms-flex-item-align:baseline!important;
  align-self:baseline!important
}
.align-self-stretch{
  -ms-flex-item-align:stretch!important;
  align-self:stretch!important
}
.order-first{
  -webkit-box-ordinal-group:0!important;
  -ms-flex-order:-1!important;
  order:-1!important
}
.order-0{
  -webkit-box-ordinal-group:1!important;
  -ms-flex-order:0!important;
  order:0!important
}
.order-1{
  -webkit-box-ordinal-group:2!important;
  -ms-flex-order:1!important;
  order:1!important
}
.order-2{
  -webkit-box-ordinal-group:3!important;
  -ms-flex-order:2!important;
  order:2!important
}
.order-3{
  -webkit-box-ordinal-group:4!important;
  -ms-flex-order:3!important;
  order:3!important
}
.order-4{
  -webkit-box-ordinal-group:5!important;
  -ms-flex-order:4!important;
  order:4!important
}
.order-5{
  -webkit-box-ordinal-group:6!important;
  -ms-flex-order:5!important;
  order:5!important
}
.order-last{
  -webkit-box-ordinal-group:7!important;
  -ms-flex-order:6!important;
  order:6!important
}
.m-0{
  margin:0!important
}
.m-1{
  margin:.25rem!important
}
.m-2{
  margin:.5rem!important
}
.m-3{
  margin:1rem!important
}
.m-4{
  margin:1.5rem!important
}
.m-5{
  margin:3rem!important
}
.m-auto{
  margin:auto!important
}
.mx-0{
  margin-right:0!important;
  margin-left:0!important
}
.mx-1{
  margin-right:.25rem!important;
  margin-left:.25rem!important
}
.mx-2{
  margin-right:.5rem!important;
  margin-left:.5rem!important
}
.mx-3{
  margin-right:1rem!important;
  margin-left:1rem!important
}
.mx-4{
  margin-right:1.5rem!important;
  margin-left:1.5rem!important
}
.mx-5{
  margin-right:3rem!important;
  margin-left:3rem!important
}
.mx-auto{
  margin-right:auto!important;
  margin-left:auto!important
}
.my-0{
  margin-top:0!important;
  margin-bottom:0!important
}
.my-1{
  margin-top:.25rem!important;
  margin-bottom:.25rem!important
}
.my-2{
  margin-top:.5rem!important;
  margin-bottom:.5rem!important
}
.my-3{
  margin-top:1rem!important;
  margin-bottom:1rem!important
}
.my-4{
  margin-top:1.5rem!important;
  margin-bottom:1.5rem!important
}
.my-5{
  margin-top:3rem!important;
  margin-bottom:3rem!important
}
.my-auto{
  margin-top:auto!important;
  margin-bottom:auto!important
}
.mt-0{
  margin-top:0!important
}
.mt-1{
  margin-top:.25rem!important
}
.mt-2{
  margin-top:.5rem!important
}
.mt-3{
  margin-top:1rem!important
}
.mt-4{
  margin-top:1.5rem!important
}
.mt-5{
  margin-top:3rem!important
}
.mt-auto{
  margin-top:auto!important
}
.me-0{
  margin-right:0!important
}
.me-1{
  margin-right:.25rem!important
}
.me-2{
  margin-right:.5rem!important
}
.me-3{
  margin-right:1rem!important
}
.me-4{
  margin-right:1.5rem!important
}
.me-5{
  margin-right:3rem!important
}
.me-auto{
  margin-right:auto!important
}
.mb-0{
  margin-bottom:0!important
}
.mb-1{
  margin-bottom:.25rem!important
}
.mb-2{
  margin-bottom:.5rem!important
}
.mb-3{
  margin-bottom:1rem!important
}
.mb-4{
  margin-bottom:1.5rem!important
}
.mb-5{
  margin-bottom:3rem!important
}
.mb-auto{
  margin-bottom:auto!important
}
.ms-0{
  margin-left:0!important
}
.ms-1{
  margin-left:.25rem!important
}
.ms-2{
  margin-left:.5rem!important
}
.ms-3{
  margin-left:1rem!important
}
.ms-4{
  margin-left:1.5rem!important
}
.ms-5{
  margin-left:3rem!important
}
.ms-auto{
  margin-left:auto!important
}
.p-0{
  padding:0!important
}
.p-1{
  padding:.25rem!important
}
.p-2{
  padding:.5rem!important
}
.p-3{
  padding:1rem!important
}
.p-4{
  padding:1.5rem!important
}
.p-5{
  padding:3rem!important
}
.px-0{
  padding-right:0!important;
  padding-left:0!important
}
.px-1{
  padding-right:.25rem!important;
  padding-left:.25rem!important
}
.px-2{
  padding-right:.5rem!important;
  padding-left:.5rem!important
}
.px-3{
  padding-right:1rem!important;
  padding-left:1rem!important
}
.px-4{
  padding-right:1.5rem!important;
  padding-left:1.5rem!important
}
.px-5{
  padding-right:3rem!important;
  padding-left:3rem!important
}
.py-0{
  padding-top:0!important;
  padding-bottom:0!important
}
.py-1{
  padding-top:.25rem!important;
  padding-bottom:.25rem!important
}
.py-2{
  padding-top:.5rem!important;
  padding-bottom:.5rem!important
}
.py-3{
  padding-top:1rem!important;
  padding-bottom:1rem!important
}
.py-4{
  padding-top:1.5rem!important;
  padding-bottom:1.5rem!important
}
.py-5{
  padding-top:3rem!important;
  padding-bottom:3rem!important
}
.pt-0{
  padding-top:0!important
}
.pt-1{
  padding-top:.25rem!important
}
.pt-2{
  padding-top:.5rem!important
}
.pt-3{
  padding-top:1rem!important
}
.pt-4{
  padding-top:1.5rem!important
}
.pt-5{
  padding-top:3rem!important
}
.pe-0{
  padding-right:0!important
}
.pe-1{
  padding-right:.25rem!important
}
.pe-2{
  padding-right:.5rem!important
}
.pe-3{
  padding-right:1rem!important
}
.pe-4{
  padding-right:1.5rem!important
}
.pe-5{
  padding-right:3rem!important
}
.pb-0{
  padding-bottom:0!important
}
.pb-1{
  padding-bottom:.25rem!important
}
.pb-2{
  padding-bottom:.5rem!important
}
.pb-3{
  padding-bottom:1rem!important
}
.pb-4{
  padding-bottom:1.5rem!important
}
.pb-5{
  padding-bottom:3rem!important
}
.ps-0{
  padding-left:0!important
}
.ps-1{
  padding-left:.25rem!important
}
.ps-2{
  padding-left:.5rem!important
}
.ps-3{
  padding-left:1rem!important
}
.ps-4{
  padding-left:1.5rem!important
}
.ps-5{
  padding-left:3rem!important
}
.font-monospace{
  font-family:var(--bs-font-monospace)!important
}
.fs-1{
  font-size:calc(1.35rem + 1.2vw)!important
}
.fs-2{
  font-size:calc(1.305rem + .66vw)!important
}
.fs-3{
  font-size:calc(1.2825rem + .39vw)!important
}
.fs-4{
  font-size:calc(1.26rem + .12vw)!important
}
.fs-5{
  font-size:1.125rem!important
}
.fs-6{
  font-size:.9rem!important
}
.fst-italic{
  font-style:italic!important
}
.fst-normal{
  font-style:normal!important
}
.fw-light{
  font-weight:300!important
}
.fw-lighter{
  font-weight:lighter!important
}
.fw-normal{
  font-weight:400!important
}
.fw-bold{
  font-weight:600!important
}
.fw-bolder{
  font-weight:bolder!important
}
.lh-1{
  line-height:1!important
}
.lh-sm{
  line-height:1.25!important
}
.lh-base{
  line-height:1.5!important
}
.lh-lg{
  line-height:2!important
}
.text-start{
  text-align:left!important
}
.text-end{
  text-align:right!important
}
.text-center{
  text-align:center!important
}
.text-decoration-none{
  text-decoration:none!important
}
.text-decoration-underline{
  text-decoration:underline!important
}
.text-decoration-line-through{
  text-decoration:line-through!important
}
.text-lowercase{
  text-transform:lowercase!important
}
.text-uppercase{
  text-transform:uppercase!important
}
.text-capitalize{
  text-transform:capitalize!important
}
.text-wrap{
  white-space:normal!important
}
.text-nowrap{
  white-space:nowrap!important
}
.text-break{
  word-wrap:break-word!important;
  word-break:break-word!important
}
.text-primary{
  --bs-text-opacity:1;
  color:rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important
}
.text-secondary{
  --bs-text-opacity:1;
  color:rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important
}
.text-success{
  --bs-text-opacity:1;
  color:rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important
}
.text-info{
  --bs-text-opacity:1;
  color:rgba(var(--bs-info-rgb),var(--bs-text-opacity))!important
}
.text-warning{
  --bs-text-opacity:1;
  color:rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important
}
.text-danger{
  --bs-text-opacity:1;
  color:rgba(var(--bs-danger-rgb),var(--bs-text-opacity))!important
}
.text-pink{
  --bs-text-opacity:1;
  color:rgba(var(--bs-pink-rgb),var(--bs-text-opacity))!important
}
.text-light{
  --bs-text-opacity:1;
  color:rgba(var(--bs-light-rgb),var(--bs-text-opacity))!important
}
.text-dark{
  --bs-text-opacity:1;
  color:rgba(var(--bs-dark-rgb),var(--bs-text-opacity))!important
}
.text-purple{
  --bs-text-opacity:1;
  color:rgba(var(--bs-purple-rgb),var(--bs-text-opacity))!important
}
.text-black{
  --bs-text-opacity:1;
  color:rgba(var(--bs-black-rgb),var(--bs-text-opacity))!important
}
.text-white{
  --bs-text-opacity:1;
  color:rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important
}
.text-body{
  --bs-text-opacity:1;
  color:rgba(var(--bs-body-color-rgb),var(--bs-text-opacity))!important
}
.text-muted{
  --bs-text-opacity:1;
  color:#9699a8!important
}
.text-black-50{
  --bs-text-opacity:1;
  color:rgba(0,0,0,.5)!important
}
.text-white-50{
  --bs-text-opacity:1;
  color:rgba(255,255,255,.5)!important
}
.text-reset{
  --bs-text-opacity:1;
  color:inherit!important
}
.text-opacity-25{
  --bs-text-opacity:0.25
}
.text-opacity-50{
  --bs-text-opacity:0.5
}
.text-opacity-75{
  --bs-text-opacity:0.75
}
.text-opacity-100{
  --bs-text-opacity:1
}
.bg-primary{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-primary-rgb),var(--bs-bg-opacity))!important
}
.bg-secondary{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity))!important
}
.bg-success{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-success-rgb),var(--bs-bg-opacity))!important
}
.bg-info{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-info-rgb),var(--bs-bg-opacity))!important
}
.bg-warning{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-warning-rgb),var(--bs-bg-opacity))!important
}
.bg-danger{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-danger-rgb),var(--bs-bg-opacity))!important
}
.bg-pink{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-pink-rgb),var(--bs-bg-opacity))!important
}
.bg-light{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important
}
.bg-dark{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important
}
.bg-purple{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-purple-rgb),var(--bs-bg-opacity))!important
}
.bg-black{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-black-rgb),var(--bs-bg-opacity))!important
}
.bg-white{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important
}
.bg-body{
  --bs-bg-opacity:1;
  background-color:rgba(var(--bs-body-bg-rgb),var(--bs-bg-opacity))!important
}
.bg-transparent{
  --bs-bg-opacity:1;
  background-color:transparent!important
}
.bg-opacity-10{
  --bs-bg-opacity:0.1
}
.bg-opacity-25{
  --bs-bg-opacity:0.25
}
.bg-opacity-50{
  --bs-bg-opacity:0.5
}
.bg-opacity-75{
  --bs-bg-opacity:0.75
}
.bg-opacity-100{
  --bs-bg-opacity:1
}
.bg-gradient{
  background-image:var(--bs-gradient)!important
}
.user-select-all{
  -webkit-user-select:all!important;
  -moz-user-select:all!important;
  -ms-user-select:all!important;
  user-select:all!important
}
.user-select-auto{
  -webkit-user-select:auto!important;
  -moz-user-select:auto!important;
  -ms-user-select:auto!important;
  user-select:auto!important
}
.user-select-none{
  -webkit-user-select:none!important;
  -moz-user-select:none!important;
  -ms-user-select:none!important;
  user-select:none!important
}
.pe-none{
  pointer-events:none!important
}
.pe-auto{
  pointer-events:auto!important
}
.rounded{
  border-radius:.25rem!important
}
.rounded-0{
  border-radius:0!important
}
.rounded-1{
  border-radius:.2rem!important
}
.rounded-2{
  border-radius:.25rem!important
}
.rounded-3{
  border-radius:.3rem!important
}
.rounded-circle{
  border-radius:50%!important
}
.rounded-pill{
  border-radius:50rem!important
}
.rounded-top{
  border-top-left-radius:.25rem!important;
  border-top-right-radius:.25rem!important
}
.rounded-end{
  border-top-right-radius:.25rem!important;
  border-bottom-right-radius:.25rem!important
}
.rounded-bottom{
  border-bottom-right-radius:.25rem!important;
  border-bottom-left-radius:.25rem!important
}
.rounded-start{
  border-bottom-left-radius:.25rem!important;
  border-top-left-radius:.25rem!important
}
.visible{
  visibility:visible!important
}
.invisible{
  visibility:hidden!important
}
@media (min-width:576px){
  .float-sm-start{
      float:left!important
  }
  .float-sm-end{
      float:right!important
  }
  .float-sm-none{
      float:none!important
  }
  .d-sm-inline{
      display:inline!important
  }
  .d-sm-inline-block{
      display:inline-block!important
  }
  .d-sm-block{
      display:block!important
  }
  .d-sm-grid{
      display:grid!important
  }
  .d-sm-table{
      display:table!important
  }
  .d-sm-table-row{
      display:table-row!important
  }
  .d-sm-table-cell{
      display:table-cell!important
  }
  .d-sm-flex{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important
  }
  .d-sm-inline-flex{
      display:-webkit-inline-box!important;
      display:-ms-inline-flexbox!important;
      display:inline-flex!important
  }
  .d-sm-none{
      display:none!important
  }
  .flex-sm-fill{
      -webkit-box-flex:1!important;
      -ms-flex:1 1 auto!important;
      flex:1 1 auto!important
  }
  .flex-sm-row{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:row!important;
      flex-direction:row!important
  }
  .flex-sm-column{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:column!important;
      flex-direction:column!important
  }
  .flex-sm-row-reverse{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:row-reverse!important;
      flex-direction:row-reverse!important
  }
  .flex-sm-column-reverse{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:column-reverse!important;
      flex-direction:column-reverse!important
  }
  .flex-sm-grow-0{
      -webkit-box-flex:0!important;
      -ms-flex-positive:0!important;
      flex-grow:0!important
  }
  .flex-sm-grow-1{
      -webkit-box-flex:1!important;
      -ms-flex-positive:1!important;
      flex-grow:1!important
  }
  .flex-sm-shrink-0{
      -ms-flex-negative:0!important;
      flex-shrink:0!important
  }
  .flex-sm-shrink-1{
      -ms-flex-negative:1!important;
      flex-shrink:1!important
  }
  .flex-sm-wrap{
      -ms-flex-wrap:wrap!important;
      flex-wrap:wrap!important
  }
  .flex-sm-nowrap{
      -ms-flex-wrap:nowrap!important;
      flex-wrap:nowrap!important
  }
  .flex-sm-wrap-reverse{
      -ms-flex-wrap:wrap-reverse!important;
      flex-wrap:wrap-reverse!important
  }
  .gap-sm-0{
      gap:0!important
  }
  .gap-sm-1{
      gap:.25rem!important
  }
  .gap-sm-2{
      gap:.5rem!important
  }
  .gap-sm-3{
      gap:1rem!important
  }
  .gap-sm-4{
      gap:1.5rem!important
  }
  .gap-sm-5{
      gap:3rem!important
  }
  .justify-content-sm-start{
      -webkit-box-pack:start!important;
      -ms-flex-pack:start!important;
      justify-content:flex-start!important
  }
  .justify-content-sm-end{
      -webkit-box-pack:end!important;
      -ms-flex-pack:end!important;
      justify-content:flex-end!important
  }
  .justify-content-sm-center{
      -webkit-box-pack:center!important;
      -ms-flex-pack:center!important;
      justify-content:center!important
  }
  .justify-content-sm-between{
      -webkit-box-pack:justify!important;
      -ms-flex-pack:justify!important;
      justify-content:space-between!important
  }
  .justify-content-sm-around{
      -ms-flex-pack:distribute!important;
      justify-content:space-around!important
  }
  .justify-content-sm-evenly{
      -webkit-box-pack:space-evenly!important;
      -ms-flex-pack:space-evenly!important;
      justify-content:space-evenly!important
  }
  .align-items-sm-start{
      -webkit-box-align:start!important;
      -ms-flex-align:start!important;
      align-items:flex-start!important
  }
  .align-items-sm-end{
      -webkit-box-align:end!important;
      -ms-flex-align:end!important;
      align-items:flex-end!important
  }
  .align-items-sm-center{
      -webkit-box-align:center!important;
      -ms-flex-align:center!important;
      align-items:center!important
  }
  .align-items-sm-baseline{
      -webkit-box-align:baseline!important;
      -ms-flex-align:baseline!important;
      align-items:baseline!important
  }
  .align-items-sm-stretch{
      -webkit-box-align:stretch!important;
      -ms-flex-align:stretch!important;
      align-items:stretch!important
  }
  .align-content-sm-start{
      -ms-flex-line-pack:start!important;
      align-content:flex-start!important
  }
  .align-content-sm-end{
      -ms-flex-line-pack:end!important;
      align-content:flex-end!important
  }
  .align-content-sm-center{
      -ms-flex-line-pack:center!important;
      align-content:center!important
  }
  .align-content-sm-between{
      -ms-flex-line-pack:justify!important;
      align-content:space-between!important
  }
  .align-content-sm-around{
      -ms-flex-line-pack:distribute!important;
      align-content:space-around!important
  }
  .align-content-sm-stretch{
      -ms-flex-line-pack:stretch!important;
      align-content:stretch!important
  }
  .align-self-sm-auto{
      -ms-flex-item-align:auto!important;
      align-self:auto!important
  }
  .align-self-sm-start{
      -ms-flex-item-align:start!important;
      align-self:flex-start!important
  }
  .align-self-sm-end{
      -ms-flex-item-align:end!important;
      align-self:flex-end!important
  }
  .align-self-sm-center{
      -ms-flex-item-align:center!important;
      align-self:center!important
  }
  .align-self-sm-baseline{
      -ms-flex-item-align:baseline!important;
      align-self:baseline!important
  }
  .align-self-sm-stretch{
      -ms-flex-item-align:stretch!important;
      align-self:stretch!important
  }
  .order-sm-first{
      -webkit-box-ordinal-group:0!important;
      -ms-flex-order:-1!important;
      order:-1!important
  }
  .order-sm-0{
      -webkit-box-ordinal-group:1!important;
      -ms-flex-order:0!important;
      order:0!important
  }
  .order-sm-1{
      -webkit-box-ordinal-group:2!important;
      -ms-flex-order:1!important;
      order:1!important
  }
  .order-sm-2{
      -webkit-box-ordinal-group:3!important;
      -ms-flex-order:2!important;
      order:2!important
  }
  .order-sm-3{
      -webkit-box-ordinal-group:4!important;
      -ms-flex-order:3!important;
      order:3!important
  }
  .order-sm-4{
      -webkit-box-ordinal-group:5!important;
      -ms-flex-order:4!important;
      order:4!important
  }
  .order-sm-5{
      -webkit-box-ordinal-group:6!important;
      -ms-flex-order:5!important;
      order:5!important
  }
  .order-sm-last{
      -webkit-box-ordinal-group:7!important;
      -ms-flex-order:6!important;
      order:6!important
  }
  .m-sm-0{
      margin:0!important
  }
  .m-sm-1{
      margin:.25rem!important
  }
  .m-sm-2{
      margin:.5rem!important
  }
  .m-sm-3{
      margin:1rem!important
  }
  .m-sm-4{
      margin:1.5rem!important
  }
  .m-sm-5{
      margin:3rem!important
  }
  .m-sm-auto{
      margin:auto!important
  }
  .mx-sm-0{
      margin-right:0!important;
      margin-left:0!important
  }
  .mx-sm-1{
      margin-right:.25rem!important;
      margin-left:.25rem!important
  }
  .mx-sm-2{
      margin-right:.5rem!important;
      margin-left:.5rem!important
  }
  .mx-sm-3{
      margin-right:1rem!important;
      margin-left:1rem!important
  }
  .mx-sm-4{
      margin-right:1.5rem!important;
      margin-left:1.5rem!important
  }
  .mx-sm-5{
      margin-right:3rem!important;
      margin-left:3rem!important
  }
  .mx-sm-auto{
      margin-right:auto!important;
      margin-left:auto!important
  }
  .my-sm-0{
      margin-top:0!important;
      margin-bottom:0!important
  }
  .my-sm-1{
      margin-top:.25rem!important;
      margin-bottom:.25rem!important
  }
  .my-sm-2{
      margin-top:.5rem!important;
      margin-bottom:.5rem!important
  }
  .my-sm-3{
      margin-top:1rem!important;
      margin-bottom:1rem!important
  }
  .my-sm-4{
      margin-top:1.5rem!important;
      margin-bottom:1.5rem!important
  }
  .my-sm-5{
      margin-top:3rem!important;
      margin-bottom:3rem!important
  }
  .my-sm-auto{
      margin-top:auto!important;
      margin-bottom:auto!important
  }
  .mt-sm-0{
      margin-top:0!important
  }
  .mt-sm-1{
      margin-top:.25rem!important
  }
  .mt-sm-2{
      margin-top:.5rem!important
  }
  .mt-sm-3{
      margin-top:1rem!important
  }
  .mt-sm-4{
      margin-top:1.5rem!important
  }
  .mt-sm-5{
      margin-top:3rem!important
  }
  .mt-sm-auto{
      margin-top:auto!important
  }
  .me-sm-0{
      margin-right:0!important
  }
  .me-sm-1{
      margin-right:.25rem!important
  }
  .me-sm-2{
      margin-right:.5rem!important
  }
  .me-sm-3{
      margin-right:1rem!important
  }
  .me-sm-4{
      margin-right:1.5rem!important
  }
  .me-sm-5{
      margin-right:3rem!important
  }
  .me-sm-auto{
      margin-right:auto!important
  }
  .mb-sm-0{
      margin-bottom:0!important
  }
  .mb-sm-1{
      margin-bottom:.25rem!important
  }
  .mb-sm-2{
      margin-bottom:.5rem!important
  }
  .mb-sm-3{
      margin-bottom:1rem!important
  }
  .mb-sm-4{
      margin-bottom:1.5rem!important
  }
  .mb-sm-5{
      margin-bottom:3rem!important
  }
  .mb-sm-auto{
      margin-bottom:auto!important
  }
  .ms-sm-0{
      margin-left:0!important
  }
  .ms-sm-1{
      margin-left:.25rem!important
  }
  .ms-sm-2{
      margin-left:.5rem!important
  }
  .ms-sm-3{
      margin-left:1rem!important
  }
  .ms-sm-4{
      margin-left:1.5rem!important
  }
  .ms-sm-5{
      margin-left:3rem!important
  }
  .ms-sm-auto{
      margin-left:auto!important
  }
  .p-sm-0{
      padding:0!important
  }
  .p-sm-1{
      padding:.25rem!important
  }
  .p-sm-2{
      padding:.5rem!important
  }
  .p-sm-3{
      padding:1rem!important
  }
  .p-sm-4{
      padding:1.5rem!important
  }
  .p-sm-5{
      padding:3rem!important
  }
  .px-sm-0{
      padding-right:0!important;
      padding-left:0!important
  }
  .px-sm-1{
      padding-right:.25rem!important;
      padding-left:.25rem!important
  }
  .px-sm-2{
      padding-right:.5rem!important;
      padding-left:.5rem!important
  }
  .px-sm-3{
      padding-right:1rem!important;
      padding-left:1rem!important
  }
  .px-sm-4{
      padding-right:1.5rem!important;
      padding-left:1.5rem!important
  }
  .px-sm-5{
      padding-right:3rem!important;
      padding-left:3rem!important
  }
  .py-sm-0{
      padding-top:0!important;
      padding-bottom:0!important
  }
  .py-sm-1{
      padding-top:.25rem!important;
      padding-bottom:.25rem!important
  }
  .py-sm-2{
      padding-top:.5rem!important;
      padding-bottom:.5rem!important
  }
  .py-sm-3{
      padding-top:1rem!important;
      padding-bottom:1rem!important
  }
  .py-sm-4{
      padding-top:1.5rem!important;
      padding-bottom:1.5rem!important
  }
  .py-sm-5{
      padding-top:3rem!important;
      padding-bottom:3rem!important
  }
  .pt-sm-0{
      padding-top:0!important
  }
  .pt-sm-1{
      padding-top:.25rem!important
  }
  .pt-sm-2{
      padding-top:.5rem!important
  }
  .pt-sm-3{
      padding-top:1rem!important
  }
  .pt-sm-4{
      padding-top:1.5rem!important
  }
  .pt-sm-5{
      padding-top:3rem!important
  }
  .pe-sm-0{
      padding-right:0!important
  }
  .pe-sm-1{
      padding-right:.25rem!important
  }
  .pe-sm-2{
      padding-right:.5rem!important
  }
  .pe-sm-3{
      padding-right:1rem!important
  }
  .pe-sm-4{
      padding-right:1.5rem!important
  }
  .pe-sm-5{
      padding-right:3rem!important
  }
  .pb-sm-0{
      padding-bottom:0!important
  }
  .pb-sm-1{
      padding-bottom:.25rem!important
  }
  .pb-sm-2{
      padding-bottom:.5rem!important
  }
  .pb-sm-3{
      padding-bottom:1rem!important
  }
  .pb-sm-4{
      padding-bottom:1.5rem!important
  }
  .pb-sm-5{
      padding-bottom:3rem!important
  }
  .ps-sm-0{
      padding-left:0!important
  }
  .ps-sm-1{
      padding-left:.25rem!important
  }
  .ps-sm-2{
      padding-left:.5rem!important
  }
  .ps-sm-3{
      padding-left:1rem!important
  }
  .ps-sm-4{
      padding-left:1.5rem!important
  }
  .ps-sm-5{
      padding-left:3rem!important
  }
  .text-sm-start{
      text-align:left!important
  }
  .text-sm-end{
      text-align:right!important
  }
  .text-sm-center{
      text-align:center!important
  }
}
@media (min-width:768px){
  .float-md-start{
      float:left!important
  }
  .float-md-end{
      float:right!important
  }
  .float-md-none{
      float:none!important
  }
  .d-md-inline{
      display:inline!important
  }
  .d-md-inline-block{
      display:inline-block!important
  }
  .d-md-block{
      display:block!important
  }
  .d-md-grid{
      display:grid!important
  }
  .d-md-table{
      display:table!important
  }
  .d-md-table-row{
      display:table-row!important
  }
  .d-md-table-cell{
      display:table-cell!important
  }
  .d-md-flex{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important
  }
  .d-md-inline-flex{
      display:-webkit-inline-box!important;
      display:-ms-inline-flexbox!important;
      display:inline-flex!important
  }
  .d-md-none{
      display:none!important
  }
  .flex-md-fill{
      -webkit-box-flex:1!important;
      -ms-flex:1 1 auto!important;
      flex:1 1 auto!important
  }
  .flex-md-row{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:row!important;
      flex-direction:row!important
  }
  .flex-md-column{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:column!important;
      flex-direction:column!important
  }
  .flex-md-row-reverse{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:row-reverse!important;
      flex-direction:row-reverse!important
  }
  .flex-md-column-reverse{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:column-reverse!important;
      flex-direction:column-reverse!important
  }
  .flex-md-grow-0{
      -webkit-box-flex:0!important;
      -ms-flex-positive:0!important;
      flex-grow:0!important
  }
  .flex-md-grow-1{
      -webkit-box-flex:1!important;
      -ms-flex-positive:1!important;
      flex-grow:1!important
  }
  .flex-md-shrink-0{
      -ms-flex-negative:0!important;
      flex-shrink:0!important
  }
  .flex-md-shrink-1{
      -ms-flex-negative:1!important;
      flex-shrink:1!important
  }
  .flex-md-wrap{
      -ms-flex-wrap:wrap!important;
      flex-wrap:wrap!important
  }
  .flex-md-nowrap{
      -ms-flex-wrap:nowrap!important;
      flex-wrap:nowrap!important
  }
  .flex-md-wrap-reverse{
      -ms-flex-wrap:wrap-reverse!important;
      flex-wrap:wrap-reverse!important
  }
  .gap-md-0{
      gap:0!important
  }
  .gap-md-1{
      gap:.25rem!important
  }
  .gap-md-2{
      gap:.5rem!important
  }
  .gap-md-3{
      gap:1rem!important
  }
  .gap-md-4{
      gap:1.5rem!important
  }
  .gap-md-5{
      gap:3rem!important
  }
  .justify-content-md-start{
      -webkit-box-pack:start!important;
      -ms-flex-pack:start!important;
      justify-content:flex-start!important
  }
  .justify-content-md-end{
      -webkit-box-pack:end!important;
      -ms-flex-pack:end!important;
      justify-content:flex-end!important
  }
  .justify-content-md-center{
      -webkit-box-pack:center!important;
      -ms-flex-pack:center!important;
      justify-content:center!important
  }
  .justify-content-md-between{
      -webkit-box-pack:justify!important;
      -ms-flex-pack:justify!important;
      justify-content:space-between!important
  }
  .justify-content-md-around{
      -ms-flex-pack:distribute!important;
      justify-content:space-around!important
  }
  .justify-content-md-evenly{
      -webkit-box-pack:space-evenly!important;
      -ms-flex-pack:space-evenly!important;
      justify-content:space-evenly!important
  }
  .align-items-md-start{
      -webkit-box-align:start!important;
      -ms-flex-align:start!important;
      align-items:flex-start!important
  }
  .align-items-md-end{
      -webkit-box-align:end!important;
      -ms-flex-align:end!important;
      align-items:flex-end!important
  }
  .align-items-md-center{
      -webkit-box-align:center!important;
      -ms-flex-align:center!important;
      align-items:center!important
  }
  .align-items-md-baseline{
      -webkit-box-align:baseline!important;
      -ms-flex-align:baseline!important;
      align-items:baseline!important
  }
  .align-items-md-stretch{
      -webkit-box-align:stretch!important;
      -ms-flex-align:stretch!important;
      align-items:stretch!important
  }
  .align-content-md-start{
      -ms-flex-line-pack:start!important;
      align-content:flex-start!important
  }
  .align-content-md-end{
      -ms-flex-line-pack:end!important;
      align-content:flex-end!important
  }
  .align-content-md-center{
      -ms-flex-line-pack:center!important;
      align-content:center!important
  }
  .align-content-md-between{
      -ms-flex-line-pack:justify!important;
      align-content:space-between!important
  }
  .align-content-md-around{
      -ms-flex-line-pack:distribute!important;
      align-content:space-around!important
  }
  .align-content-md-stretch{
      -ms-flex-line-pack:stretch!important;
      align-content:stretch!important
  }
  .align-self-md-auto{
      -ms-flex-item-align:auto!important;
      align-self:auto!important
  }
  .align-self-md-start{
      -ms-flex-item-align:start!important;
      align-self:flex-start!important
  }
  .align-self-md-end{
      -ms-flex-item-align:end!important;
      align-self:flex-end!important
  }
  .align-self-md-center{
      -ms-flex-item-align:center!important;
      align-self:center!important
  }
  .align-self-md-baseline{
      -ms-flex-item-align:baseline!important;
      align-self:baseline!important
  }
  .align-self-md-stretch{
      -ms-flex-item-align:stretch!important;
      align-self:stretch!important
  }
  .order-md-first{
      -webkit-box-ordinal-group:0!important;
      -ms-flex-order:-1!important;
      order:-1!important
  }
  .order-md-0{
      -webkit-box-ordinal-group:1!important;
      -ms-flex-order:0!important;
      order:0!important
  }
  .order-md-1{
      -webkit-box-ordinal-group:2!important;
      -ms-flex-order:1!important;
      order:1!important
  }
  .order-md-2{
      -webkit-box-ordinal-group:3!important;
      -ms-flex-order:2!important;
      order:2!important
  }
  .order-md-3{
      -webkit-box-ordinal-group:4!important;
      -ms-flex-order:3!important;
      order:3!important
  }
  .order-md-4{
      -webkit-box-ordinal-group:5!important;
      -ms-flex-order:4!important;
      order:4!important
  }
  .order-md-5{
      -webkit-box-ordinal-group:6!important;
      -ms-flex-order:5!important;
      order:5!important
  }
  .order-md-last{
      -webkit-box-ordinal-group:7!important;
      -ms-flex-order:6!important;
      order:6!important
  }
  .m-md-0{
      margin:0!important
  }
  .m-md-1{
      margin:.25rem!important
  }
  .m-md-2{
      margin:.5rem!important
  }
  .m-md-3{
      margin:1rem!important
  }
  .m-md-4{
      margin:1.5rem!important
  }
  .m-md-5{
      margin:3rem!important
  }
  .m-md-auto{
      margin:auto!important
  }
  .mx-md-0{
      margin-right:0!important;
      margin-left:0!important
  }
  .mx-md-1{
      margin-right:.25rem!important;
      margin-left:.25rem!important
  }
  .mx-md-2{
      margin-right:.5rem!important;
      margin-left:.5rem!important
  }
  .mx-md-3{
      margin-right:1rem!important;
      margin-left:1rem!important
  }
  .mx-md-4{
      margin-right:1.5rem!important;
      margin-left:1.5rem!important
  }
  .mx-md-5{
      margin-right:3rem!important;
      margin-left:3rem!important
  }
  .mx-md-auto{
      margin-right:auto!important;
      margin-left:auto!important
  }
  .my-md-0{
      margin-top:0!important;
      margin-bottom:0!important
  }
  .my-md-1{
      margin-top:.25rem!important;
      margin-bottom:.25rem!important
  }
  .my-md-2{
      margin-top:.5rem!important;
      margin-bottom:.5rem!important
  }
  .my-md-3{
      margin-top:1rem!important;
      margin-bottom:1rem!important
  }
  .my-md-4{
      margin-top:1.5rem!important;
      margin-bottom:1.5rem!important
  }
  .my-md-5{
      margin-top:3rem!important;
      margin-bottom:3rem!important
  }
  .my-md-auto{
      margin-top:auto!important;
      margin-bottom:auto!important
  }
  .mt-md-0{
      margin-top:0!important
  }
  .mt-md-1{
      margin-top:.25rem!important
  }
  .mt-md-2{
      margin-top:.5rem!important
  }
  .mt-md-3{
      margin-top:1rem!important
  }
  .mt-md-4{
      margin-top:1.5rem!important
  }
  .mt-md-5{
      margin-top:3rem!important
  }
  .mt-md-auto{
      margin-top:auto!important
  }
  .me-md-0{
      margin-right:0!important
  }
  .me-md-1{
      margin-right:.25rem!important
  }
  .me-md-2{
      margin-right:.5rem!important
  }
  .me-md-3{
      margin-right:1rem!important
  }
  .me-md-4{
      margin-right:1.5rem!important
  }
  .me-md-5{
      margin-right:3rem!important
  }
  .me-md-auto{
      margin-right:auto!important
  }
  .mb-md-0{
      margin-bottom:0!important
  }
  .mb-md-1{
      margin-bottom:.25rem!important
  }
  .mb-md-2{
      margin-bottom:.5rem!important
  }
  .mb-md-3{
      margin-bottom:1rem!important
  }
  .mb-md-4{
      margin-bottom:1.5rem!important
  }
  .mb-md-5{
      margin-bottom:3rem!important
  }
  .mb-md-auto{
      margin-bottom:auto!important
  }
  .ms-md-0{
      margin-left:0!important
  }
  .ms-md-1{
      margin-left:.25rem!important
  }
  .ms-md-2{
      margin-left:.5rem!important
  }
  .ms-md-3{
      margin-left:1rem!important
  }
  .ms-md-4{
      margin-left:1.5rem!important
  }
  .ms-md-5{
      margin-left:3rem!important
  }
  .ms-md-auto{
      margin-left:auto!important
  }
  .p-md-0{
      padding:0!important
  }
  .p-md-1{
      padding:.25rem!important
  }
  .p-md-2{
      padding:.5rem!important
  }
  .p-md-3{
      padding:1rem!important
  }
  .p-md-4{
      padding:1.5rem!important
  }
  .p-md-5{
      padding:3rem!important
  }
  .px-md-0{
      padding-right:0!important;
      padding-left:0!important
  }
  .px-md-1{
      padding-right:.25rem!important;
      padding-left:.25rem!important
  }
  .px-md-2{
      padding-right:.5rem!important;
      padding-left:.5rem!important
  }
  .px-md-3{
      padding-right:1rem!important;
      padding-left:1rem!important
  }
  .px-md-4{
      padding-right:1.5rem!important;
      padding-left:1.5rem!important
  }
  .px-md-5{
      padding-right:3rem!important;
      padding-left:3rem!important
  }
  .py-md-0{
      padding-top:0!important;
      padding-bottom:0!important
  }
  .py-md-1{
      padding-top:.25rem!important;
      padding-bottom:.25rem!important
  }
  .py-md-2{
      padding-top:.5rem!important;
      padding-bottom:.5rem!important
  }
  .py-md-3{
      padding-top:1rem!important;
      padding-bottom:1rem!important
  }
  .py-md-4{
      padding-top:1.5rem!important;
      padding-bottom:1.5rem!important
  }
  .py-md-5{
      padding-top:3rem!important;
      padding-bottom:3rem!important
  }
  .pt-md-0{
      padding-top:0!important
  }
  .pt-md-1{
      padding-top:.25rem!important
  }
  .pt-md-2{
      padding-top:.5rem!important
  }
  .pt-md-3{
      padding-top:1rem!important
  }
  .pt-md-4{
      padding-top:1.5rem!important
  }
  .pt-md-5{
      padding-top:3rem!important
  }
  .pe-md-0{
      padding-right:0!important
  }
  .pe-md-1{
      padding-right:.25rem!important
  }
  .pe-md-2{
      padding-right:.5rem!important
  }
  .pe-md-3{
      padding-right:1rem!important
  }
  .pe-md-4{
      padding-right:1.5rem!important
  }
  .pe-md-5{
      padding-right:3rem!important
  }
  .pb-md-0{
      padding-bottom:0!important
  }
  .pb-md-1{
      padding-bottom:.25rem!important
  }
  .pb-md-2{
      padding-bottom:.5rem!important
  }
  .pb-md-3{
      padding-bottom:1rem!important
  }
  .pb-md-4{
      padding-bottom:1.5rem!important
  }
  .pb-md-5{
      padding-bottom:3rem!important
  }
  .ps-md-0{
      padding-left:0!important
  }
  .ps-md-1{
      padding-left:.25rem!important
  }
  .ps-md-2{
      padding-left:.5rem!important
  }
  .ps-md-3{
      padding-left:1rem!important
  }
  .ps-md-4{
      padding-left:1.5rem!important
  }
  .ps-md-5{
      padding-left:3rem!important
  }
  .text-md-start{
      text-align:left!important
  }
  .text-md-end{
      text-align:right!important
  }
  .text-md-center{
      text-align:center!important
  }
}
@media (min-width:992px){
  .float-lg-start{
      float:left!important
  }
  .float-lg-end{
      float:right!important
  }
  .float-lg-none{
      float:none!important
  }
  .d-lg-inline{
      display:inline!important
  }
  .d-lg-inline-block{
      display:inline-block!important
  }
  .d-lg-block{
      display:block!important
  }
  .d-lg-grid{
      display:grid!important
  }
  .d-lg-table{
      display:table!important
  }
  .d-lg-table-row{
      display:table-row!important
  }
  .d-lg-table-cell{
      display:table-cell!important
  }
  .d-lg-flex{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important
  }
  .d-lg-inline-flex{
      display:-webkit-inline-box!important;
      display:-ms-inline-flexbox!important;
      display:inline-flex!important
  }
  .d-lg-none{
      display:none!important
  }
  .flex-lg-fill{
      -webkit-box-flex:1!important;
      -ms-flex:1 1 auto!important;
      flex:1 1 auto!important
  }
  .flex-lg-row{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:row!important;
      flex-direction:row!important
  }
  .flex-lg-column{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:column!important;
      flex-direction:column!important
  }
  .flex-lg-row-reverse{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:row-reverse!important;
      flex-direction:row-reverse!important
  }
  .flex-lg-column-reverse{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:column-reverse!important;
      flex-direction:column-reverse!important
  }
  .flex-lg-grow-0{
      -webkit-box-flex:0!important;
      -ms-flex-positive:0!important;
      flex-grow:0!important
  }
  .flex-lg-grow-1{
      -webkit-box-flex:1!important;
      -ms-flex-positive:1!important;
      flex-grow:1!important
  }
  .flex-lg-shrink-0{
      -ms-flex-negative:0!important;
      flex-shrink:0!important
  }
  .flex-lg-shrink-1{
      -ms-flex-negative:1!important;
      flex-shrink:1!important
  }
  .flex-lg-wrap{
      -ms-flex-wrap:wrap!important;
      flex-wrap:wrap!important
  }
  .flex-lg-nowrap{
      -ms-flex-wrap:nowrap!important;
      flex-wrap:nowrap!important
  }
  .flex-lg-wrap-reverse{
      -ms-flex-wrap:wrap-reverse!important;
      flex-wrap:wrap-reverse!important
  }
  .gap-lg-0{
      gap:0!important
  }
  .gap-lg-1{
      gap:.25rem!important
  }
  .gap-lg-2{
      gap:.5rem!important
  }
  .gap-lg-3{
      gap:1rem!important
  }
  .gap-lg-4{
      gap:1.5rem!important
  }
  .gap-lg-5{
      gap:3rem!important
  }
  .justify-content-lg-start{
      -webkit-box-pack:start!important;
      -ms-flex-pack:start!important;
      justify-content:flex-start!important
  }
  .justify-content-lg-end{
      -webkit-box-pack:end!important;
      -ms-flex-pack:end!important;
      justify-content:flex-end!important
  }
  .justify-content-lg-center{
      -webkit-box-pack:center!important;
      -ms-flex-pack:center!important;
      justify-content:center!important
  }
  .justify-content-lg-between{
      -webkit-box-pack:justify!important;
      -ms-flex-pack:justify!important;
      justify-content:space-between!important
  }
  .justify-content-lg-around{
      -ms-flex-pack:distribute!important;
      justify-content:space-around!important
  }
  .justify-content-lg-evenly{
      -webkit-box-pack:space-evenly!important;
      -ms-flex-pack:space-evenly!important;
      justify-content:space-evenly!important
  }
  .align-items-lg-start{
      -webkit-box-align:start!important;
      -ms-flex-align:start!important;
      align-items:flex-start!important
  }
  .align-items-lg-end{
      -webkit-box-align:end!important;
      -ms-flex-align:end!important;
      align-items:flex-end!important
  }
  .align-items-lg-center{
      -webkit-box-align:center!important;
      -ms-flex-align:center!important;
      align-items:center!important
  }
  .align-items-lg-baseline{
      -webkit-box-align:baseline!important;
      -ms-flex-align:baseline!important;
      align-items:baseline!important
  }
  .align-items-lg-stretch{
      -webkit-box-align:stretch!important;
      -ms-flex-align:stretch!important;
      align-items:stretch!important
  }
  .align-content-lg-start{
      -ms-flex-line-pack:start!important;
      align-content:flex-start!important
  }
  .align-content-lg-end{
      -ms-flex-line-pack:end!important;
      align-content:flex-end!important
  }
  .align-content-lg-center{
      -ms-flex-line-pack:center!important;
      align-content:center!important
  }
  .align-content-lg-between{
      -ms-flex-line-pack:justify!important;
      align-content:space-between!important
  }
  .align-content-lg-around{
      -ms-flex-line-pack:distribute!important;
      align-content:space-around!important
  }
  .align-content-lg-stretch{
      -ms-flex-line-pack:stretch!important;
      align-content:stretch!important
  }
  .align-self-lg-auto{
      -ms-flex-item-align:auto!important;
      align-self:auto!important
  }
  .align-self-lg-start{
      -ms-flex-item-align:start!important;
      align-self:flex-start!important
  }
  .align-self-lg-end{
      -ms-flex-item-align:end!important;
      align-self:flex-end!important
  }
  .align-self-lg-center{
      -ms-flex-item-align:center!important;
      align-self:center!important
  }
  .align-self-lg-baseline{
      -ms-flex-item-align:baseline!important;
      align-self:baseline!important
  }
  .align-self-lg-stretch{
      -ms-flex-item-align:stretch!important;
      align-self:stretch!important
  }
  .order-lg-first{
      -webkit-box-ordinal-group:0!important;
      -ms-flex-order:-1!important;
      order:-1!important
  }
  .order-lg-0{
      -webkit-box-ordinal-group:1!important;
      -ms-flex-order:0!important;
      order:0!important
  }
  .order-lg-1{
      -webkit-box-ordinal-group:2!important;
      -ms-flex-order:1!important;
      order:1!important
  }
  .order-lg-2{
      -webkit-box-ordinal-group:3!important;
      -ms-flex-order:2!important;
      order:2!important
  }
  .order-lg-3{
      -webkit-box-ordinal-group:4!important;
      -ms-flex-order:3!important;
      order:3!important
  }
  .order-lg-4{
      -webkit-box-ordinal-group:5!important;
      -ms-flex-order:4!important;
      order:4!important
  }
  .order-lg-5{
      -webkit-box-ordinal-group:6!important;
      -ms-flex-order:5!important;
      order:5!important
  }
  .order-lg-last{
      -webkit-box-ordinal-group:7!important;
      -ms-flex-order:6!important;
      order:6!important
  }
  .m-lg-0{
      margin:0!important
  }
  .m-lg-1{
      margin:.25rem!important
  }
  .m-lg-2{
      margin:.5rem!important
  }
  .m-lg-3{
      margin:1rem!important
  }
  .m-lg-4{
      margin:1.5rem!important
  }
  .m-lg-5{
      margin:3rem!important
  }
  .m-lg-auto{
      margin:auto!important
  }
  .mx-lg-0{
      margin-right:0!important;
      margin-left:0!important
  }
  .mx-lg-1{
      margin-right:.25rem!important;
      margin-left:.25rem!important
  }
  .mx-lg-2{
      margin-right:.5rem!important;
      margin-left:.5rem!important
  }
  .mx-lg-3{
      margin-right:1rem!important;
      margin-left:1rem!important
  }
  .mx-lg-4{
      margin-right:1.5rem!important;
      margin-left:1.5rem!important
  }
  .mx-lg-5{
      margin-right:3rem!important;
      margin-left:3rem!important
  }
  .mx-lg-auto{
      margin-right:auto!important;
      margin-left:auto!important
  }
  .my-lg-0{
      margin-top:0!important;
      margin-bottom:0!important
  }
  .my-lg-1{
      margin-top:.25rem!important;
      margin-bottom:.25rem!important
  }
  .my-lg-2{
      margin-top:.5rem!important;
      margin-bottom:.5rem!important
  }
  .my-lg-3{
      margin-top:1rem!important;
      margin-bottom:1rem!important
  }
  .my-lg-4{
      margin-top:1.5rem!important;
      margin-bottom:1.5rem!important
  }
  .my-lg-5{
      margin-top:3rem!important;
      margin-bottom:3rem!important
  }
  .my-lg-auto{
      margin-top:auto!important;
      margin-bottom:auto!important
  }
  .mt-lg-0{
      margin-top:0!important
  }
  .mt-lg-1{
      margin-top:.25rem!important
  }
  .mt-lg-2{
      margin-top:.5rem!important
  }
  .mt-lg-3{
      margin-top:1rem!important
  }
  .mt-lg-4{
      margin-top:1.5rem!important
  }
  .mt-lg-5{
      margin-top:3rem!important
  }
  .mt-lg-auto{
      margin-top:auto!important
  }
  .me-lg-0{
      margin-right:0!important
  }
  .me-lg-1{
      margin-right:.25rem!important
  }
  .me-lg-2{
      margin-right:.5rem!important
  }
  .me-lg-3{
      margin-right:1rem!important
  }
  .me-lg-4{
      margin-right:1.5rem!important
  }
  .me-lg-5{
      margin-right:3rem!important
  }
  .me-lg-auto{
      margin-right:auto!important
  }
  .mb-lg-0{
      margin-bottom:0!important
  }
  .mb-lg-1{
      margin-bottom:.25rem!important
  }
  .mb-lg-2{
      margin-bottom:.5rem!important
  }
  .mb-lg-3{
      margin-bottom:1rem!important
  }
  .mb-lg-4{
      margin-bottom:1.5rem!important
  }
  .mb-lg-5{
      margin-bottom:3rem!important
  }
  .mb-lg-auto{
      margin-bottom:auto!important
  }
  .ms-lg-0{
      margin-left:0!important
  }
  .ms-lg-1{
      margin-left:.25rem!important
  }
  .ms-lg-2{
      margin-left:.5rem!important
  }
  .ms-lg-3{
      margin-left:1rem!important
  }
  .ms-lg-4{
      margin-left:1.5rem!important
  }
  .ms-lg-5{
      margin-left:3rem!important
  }
  .ms-lg-auto{
      margin-left:auto!important
  }
  .p-lg-0{
      padding:0!important
  }
  .p-lg-1{
      padding:.25rem!important
  }
  .p-lg-2{
      padding:.5rem!important
  }
  .p-lg-3{
      padding:1rem!important
  }
  .p-lg-4{
      padding:1.5rem!important
  }
  .p-lg-5{
      padding:3rem!important
  }
  .px-lg-0{
      padding-right:0!important;
      padding-left:0!important
  }
  .px-lg-1{
      padding-right:.25rem!important;
      padding-left:.25rem!important
  }
  .px-lg-2{
      padding-right:.5rem!important;
      padding-left:.5rem!important
  }
  .px-lg-3{
      padding-right:1rem!important;
      padding-left:1rem!important
  }
  .px-lg-4{
      padding-right:1.5rem!important;
      padding-left:1.5rem!important
  }
  .px-lg-5{
      padding-right:3rem!important;
      padding-left:3rem!important
  }
  .py-lg-0{
      padding-top:0!important;
      padding-bottom:0!important
  }
  .py-lg-1{
      padding-top:.25rem!important;
      padding-bottom:.25rem!important
  }
  .py-lg-2{
      padding-top:.5rem!important;
      padding-bottom:.5rem!important
  }
  .py-lg-3{
      padding-top:1rem!important;
      padding-bottom:1rem!important
  }
  .py-lg-4{
      padding-top:1.5rem!important;
      padding-bottom:1.5rem!important
  }
  .py-lg-5{
      padding-top:3rem!important;
      padding-bottom:3rem!important
  }
  .pt-lg-0{
      padding-top:0!important
  }
  .pt-lg-1{
      padding-top:.25rem!important
  }
  .pt-lg-2{
      padding-top:.5rem!important
  }
  .pt-lg-3{
      padding-top:1rem!important
  }
  .pt-lg-4{
      padding-top:1.5rem!important
  }
  .pt-lg-5{
      padding-top:3rem!important
  }
  .pe-lg-0{
      padding-right:0!important
  }
  .pe-lg-1{
      padding-right:.25rem!important
  }
  .pe-lg-2{
      padding-right:.5rem!important
  }
  .pe-lg-3{
      padding-right:1rem!important
  }
  .pe-lg-4{
      padding-right:1.5rem!important
  }
  .pe-lg-5{
      padding-right:3rem!important
  }
  .pb-lg-0{
      padding-bottom:0!important
  }
  .pb-lg-1{
      padding-bottom:.25rem!important
  }
  .pb-lg-2{
      padding-bottom:.5rem!important
  }
  .pb-lg-3{
      padding-bottom:1rem!important
  }
  .pb-lg-4{
      padding-bottom:1.5rem!important
  }
  .pb-lg-5{
      padding-bottom:3rem!important
  }
  .ps-lg-0{
      padding-left:0!important
  }
  .ps-lg-1{
      padding-left:.25rem!important
  }
  .ps-lg-2{
      padding-left:.5rem!important
  }
  .ps-lg-3{
      padding-left:1rem!important
  }
  .ps-lg-4{
      padding-left:1.5rem!important
  }
  .ps-lg-5{
      padding-left:3rem!important
  }
  .text-lg-start{
      text-align:left!important
  }
  .text-lg-end{
      text-align:right!important
  }
  .text-lg-center{
      text-align:center!important
  }
}
@media (min-width:1200px){
  .float-xl-start{
      float:left!important
  }
  .float-xl-end{
      float:right!important
  }
  .float-xl-none{
      float:none!important
  }
  .d-xl-inline{
      display:inline!important
  }
  .d-xl-inline-block{
      display:inline-block!important
  }
  .d-xl-block{
      display:block!important
  }
  .d-xl-grid{
      display:grid!important
  }
  .d-xl-table{
      display:table!important
  }
  .d-xl-table-row{
      display:table-row!important
  }
  .d-xl-table-cell{
      display:table-cell!important
  }
  .d-xl-flex{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important
  }
  .d-xl-inline-flex{
      display:-webkit-inline-box!important;
      display:-ms-inline-flexbox!important;
      display:inline-flex!important
  }
  .d-xl-none{
      display:none!important
  }
  .flex-xl-fill{
      -webkit-box-flex:1!important;
      -ms-flex:1 1 auto!important;
      flex:1 1 auto!important
  }
  .flex-xl-row{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:row!important;
      flex-direction:row!important
  }
  .flex-xl-column{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:column!important;
      flex-direction:column!important
  }
  .flex-xl-row-reverse{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:row-reverse!important;
      flex-direction:row-reverse!important
  }
  .flex-xl-column-reverse{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:column-reverse!important;
      flex-direction:column-reverse!important
  }
  .flex-xl-grow-0{
      -webkit-box-flex:0!important;
      -ms-flex-positive:0!important;
      flex-grow:0!important
  }
  .flex-xl-grow-1{
      -webkit-box-flex:1!important;
      -ms-flex-positive:1!important;
      flex-grow:1!important
  }
  .flex-xl-shrink-0{
      -ms-flex-negative:0!important;
      flex-shrink:0!important
  }
  .flex-xl-shrink-1{
      -ms-flex-negative:1!important;
      flex-shrink:1!important
  }
  .flex-xl-wrap{
      -ms-flex-wrap:wrap!important;
      flex-wrap:wrap!important
  }
  .flex-xl-nowrap{
      -ms-flex-wrap:nowrap!important;
      flex-wrap:nowrap!important
  }
  .flex-xl-wrap-reverse{
      -ms-flex-wrap:wrap-reverse!important;
      flex-wrap:wrap-reverse!important
  }
  .gap-xl-0{
      gap:0!important
  }
  .gap-xl-1{
      gap:.25rem!important
  }
  .gap-xl-2{
      gap:.5rem!important
  }
  .gap-xl-3{
      gap:1rem!important
  }
  .gap-xl-4{
      gap:1.5rem!important
  }
  .gap-xl-5{
      gap:3rem!important
  }
  .justify-content-xl-start{
      -webkit-box-pack:start!important;
      -ms-flex-pack:start!important;
      justify-content:flex-start!important
  }
  .justify-content-xl-end{
      -webkit-box-pack:end!important;
      -ms-flex-pack:end!important;
      justify-content:flex-end!important
  }
  .justify-content-xl-center{
      -webkit-box-pack:center!important;
      -ms-flex-pack:center!important;
      justify-content:center!important
  }
  .justify-content-xl-between{
      -webkit-box-pack:justify!important;
      -ms-flex-pack:justify!important;
      justify-content:space-between!important
  }
  .justify-content-xl-around{
      -ms-flex-pack:distribute!important;
      justify-content:space-around!important
  }
  .justify-content-xl-evenly{
      -webkit-box-pack:space-evenly!important;
      -ms-flex-pack:space-evenly!important;
      justify-content:space-evenly!important
  }
  .align-items-xl-start{
      -webkit-box-align:start!important;
      -ms-flex-align:start!important;
      align-items:flex-start!important
  }
  .align-items-xl-end{
      -webkit-box-align:end!important;
      -ms-flex-align:end!important;
      align-items:flex-end!important
  }
  .align-items-xl-center{
      -webkit-box-align:center!important;
      -ms-flex-align:center!important;
      align-items:center!important
  }
  .align-items-xl-baseline{
      -webkit-box-align:baseline!important;
      -ms-flex-align:baseline!important;
      align-items:baseline!important
  }
  .align-items-xl-stretch{
      -webkit-box-align:stretch!important;
      -ms-flex-align:stretch!important;
      align-items:stretch!important
  }
  .align-content-xl-start{
      -ms-flex-line-pack:start!important;
      align-content:flex-start!important
  }
  .align-content-xl-end{
      -ms-flex-line-pack:end!important;
      align-content:flex-end!important
  }
  .align-content-xl-center{
      -ms-flex-line-pack:center!important;
      align-content:center!important
  }
  .align-content-xl-between{
      -ms-flex-line-pack:justify!important;
      align-content:space-between!important
  }
  .align-content-xl-around{
      -ms-flex-line-pack:distribute!important;
      align-content:space-around!important
  }
  .align-content-xl-stretch{
      -ms-flex-line-pack:stretch!important;
      align-content:stretch!important
  }
  .align-self-xl-auto{
      -ms-flex-item-align:auto!important;
      align-self:auto!important
  }
  .align-self-xl-start{
      -ms-flex-item-align:start!important;
      align-self:flex-start!important
  }
  .align-self-xl-end{
      -ms-flex-item-align:end!important;
      align-self:flex-end!important
  }
  .align-self-xl-center{
      -ms-flex-item-align:center!important;
      align-self:center!important
  }
  .align-self-xl-baseline{
      -ms-flex-item-align:baseline!important;
      align-self:baseline!important
  }
  .align-self-xl-stretch{
      -ms-flex-item-align:stretch!important;
      align-self:stretch!important
  }
  .order-xl-first{
      -webkit-box-ordinal-group:0!important;
      -ms-flex-order:-1!important;
      order:-1!important
  }
  .order-xl-0{
      -webkit-box-ordinal-group:1!important;
      -ms-flex-order:0!important;
      order:0!important
  }
  .order-xl-1{
      -webkit-box-ordinal-group:2!important;
      -ms-flex-order:1!important;
      order:1!important
  }
  .order-xl-2{
      -webkit-box-ordinal-group:3!important;
      -ms-flex-order:2!important;
      order:2!important
  }
  .order-xl-3{
      -webkit-box-ordinal-group:4!important;
      -ms-flex-order:3!important;
      order:3!important
  }
  .order-xl-4{
      -webkit-box-ordinal-group:5!important;
      -ms-flex-order:4!important;
      order:4!important
  }
  .order-xl-5{
      -webkit-box-ordinal-group:6!important;
      -ms-flex-order:5!important;
      order:5!important
  }
  .order-xl-last{
      -webkit-box-ordinal-group:7!important;
      -ms-flex-order:6!important;
      order:6!important
  }
  .m-xl-0{
      margin:0!important
  }
  .m-xl-1{
      margin:.25rem!important
  }
  .m-xl-2{
      margin:.5rem!important
  }
  .m-xl-3{
      margin:1rem!important
  }
  .m-xl-4{
      margin:1.5rem!important
  }
  .m-xl-5{
      margin:3rem!important
  }
  .m-xl-auto{
      margin:auto!important
  }
  .mx-xl-0{
      margin-right:0!important;
      margin-left:0!important
  }
  .mx-xl-1{
      margin-right:.25rem!important;
      margin-left:.25rem!important
  }
  .mx-xl-2{
      margin-right:.5rem!important;
      margin-left:.5rem!important
  }
  .mx-xl-3{
      margin-right:1rem!important;
      margin-left:1rem!important
  }
  .mx-xl-4{
      margin-right:1.5rem!important;
      margin-left:1.5rem!important
  }
  .mx-xl-5{
      margin-right:3rem!important;
      margin-left:3rem!important
  }
  .mx-xl-auto{
      margin-right:auto!important;
      margin-left:auto!important
  }
  .my-xl-0{
      margin-top:0!important;
      margin-bottom:0!important
  }
  .my-xl-1{
      margin-top:.25rem!important;
      margin-bottom:.25rem!important
  }
  .my-xl-2{
      margin-top:.5rem!important;
      margin-bottom:.5rem!important
  }
  .my-xl-3{
      margin-top:1rem!important;
      margin-bottom:1rem!important
  }
  .my-xl-4{
      margin-top:1.5rem!important;
      margin-bottom:1.5rem!important
  }
  .my-xl-5{
      margin-top:3rem!important;
      margin-bottom:3rem!important
  }
  .my-xl-auto{
      margin-top:auto!important;
      margin-bottom:auto!important
  }
  .mt-xl-0{
      margin-top:0!important
  }
  .mt-xl-1{
      margin-top:.25rem!important
  }
  .mt-xl-2{
      margin-top:.5rem!important
  }
  .mt-xl-3{
      margin-top:1rem!important
  }
  .mt-xl-4{
      margin-top:1.5rem!important
  }
  .mt-xl-5{
      margin-top:3rem!important
  }
  .mt-xl-auto{
      margin-top:auto!important
  }
  .me-xl-0{
      margin-right:0!important
  }
  .me-xl-1{
      margin-right:.25rem!important
  }
  .me-xl-2{
      margin-right:.5rem!important
  }
  .me-xl-3{
      margin-right:1rem!important
  }
  .me-xl-4{
      margin-right:1.5rem!important
  }
  .me-xl-5{
      margin-right:3rem!important
  }
  .me-xl-auto{
      margin-right:auto!important
  }
  .mb-xl-0{
      margin-bottom:0!important
  }
  .mb-xl-1{
      margin-bottom:.25rem!important
  }
  .mb-xl-2{
      margin-bottom:.5rem!important
  }
  .mb-xl-3{
      margin-bottom:1rem!important
  }
  .mb-xl-4{
      margin-bottom:1.5rem!important
  }
  .mb-xl-5{
      margin-bottom:3rem!important
  }
  .mb-xl-auto{
      margin-bottom:auto!important
  }
  .ms-xl-0{
      margin-left:0!important
  }
  .ms-xl-1{
      margin-left:.25rem!important
  }
  .ms-xl-2{
      margin-left:.5rem!important
  }
  .ms-xl-3{
      margin-left:1rem!important
  }
  .ms-xl-4{
      margin-left:1.5rem!important
  }
  .ms-xl-5{
      margin-left:3rem!important
  }
  .ms-xl-auto{
      margin-left:auto!important
  }
  .p-xl-0{
      padding:0!important
  }
  .p-xl-1{
      padding:.25rem!important
  }
  .p-xl-2{
      padding:.5rem!important
  }
  .p-xl-3{
      padding:1rem!important
  }
  .p-xl-4{
      padding:1.5rem!important
  }
  .p-xl-5{
      padding:3rem!important
  }
  .px-xl-0{
      padding-right:0!important;
      padding-left:0!important
  }
  .px-xl-1{
      padding-right:.25rem!important;
      padding-left:.25rem!important
  }
  .px-xl-2{
      padding-right:.5rem!important;
      padding-left:.5rem!important
  }
  .px-xl-3{
      padding-right:1rem!important;
      padding-left:1rem!important
  }
  .px-xl-4{
      padding-right:1.5rem!important;
      padding-left:1.5rem!important
  }
  .px-xl-5{
      padding-right:3rem!important;
      padding-left:3rem!important
  }
  .py-xl-0{
      padding-top:0!important;
      padding-bottom:0!important
  }
  .py-xl-1{
      padding-top:.25rem!important;
      padding-bottom:.25rem!important
  }
  .py-xl-2{
      padding-top:.5rem!important;
      padding-bottom:.5rem!important
  }
  .py-xl-3{
      padding-top:1rem!important;
      padding-bottom:1rem!important
  }
  .py-xl-4{
      padding-top:1.5rem!important;
      padding-bottom:1.5rem!important
  }
  .py-xl-5{
      padding-top:3rem!important;
      padding-bottom:3rem!important
  }
  .pt-xl-0{
      padding-top:0!important
  }
  .pt-xl-1{
      padding-top:.25rem!important
  }
  .pt-xl-2{
      padding-top:.5rem!important
  }
  .pt-xl-3{
      padding-top:1rem!important
  }
  .pt-xl-4{
      padding-top:1.5rem!important
  }
  .pt-xl-5{
      padding-top:3rem!important
  }
  .pe-xl-0{
      padding-right:0!important
  }
  .pe-xl-1{
      padding-right:.25rem!important
  }
  .pe-xl-2{
      padding-right:.5rem!important
  }
  .pe-xl-3{
      padding-right:1rem!important
  }
  .pe-xl-4{
      padding-right:1.5rem!important
  }
  .pe-xl-5{
      padding-right:3rem!important
  }
  .pb-xl-0{
      padding-bottom:0!important
  }
  .pb-xl-1{
      padding-bottom:.25rem!important
  }
  .pb-xl-2{
      padding-bottom:.5rem!important
  }
  .pb-xl-3{
      padding-bottom:1rem!important
  }
  .pb-xl-4{
      padding-bottom:1.5rem!important
  }
  .pb-xl-5{
      padding-bottom:3rem!important
  }
  .ps-xl-0{
      padding-left:0!important
  }
  .ps-xl-1{
      padding-left:.25rem!important
  }
  .ps-xl-2{
      padding-left:.5rem!important
  }
  .ps-xl-3{
      padding-left:1rem!important
  }
  .ps-xl-4{
      padding-left:1.5rem!important
  }
  .ps-xl-5{
      padding-left:3rem!important
  }
  .text-xl-start{
      text-align:left!important
  }
  .text-xl-end{
      text-align:right!important
  }
  .text-xl-center{
      text-align:center!important
  }
}
@media (min-width:1400px){
  .float-xxl-start{
      float:left!important
  }
  .float-xxl-end{
      float:right!important
  }
  .float-xxl-none{
      float:none!important
  }
  .d-xxl-inline{
      display:inline!important
  }
  .d-xxl-inline-block{
      display:inline-block!important
  }
  .d-xxl-block{
      display:block!important
  }
  .d-xxl-grid{
      display:grid!important
  }
  .d-xxl-table{
      display:table!important
  }
  .d-xxl-table-row{
      display:table-row!important
  }
  .d-xxl-table-cell{
      display:table-cell!important
  }
  .d-xxl-flex{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important
  }
  .d-xxl-inline-flex{
      display:-webkit-inline-box!important;
      display:-ms-inline-flexbox!important;
      display:inline-flex!important
  }
  .d-xxl-none{
      display:none!important
  }
  .flex-xxl-fill{
      -webkit-box-flex:1!important;
      -ms-flex:1 1 auto!important;
      flex:1 1 auto!important
  }
  .flex-xxl-row{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:row!important;
      flex-direction:row!important
  }
  .flex-xxl-column{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:normal!important;
      -ms-flex-direction:column!important;
      flex-direction:column!important
  }
  .flex-xxl-row-reverse{
      -webkit-box-orient:horizontal!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:row-reverse!important;
      flex-direction:row-reverse!important
  }
  .flex-xxl-column-reverse{
      -webkit-box-orient:vertical!important;
      -webkit-box-direction:reverse!important;
      -ms-flex-direction:column-reverse!important;
      flex-direction:column-reverse!important
  }
  .flex-xxl-grow-0{
      -webkit-box-flex:0!important;
      -ms-flex-positive:0!important;
      flex-grow:0!important
  }
  .flex-xxl-grow-1{
      -webkit-box-flex:1!important;
      -ms-flex-positive:1!important;
      flex-grow:1!important
  }
  .flex-xxl-shrink-0{
      -ms-flex-negative:0!important;
      flex-shrink:0!important
  }
  .flex-xxl-shrink-1{
      -ms-flex-negative:1!important;
      flex-shrink:1!important
  }
  .flex-xxl-wrap{
      -ms-flex-wrap:wrap!important;
      flex-wrap:wrap!important
  }
  .flex-xxl-nowrap{
      -ms-flex-wrap:nowrap!important;
      flex-wrap:nowrap!important
  }
  .flex-xxl-wrap-reverse{
      -ms-flex-wrap:wrap-reverse!important;
      flex-wrap:wrap-reverse!important
  }
  .gap-xxl-0{
      gap:0!important
  }
  .gap-xxl-1{
      gap:.25rem!important
  }
  .gap-xxl-2{
      gap:.5rem!important
  }
  .gap-xxl-3{
      gap:1rem!important
  }
  .gap-xxl-4{
      gap:1.5rem!important
  }
  .gap-xxl-5{
      gap:3rem!important
  }
  .justify-content-xxl-start{
      -webkit-box-pack:start!important;
      -ms-flex-pack:start!important;
      justify-content:flex-start!important
  }
  .justify-content-xxl-end{
      -webkit-box-pack:end!important;
      -ms-flex-pack:end!important;
      justify-content:flex-end!important
  }
  .justify-content-xxl-center{
      -webkit-box-pack:center!important;
      -ms-flex-pack:center!important;
      justify-content:center!important
  }
  .justify-content-xxl-between{
      -webkit-box-pack:justify!important;
      -ms-flex-pack:justify!important;
      justify-content:space-between!important
  }
  .justify-content-xxl-around{
      -ms-flex-pack:distribute!important;
      justify-content:space-around!important
  }
  .justify-content-xxl-evenly{
      -webkit-box-pack:space-evenly!important;
      -ms-flex-pack:space-evenly!important;
      justify-content:space-evenly!important
  }
  .align-items-xxl-start{
      -webkit-box-align:start!important;
      -ms-flex-align:start!important;
      align-items:flex-start!important
  }
  .align-items-xxl-end{
      -webkit-box-align:end!important;
      -ms-flex-align:end!important;
      align-items:flex-end!important
  }
  .align-items-xxl-center{
      -webkit-box-align:center!important;
      -ms-flex-align:center!important;
      align-items:center!important
  }
  .align-items-xxl-baseline{
      -webkit-box-align:baseline!important;
      -ms-flex-align:baseline!important;
      align-items:baseline!important
  }
  .align-items-xxl-stretch{
      -webkit-box-align:stretch!important;
      -ms-flex-align:stretch!important;
      align-items:stretch!important
  }
  .align-content-xxl-start{
      -ms-flex-line-pack:start!important;
      align-content:flex-start!important
  }
  .align-content-xxl-end{
      -ms-flex-line-pack:end!important;
      align-content:flex-end!important
  }
  .align-content-xxl-center{
      -ms-flex-line-pack:center!important;
      align-content:center!important
  }
  .align-content-xxl-between{
      -ms-flex-line-pack:justify!important;
      align-content:space-between!important
  }
  .align-content-xxl-around{
      -ms-flex-line-pack:distribute!important;
      align-content:space-around!important
  }
  .align-content-xxl-stretch{
      -ms-flex-line-pack:stretch!important;
      align-content:stretch!important
  }
  .align-self-xxl-auto{
      -ms-flex-item-align:auto!important;
      align-self:auto!important
  }
  .align-self-xxl-start{
      -ms-flex-item-align:start!important;
      align-self:flex-start!important
  }
  .align-self-xxl-end{
      -ms-flex-item-align:end!important;
      align-self:flex-end!important
  }
  .align-self-xxl-center{
      -ms-flex-item-align:center!important;
      align-self:center!important
  }
  .align-self-xxl-baseline{
      -ms-flex-item-align:baseline!important;
      align-self:baseline!important
  }
  .align-self-xxl-stretch{
      -ms-flex-item-align:stretch!important;
      align-self:stretch!important
  }
  .order-xxl-first{
      -webkit-box-ordinal-group:0!important;
      -ms-flex-order:-1!important;
      order:-1!important
  }
  .order-xxl-0{
      -webkit-box-ordinal-group:1!important;
      -ms-flex-order:0!important;
      order:0!important
  }
  .order-xxl-1{
      -webkit-box-ordinal-group:2!important;
      -ms-flex-order:1!important;
      order:1!important
  }
  .order-xxl-2{
      -webkit-box-ordinal-group:3!important;
      -ms-flex-order:2!important;
      order:2!important
  }
  .order-xxl-3{
      -webkit-box-ordinal-group:4!important;
      -ms-flex-order:3!important;
      order:3!important
  }
  .order-xxl-4{
      -webkit-box-ordinal-group:5!important;
      -ms-flex-order:4!important;
      order:4!important
  }
  .order-xxl-5{
      -webkit-box-ordinal-group:6!important;
      -ms-flex-order:5!important;
      order:5!important
  }
  .order-xxl-last{
      -webkit-box-ordinal-group:7!important;
      -ms-flex-order:6!important;
      order:6!important
  }
  .m-xxl-0{
      margin:0!important
  }
  .m-xxl-1{
      margin:.25rem!important
  }
  .m-xxl-2{
      margin:.5rem!important
  }
  .m-xxl-3{
      margin:1rem!important
  }
  .m-xxl-4{
      margin:1.5rem!important
  }
  .m-xxl-5{
      margin:3rem!important
  }
  .m-xxl-auto{
      margin:auto!important
  }
  .mx-xxl-0{
      margin-right:0!important;
      margin-left:0!important
  }
  .mx-xxl-1{
      margin-right:.25rem!important;
      margin-left:.25rem!important
  }
  .mx-xxl-2{
      margin-right:.5rem!important;
      margin-left:.5rem!important
  }
  .mx-xxl-3{
      margin-right:1rem!important;
      margin-left:1rem!important
  }
  .mx-xxl-4{
      margin-right:1.5rem!important;
      margin-left:1.5rem!important
  }
  .mx-xxl-5{
      margin-right:3rem!important;
      margin-left:3rem!important
  }
  .mx-xxl-auto{
      margin-right:auto!important;
      margin-left:auto!important
  }
  .my-xxl-0{
      margin-top:0!important;
      margin-bottom:0!important
  }
  .my-xxl-1{
      margin-top:.25rem!important;
      margin-bottom:.25rem!important
  }
  .my-xxl-2{
      margin-top:.5rem!important;
      margin-bottom:.5rem!important
  }
  .my-xxl-3{
      margin-top:1rem!important;
      margin-bottom:1rem!important
  }
  .my-xxl-4{
      margin-top:1.5rem!important;
      margin-bottom:1.5rem!important
  }
  .my-xxl-5{
      margin-top:3rem!important;
      margin-bottom:3rem!important
  }
  .my-xxl-auto{
      margin-top:auto!important;
      margin-bottom:auto!important
  }
  .mt-xxl-0{
      margin-top:0!important
  }
  .mt-xxl-1{
      margin-top:.25rem!important
  }
  .mt-xxl-2{
      margin-top:.5rem!important
  }
  .mt-xxl-3{
      margin-top:1rem!important
  }
  .mt-xxl-4{
      margin-top:1.5rem!important
  }
  .mt-xxl-5{
      margin-top:3rem!important
  }
  .mt-xxl-auto{
      margin-top:auto!important
  }
  .me-xxl-0{
      margin-right:0!important
  }
  .me-xxl-1{
      margin-right:.25rem!important
  }
  .me-xxl-2{
      margin-right:.5rem!important
  }
  .me-xxl-3{
      margin-right:1rem!important
  }
  .me-xxl-4{
      margin-right:1.5rem!important
  }
  .me-xxl-5{
      margin-right:3rem!important
  }
  .me-xxl-auto{
      margin-right:auto!important
  }
  .mb-xxl-0{
      margin-bottom:0!important
  }
  .mb-xxl-1{
      margin-bottom:.25rem!important
  }
  .mb-xxl-2{
      margin-bottom:.5rem!important
  }
  .mb-xxl-3{
      margin-bottom:1rem!important
  }
  .mb-xxl-4{
      margin-bottom:1.5rem!important
  }
  .mb-xxl-5{
      margin-bottom:3rem!important
  }
  .mb-xxl-auto{
      margin-bottom:auto!important
  }
  .ms-xxl-0{
      margin-left:0!important
  }
  .ms-xxl-1{
      margin-left:.25rem!important
  }
  .ms-xxl-2{
      margin-left:.5rem!important
  }
  .ms-xxl-3{
      margin-left:1rem!important
  }
  .ms-xxl-4{
      margin-left:1.5rem!important
  }
  .ms-xxl-5{
      margin-left:3rem!important
  }
  .ms-xxl-auto{
      margin-left:auto!important
  }
  .p-xxl-0{
      padding:0!important
  }
  .p-xxl-1{
      padding:.25rem!important
  }
  .p-xxl-2{
      padding:.5rem!important
  }
  .p-xxl-3{
      padding:1rem!important
  }
  .p-xxl-4{
      padding:1.5rem!important
  }
  .p-xxl-5{
      padding:3rem!important
  }
  .px-xxl-0{
      padding-right:0!important;
      padding-left:0!important
  }
  .px-xxl-1{
      padding-right:.25rem!important;
      padding-left:.25rem!important
  }
  .px-xxl-2{
      padding-right:.5rem!important;
      padding-left:.5rem!important
  }
  .px-xxl-3{
      padding-right:1rem!important;
      padding-left:1rem!important
  }
  .px-xxl-4{
      padding-right:1.5rem!important;
      padding-left:1.5rem!important
  }
  .px-xxl-5{
      padding-right:3rem!important;
      padding-left:3rem!important
  }
  .py-xxl-0{
      padding-top:0!important;
      padding-bottom:0!important
  }
  .py-xxl-1{
      padding-top:.25rem!important;
      padding-bottom:.25rem!important
  }
  .py-xxl-2{
      padding-top:.5rem!important;
      padding-bottom:.5rem!important
  }
  .py-xxl-3{
      padding-top:1rem!important;
      padding-bottom:1rem!important
  }
  .py-xxl-4{
      padding-top:1.5rem!important;
      padding-bottom:1.5rem!important
  }
  .py-xxl-5{
      padding-top:3rem!important;
      padding-bottom:3rem!important
  }
  .pt-xxl-0{
      padding-top:0!important
  }
  .pt-xxl-1{
      padding-top:.25rem!important
  }
  .pt-xxl-2{
      padding-top:.5rem!important
  }
  .pt-xxl-3{
      padding-top:1rem!important
  }
  .pt-xxl-4{
      padding-top:1.5rem!important
  }
  .pt-xxl-5{
      padding-top:3rem!important
  }
  .pe-xxl-0{
      padding-right:0!important
  }
  .pe-xxl-1{
      padding-right:.25rem!important
  }
  .pe-xxl-2{
      padding-right:.5rem!important
  }
  .pe-xxl-3{
      padding-right:1rem!important
  }
  .pe-xxl-4{
      padding-right:1.5rem!important
  }
  .pe-xxl-5{
      padding-right:3rem!important
  }
  .pb-xxl-0{
      padding-bottom:0!important
  }
  .pb-xxl-1{
      padding-bottom:.25rem!important
  }
  .pb-xxl-2{
      padding-bottom:.5rem!important
  }
  .pb-xxl-3{
      padding-bottom:1rem!important
  }
  .pb-xxl-4{
      padding-bottom:1.5rem!important
  }
  .pb-xxl-5{
      padding-bottom:3rem!important
  }
  .ps-xxl-0{
      padding-left:0!important
  }
  .ps-xxl-1{
      padding-left:.25rem!important
  }
  .ps-xxl-2{
      padding-left:.5rem!important
  }
  .ps-xxl-3{
      padding-left:1rem!important
  }
  .ps-xxl-4{
      padding-left:1.5rem!important
  }
  .ps-xxl-5{
      padding-left:3rem!important
  }
  .text-xxl-start{
      text-align:left!important
  }
  .text-xxl-end{
      text-align:right!important
  }
  .text-xxl-center{
      text-align:center!important
  }
}
@media (min-width:1200px){
  .fs-1{
      font-size:2.25rem!important
  }
  .fs-2{
      font-size:1.8rem!important
  }
  .fs-3{
      font-size:1.575rem!important
  }
  .fs-4{
      font-size:1.35rem!important
  }
}
@media print{
  .d-print-inline{
      display:inline!important
  }
  .d-print-inline-block{
      display:inline-block!important
  }
  .d-print-block{
      display:block!important
  }
  .d-print-grid{
      display:grid!important
  }
  .d-print-table{
      display:table!important
  }
  .d-print-table-row{
      display:table-row!important
  }
  .d-print-table-cell{
      display:table-cell!important
  }
  .d-print-flex{
      display:-webkit-box!important;
      display:-ms-flexbox!important;
      display:flex!important
  }
  .d-print-inline-flex{
      display:-webkit-inline-box!important;
      display:-ms-inline-flexbox!important;
      display:inline-flex!important
  }
  .d-print-none{
      display:none!important
  }
}
html{
  position:relative;
  min-height:100%
}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{
  color:#f6f6f6
}
.h1,h1{
  font-size:2.25rem
}
.h2,h2{
  font-size:1.8rem
}
.h3,h3{
  font-size:1.575rem
}
.h4,h4{
  font-size:1.35rem
}
.h5,h5{
  font-size:1.125rem
}
.h6,h6{
  font-size:.9rem
}
a{
  text-decoration:none!important
}
label{
  font-weight:500
}
.blockquote{
  padding:10px 20px;
  border-left:4px solid #343747
}
.blockquote-reverse{
  border-left:0;
  border-right:4px solid #343747;
  text-align:right
}
@media (min-width:1200px){
  .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{
      max-width:1140px
  }
}
.row>*{
  position:relative
}
label{
  margin-bottom:.5rem
}
.bg-soft-primary{
  background-color:rgba(91,115,232,.25)!important
}
.bg-soft-secondary{
  background-color:rgba(195,203,228,.25)!important
}
.bg-soft-success{
  background-color:rgba(52,195,143,.25)!important
}
.bg-soft-info{
  background-color:rgba(80,165,241,.25)!important
}
.bg-soft-warning{
  background-color:rgba(241,180,76,.25)!important
}
.bg-soft-danger{
  background-color:rgba(244,106,106,.25)!important
}
.bg-soft-pink{
  background-color:rgba(232,62,140,.25)!important
}
.bg-soft-light{
  background-color:rgba(52,55,71,.25)!important
}
.bg-soft-dark{
  background-color:rgba(239,242,247,.25)!important
}
.bg-soft-purple{
  background-color:rgba(111,66,193,.25)!important
}
.bg-gradient-primary{
  background:-webkit-gradient(linear,left top,left bottom,from(#5b73e8),to(rgba(91,115,232,.5)))!important;
  background:linear-gradient(to bottom,#5b73e8,rgba(91,115,232,.5))!important
}
.bg-gradient-secondary{
  background:-webkit-gradient(linear,left top,left bottom,from(#c3cbe4),to(rgba(195,203,228,.5)))!important;
  background:linear-gradient(to bottom,#c3cbe4,rgba(195,203,228,.5))!important
}
.bg-gradient-success{
  background:-webkit-gradient(linear,left top,left bottom,from(#34c38f),to(rgba(52,195,143,.5)))!important;
  background:linear-gradient(to bottom,#34c38f,rgba(52,195,143,.5))!important
}
.bg-gradient-info{
  background:-webkit-gradient(linear,left top,left bottom,from(#50a5f1),to(rgba(80,165,241,.5)))!important;
  background:linear-gradient(to bottom,#50a5f1,rgba(80,165,241,.5))!important
}
.bg-gradient-warning{
  background:-webkit-gradient(linear,left top,left bottom,from(#f1b44c),to(rgba(241,180,76,.5)))!important;
  background:linear-gradient(to bottom,#f1b44c,rgba(241,180,76,.5))!important
}
.bg-gradient-danger{
  background:-webkit-gradient(linear,left top,left bottom,from(#f46a6a),to(rgba(244,106,106,.5)))!important;
  background:linear-gradient(to bottom,#f46a6a,rgba(244,106,106,.5))!important
}
.bg-gradient-pink{
  background:-webkit-gradient(linear,left top,left bottom,from(#e83e8c),to(rgba(232,62,140,.5)))!important;
  background:linear-gradient(to bottom,#e83e8c,rgba(232,62,140,.5))!important
}
.bg-gradient-light{
  background:-webkit-gradient(linear,left top,left bottom,from(#343747),to(rgba(52,55,71,.5)))!important;
  background:linear-gradient(to bottom,#343747,rgba(52,55,71,.5))!important
}
.bg-gradient-dark{
  background:-webkit-gradient(linear,left top,left bottom,from(#eff2f7),to(rgba(239,242,247,.5)))!important;
  background:linear-gradient(to bottom,#eff2f7,rgba(239,242,247,.5))!important
}
.bg-gradient-purple{
  background:-webkit-gradient(linear,left top,left bottom,from(#6f42c1),to(rgba(111,66,193,.5)))!important;
  background:linear-gradient(to bottom,#6f42c1,rgba(111,66,193,.5))!important
}
.rounded-pill{
  padding-right:.6em;
  padding-left:.6em
}
.badge.bg-primary[href]:focus,.badge.bg-primary[href]:hover{
  background-color:#4964e5!important
}
.bg-soft-primary{
  color:#5b73e8;
  background-color:rgba(91,115,232,.18)
}
.bg-soft-primary[href]{
  color:#5b73e8;
  text-decoration:none;
  background-color:rgba(91,115,232,.4)
}
.bg-soft-primary[href]:focus,.bg-soft-primary[href]:hover{
  color:#5b73e8;
  text-decoration:none;
  background-color:rgba(91,115,232,.4)
}
.badge.bg-secondary[href]:focus,.badge.bg-secondary[href]:hover{
  background-color:#b5bfde!important
}
.bg-soft-secondary{
  color:#c3cbe4;
  background-color:rgba(195,203,228,.18)
}
.bg-soft-secondary[href]{
  color:#c3cbe4;
  text-decoration:none;
  background-color:rgba(195,203,228,.4)
}
.bg-soft-secondary[href]:focus,.bg-soft-secondary[href]:hover{
  color:#c3cbe4;
  text-decoration:none;
  background-color:rgba(195,203,228,.4)
}
.badge.bg-success[href]:focus,.badge.bg-success[href]:hover{
  background-color:#30b383!important
}
.bg-soft-success{
  color:#34c38f;
  background-color:rgba(52,195,143,.18)
}
.bg-soft-success[href]{
  color:#34c38f;
  text-decoration:none;
  background-color:rgba(52,195,143,.4)
}
.bg-soft-success[href]:focus,.bg-soft-success[href]:hover{
  color:#34c38f;
  text-decoration:none;
  background-color:rgba(52,195,143,.4)
}
.badge.bg-info[href]:focus,.badge.bg-info[href]:hover{
  background-color:#3d9bef!important
}
.bg-soft-info{
  color:#50a5f1;
  background-color:rgba(80,165,241,.18)
}
.bg-soft-info[href]{
  color:#50a5f1;
  text-decoration:none;
  background-color:rgba(80,165,241,.4)
}
.bg-soft-info[href]:focus,.bg-soft-info[href]:hover{
  color:#50a5f1;
  text-decoration:none;
  background-color:rgba(80,165,241,.4)
}
.badge.bg-warning[href]:focus,.badge.bg-warning[href]:hover{
  background-color:#f0ac39!important
}
.bg-soft-warning{
  color:#f1b44c;
  background-color:rgba(241,180,76,.18)
}
.bg-soft-warning[href]{
  color:#f1b44c;
  text-decoration:none;
  background-color:rgba(241,180,76,.4)
}
.bg-soft-warning[href]:focus,.bg-soft-warning[href]:hover{
  color:#f1b44c;
  text-decoration:none;
  background-color:rgba(241,180,76,.4)
}
.badge.bg-danger[href]:focus,.badge.bg-danger[href]:hover{
  background-color:#f35757!important
}
.bg-soft-danger{
  color:#f46a6a;
  background-color:rgba(244,106,106,.18)
}
.bg-soft-danger[href]{
  color:#f46a6a;
  text-decoration:none;
  background-color:rgba(244,106,106,.4)
}
.bg-soft-danger[href]:focus,.bg-soft-danger[href]:hover{
  color:#f46a6a;
  text-decoration:none;
  background-color:rgba(244,106,106,.4)
}
.badge.bg-pink[href]:focus,.badge.bg-pink[href]:hover{
  background-color:#e62c81!important
}
.bg-soft-pink{
  color:#e83e8c;
  background-color:rgba(232,62,140,.18)
}
.bg-soft-pink[href]{
  color:#e83e8c;
  text-decoration:none;
  background-color:rgba(232,62,140,.4)
}
.bg-soft-pink[href]:focus,.bg-soft-pink[href]:hover{
  color:#e83e8c;
  text-decoration:none;
  background-color:rgba(232,62,140,.4)
}
.badge.bg-light[href]:focus,.badge.bg-light[href]:hover{
  background-color:#2b2e3b!important
}
.bg-soft-light{
  color:#343747;
  background-color:rgba(52,55,71,.18)
}
.bg-soft-light[href]{
  color:#343747;
  text-decoration:none;
  background-color:rgba(52,55,71,.4)
}
.bg-soft-light[href]:focus,.bg-soft-light[href]:hover{
  color:#343747;
  text-decoration:none;
  background-color:rgba(52,55,71,.4)
}
.badge.bg-dark[href]:focus,.badge.bg-dark[href]:hover{
  background-color:#e1e7f0!important
}
.bg-soft-dark{
  color:#eff2f7;
  background-color:rgba(239,242,247,.18)
}
.bg-soft-dark[href]{
  color:#eff2f7;
  text-decoration:none;
  background-color:rgba(239,242,247,.4)
}
.bg-soft-dark[href]:focus,.bg-soft-dark[href]:hover{
  color:#eff2f7;
  text-decoration:none;
  background-color:rgba(239,242,247,.4)
}
.badge.bg-purple[href]:focus,.badge.bg-purple[href]:hover{
  background-color:#663bb4!important
}
.bg-soft-purple{
  color:#6f42c1;
  background-color:rgba(111,66,193,.18)
}
.bg-soft-purple[href]{
  color:#6f42c1;
  text-decoration:none;
  background-color:rgba(111,66,193,.4)
}
.bg-soft-purple[href]:focus,.bg-soft-purple[href]:hover{
  color:#6f42c1;
  text-decoration:none;
  background-color:rgba(111,66,193,.4)
}
.badge.bg-light{
  color:#9295a4
}
.badge.bg-light[href]:focus,.badge.bg-light[href]:hover{
  color:#9295a4
}
.badge.bg-dark{
  color:#343747
}
a,button{
  outline:0!important
}
.btn-rounded{
  border-radius:30px
}
.btn-dark,.btn-secondary{
  color:#2b2e3b
}
.btn-outline-light{
  color:#f8f9fa
}
.btn-soft-primary{
  background-color:rgba(91,115,232,.25);
  border-color:rgba(91,115,232,.25);
  color:#5b73e8
}
.btn-soft-secondary{
  background-color:rgba(195,203,228,.25);
  border-color:rgba(195,203,228,.25);
  color:#c3cbe4
}
.btn-soft-success{
  background-color:rgba(52,195,143,.25);
  border-color:rgba(52,195,143,.25);
  color:#34c38f
}
.btn-soft-info{
  background-color:rgba(80,165,241,.25);
  border-color:rgba(80,165,241,.25);
  color:#50a5f1
}
.btn-soft-warning{
  background-color:rgba(241,180,76,.25);
  border-color:rgba(241,180,76,.25);
  color:#f1b44c
}
.btn-soft-danger{
  background-color:rgba(244,106,106,.25);
  border-color:rgba(244,106,106,.25);
  color:#f46a6a
}
.btn-soft-pink{
  background-color:rgba(232,62,140,.25);
  border-color:rgba(232,62,140,.25);
  color:#e83e8c
}
.btn-soft-light{
  background-color:rgba(52,55,71,.25);
  border-color:rgba(52,55,71,.25);
  color:#343747
}
.btn-soft-dark{
  background-color:rgba(239,242,247,.25);
  border-color:rgba(239,242,247,.25);
  color:#eff2f7
}
.btn-soft-purple{
  background-color:rgba(111,66,193,.25);
  border-color:rgba(111,66,193,.25);
  color:#6f42c1
}
.btn-group-vertical label{
  margin-bottom:0
}
.breadcrumb-item>a{
  color:#f6f6f6
}
.breadcrumb-item+.breadcrumb-item::before{
  font-family:"Material Design Icons"
}
.card{
  margin-bottom:1.25rem;
  -webkit-box-shadow:0 2px 4px rgba(15,34,58,.12);
  box-shadow:0 2px 4px rgba(15,34,58,.12)
}
.card-drop{
  color:#9295a4
}
.card-title{
  font-size:16px;
  margin:0 0 7px 0;
  font-weight:600
}
.card-title-desc{
  color:#9295a4;
  margin-bottom:24px
}
.dropdown-menu{
  -webkit-box-shadow:0 2px 4px rgba(15,34,58,.12);
  box-shadow:0 2px 4px rgba(15,34,58,.12);
  -webkit-animation-name:DropDownSlide;
  animation-name:DropDownSlide;
  -webkit-animation-duration:.3s;
  animation-duration:.3s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both;
  margin:0;
  position:absolute;
  z-index:1000
}
.dropdown-menu.show{
  top:100%!important
}
.dropdown-menu-end[style]{
  left:auto!important;
  right:0!important
}
.dropdown-menu[data-popper-placement^=left],.dropdown-menu[data-popper-placement^=right],.dropdown-menu[data-popper-placement^=top]{
  top:auto!important;
  -webkit-animation:none!important;
  animation:none!important
}
@-webkit-keyframes DropDownSlide{
  100%{
      -webkit-transform:translateY(0);
      transform:translateY(0)
  }
  0%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
  }
}
@keyframes DropDownSlide{
  100%{
      -webkit-transform:translateY(0);
      transform:translateY(0)
  }
  0%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
  }
}
@media (min-width:600px){
  .dropdown-menu-lg{
      width:320px
  }
  .dropdown-menu-md{
      width:240px
  }
}
.dropdown-divider{
  border-top-color:#343747
}
.dropdown-mega-menu-xl{
  width:40rem
}
.dropdown-mega-menu-lg{
  width:26rem
}
.nav-pills>li>a,.nav-tabs>li>a{
  color:#f6f6f6;
  font-weight:500
}
.nav-pills>a{
  color:#f6f6f6;
  font-weight:500
}
.nav-tabs-custom{
  border-bottom:2px solid #343747
}
.nav-tabs-custom .nav-item{
  position:relative;
  color:#eff2f7
}
.nav-tabs-custom .nav-item .nav-link{
  border:none
}
.nav-tabs-custom .nav-item .nav-link::after{
  content:"";
  background:#5b73e8;
  height:2px;
  position:absolute;
  width:100%;
  left:0;
  bottom:-2px;
  -webkit-transition:all 250ms ease 0s;
  transition:all 250ms ease 0s;
  -webkit-transform:scale(0);
  transform:scale(0)
}
.nav-tabs-custom .nav-item .nav-link.active{
  color:#5b73e8
}
.nav-tabs-custom .nav-item .nav-link.active:after{
  -webkit-transform:scale(1);
  transform:scale(1)
}
.vertical-nav .nav .nav-link{
  padding:24px 16px;
  text-align:center;
  margin-bottom:8px
}
.vertical-nav .nav .nav-link .nav-icon{
  font-size:24px
}
.table th{
  font-weight:600
}
.table .table-light{
  color:#9295a4;
  border-color:#343747;
  background-color:rgba(108,117,125,.05)
}
.table>:not(caption)>*>*{
  border-bottom-width:0;
  border-top-width:1px
}
.table>:not(:last-child)>:last-child>*{
  border-bottom-color:#343747
}
.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:#43494e
}
.table-nowrap td,.table-nowrap th{
  white-space:nowrap
}
.border-primary.table>:not(:first-child){
  border-color:#5b73e8!important
}
.border-secondary.table>:not(:first-child){
  border-color:#c3cbe4!important
}
.border-success.table>:not(:first-child){
  border-color:#34c38f!important
}
.border-info.table>:not(:first-child){
  border-color:#50a5f1!important
}
.border-warning.table>:not(:first-child){
  border-color:#f1b44c!important
}
.border-danger.table>:not(:first-child){
  border-color:#f46a6a!important
}
.border-pink.table>:not(:first-child){
  border-color:#e83e8c!important
}
.border-light.table>:not(:first-child){
  border-color:#343747!important
}
.border-dark.table>:not(:first-child){
  border-color:#eff2f7!important
}
.border-purple.table>:not(:first-child){
  border-color:#6f42c1!important
}
.table-centered td,.table-centered th{
  vertical-align:middle!important
}
.table-card-list{
  border-collapse:separate;
  border-spacing:0 12px
}
.table-card-list tr{
  background-color:#2b2e3b
}
.pagination-rounded .page-link{
  border-radius:30px!important;
  margin:0 3px!important;
  border:none;
  width:32px;
  height:32px;
  padding:0;
  text-align:center;
  line-height:32px
}
.progress-sm{
  height:5px
}
.progress-md{
  height:8px
}
.progress-lg{
  height:12px
}
.progress-xl{
  height:16px
}
.animated-progess{
  position:relative
}
.animated-progess .progress-bar{
  position:relative;
  border-radius:6px;
  -webkit-animation:animate-positive 2s;
  animation:animate-positive 2s
}
@-webkit-keyframes animate-positive{
  0%{
      width:0
  }
}
@keyframes animate-positive{
  0%{
      width:0
  }
}
/*# sourceMappingURL=bootstrap-dark.css.map */
